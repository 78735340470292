import { createContext, useEffect, useCallback, useContext, useMemo, useState } from "react";
import { RequestMethod } from "../_utils";
import { useClient } from "./authContext";
import useToast from "../hooks/useToast";
import { getHelperPreference } from "../_helpers/localStorage";

const SettingsContext = createContext();
SettingsContext.displayName = "SettingsContext";

function SettingsProvider(props) {
    const [loader, setLoader] = useState(false);
    const [energyClassList, setEnergyClassList] = useState([]);
    const [currentUsersList, setCurrentUsersList] = useState([]);
    const [distributorCurrency, setDistributorCurrency] = useState("PLN");
    const [currencyExchangeRate, setCurrencyExchangeRate] = useState(1);
    const [showHelper, setShowHelper] = useState(getHelperPreference());
    const clientApi = useClient();
    const { toastError } = useToast();
    useEffect(() => {
        if (energyClassList.length == 0) {
            getEnergyClassList();
        }
        if (currentUsersList.length == 0) {
            getCurrentUsersList();
        }
        getDistributorSettings();
    }, []);

    const getEnergyClassList = useCallback(
        () =>
            clientApi(`EnergyClass/GetEnergyClassList`, RequestMethod.GET)
                .then((data) => {
                    setEnergyClassList(data);
                    setLoader(false);
                })
                .catch((error) => {
                    toastError(error);
                }),
        []
    );

    const getDistributorSettings = useCallback(
        () =>
            clientApi(`Distributors/GetDistributorSettings`, RequestMethod.POST, { data: {} })
                .then((res) => {
                    setDistributorCurrency(res.currency);
                    const exchangeRateDate = new Date();
                    exchangeRateDate.setDate(exchangeRateDate.getDate() - 1);
                    let requestData = {
                        exchangeRateDate: exchangeRateDate.toISOString(),
                        sourceCurrency: res.currency,
                    };
                    getExchangeRate(requestData);
                })
                .catch((error) => {
                    toastError(error);
                }),
        []
    );

    const getExchangeRate = useCallback(
        (data) =>
            clientApi(`ExchangeRate/GetExhangeRate`, RequestMethod.POST, { data })
                .then((res) => {
                    setCurrencyExchangeRate(res.value);
                })
                .catch((error) => {
                    toastError(error);
                }),
        []
    );

    const deleteEnergyClass = useCallback(
        (energyClassId) =>
            clientApi(`EnergyClass/DeleteOwnEnergyClass`, RequestMethod.DELETE, { data: { energyClassId: energyClassId } })
                .then((data) => {
                    getEnergyClassList();
                })
                .catch((error) => {
                    toastError(error);
                }),
        []
    );

    const addEnergyClass = useCallback(
        (data) =>
            clientApi(`EnergyClass/AddOwnEnergyClass`, RequestMethod.POST, { data })
                .then((data) => {
                    getEnergyClassList();
                })
                .catch((error) => {
                    toastError(error);
                }),
        []
    );

    const updateEnergyClass = useCallback(
        (data) =>
            clientApi(`EnergyClass/UpdateOwnEnergyClass`, RequestMethod.POST, { data })
                .then((data) => {
                    getEnergyClassList();
                })
                .catch((error) => {
                    toastError(error);
                }),
        []
    );

    const getCurrentUsersList = useCallback(
        () =>
            clientApi(`User/GetCurrentUsersList`, RequestMethod.GET)
                .then((data) => {
                    setCurrentUsersList(data);
                })
                .catch((error) => {
                    toastError(error);
                }),
        []
    );

    const value = useMemo(
        () => ({
            energyClassList,
            currentUsersList,
            setCurrentUsersList,
            getCurrentUsersList,
            deleteEnergyClass,
            loader,
            setLoader,
            addEnergyClass,
            updateEnergyClass,
            distributorCurrency,
            currencyExchangeRate,
            showHelper,
            setShowHelper,
        }),
        [
            energyClassList,
            currentUsersList,
            setCurrentUsersList,
            getCurrentUsersList,
            deleteEnergyClass,
            loader,
            setLoader,
            addEnergyClass,
            updateEnergyClass,
            distributorCurrency,
            currencyExchangeRate,
            showHelper,
            setShowHelper,
        ]
    );

    return <SettingsContext.Provider value={value} {...props} />;
}

function useSettings() {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error("useSettings must be used within a SettingsContext");
    }
    return context;
}

export { useSettings, SettingsProvider };
