import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: null },
        },
        enableAutoRouteTracking: true,
        enableAutoCollectExceptions: true,
        enableAutoCollectPerformance: true,
        disableExceptionTracking: false,
    },
});

appInsights.loadAppInsights();

window.onerror = (message, source, lineno, colno, error) => {
    appInsights.trackException({
        exception: error,
        severityLevel: 2, // Error
        properties: { message, source, lineno, colno },
    });
};

window.onunhandledrejection = (event) => {
    appInsights.trackException({
        exception: event.reason,
        severityLevel: 3, // Critical
    });
};

const setReactHistory = (history) => {
    reactPlugin.setHistory(history);
};

export { appInsights, reactPlugin, setReactHistory };
