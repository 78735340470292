import { React, useEffect, useState } from "react";
import styled from "styled-components";
import { Logo, Hamburger, TagsImg, GearImg, CartImg, Cart2Img, LogOutImg, BasketImg, Link, LogoutButton, ToggleButton, Button, Searcher, ChatImg } from ".";
import { BsPeople, BsBuilding, BsBoundingBoxCircles } from "react-icons/bs";
import LoginImg from "../assets/images/Mask-Group-1.png";
import { FiPlusCircle } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import device from "../_styles/media-queries";
import i18n from "i18next";
import k from "../i18n/keys";
import { useCart } from "../_context/cartContext";
import { useProducts } from "../_context/productsContext";
import { Container } from "react-bootstrap";
import ContextMenu from "./ContextMenu";
import { useSettings } from "../_context/settingsContext";
import DistributorClientSelector from "../_views/AdminComboSelect/DistributorClientSelector";
const SidebarWrapper = styled.div`
    z-index: 1000;
    position: fixed;
    left: ${({ toggle }) => (toggle ? "-90px" : "80px")};
    width: 250px;
    padding: 20px;
    height: 100%;
    margin-left: -80px;
    border-right: 2px solid ${({ theme: { colors } }) => colors.gray.normal};
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent;
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: gray;
    }
    background: ${({ theme: { colors } }) => colors.gray.medium};
    transition: all 0.5s ease;
    @media ${device.laptop} {
        left: ${({ toggle }) => (toggle ? "-90px" : "80px")};
    }
`;

const SidebarNav = styled.ul`
    margin: 25px 0;
    padding: 0;
    list-style: none;
    li:nth-child(${({ currentTab }) => currentTab}) {
        a {
            font-weight: bold;
            text-decoration: none;
            color: ${({ theme: { colors } }) => colors.green.normal};
            span {
                color: ${({ theme: { colors } }) => colors.black};
            }
        }
    }

    margin: ${({ toggle, isAdminUser }) => (toggle ? "120px 20px -40px 40px" : isAdminUser ? "-5px 20px 100px 30px" : "50px 20px 100px 30px")};

    font-weight: 500;
    font-size: 20px;
    line-height: 1.2px;
`;

const KnowledgeMenu = styled(SidebarNav)`
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
    li:nth-child(${({ currentTab }) => currentTab}) {
        a {
            font-weight: bold;
            text-decoration: none;
            span {
                color: ${({ theme: { colors } }) => colors.gray.dark};
                &:hover {
                    color: ${({ theme: { colors } }) => colors.green.normal};
                }
            }
        }
    }
    li > a > span {
        &:hover {
            color: ${({ theme: { colors } }) => colors.green.normal};
        }
    }
`;

const PriceMenu = styled(SidebarNav)`
    margin: 0px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
    li:nth-child(${({ currentTab }) => currentTab}) {
        a {
            font-weight: bold;
            text-decoration: none;
            span {
                color: ${({ theme: { colors } }) => colors.black};
                &:hover {
                    color: ${({ theme: { colors } }) => colors.red.medium};
                }
            }
        }
    }
    li > a > span {
        text-transform: uppercase;
        margin-right: 41px;
        font-size: 12px;
        letter-spacing: 0.6px;
        &:hover {
            color: ${({ theme: { colors } }) => colors.red.medium};
        }
    }
`;

const SidebarItem = styled.li`
    margin: ${({ toggle }) => (toggle ? " 25px 140px;" : "25px 0px")};
    transition: all 0.5s ease;
`;

const NavLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.gray.dark};
    &:hover {
        font-weight: bold;
        text-decoration: none;
        color: ${({ theme: { colors } }) => colors.green.normal};
        span {
            color: ${({ theme: { colors } }) => colors.black};
        }
        img {
            filter: invert(43%) sepia(97%) saturate(388%) hue-rotate(99deg) brightness(98%) contrast(84%);
        }
    }
    span {
        margin-left: 20px;
    }
`;

const StyledUserInfo = styled.div`
    margin: ${({ isAdminUser }) => (isAdminUser ? "10px 0 10px" : "30px 0 18px")};
    font-size: 14px;
    line-height: 1.2em;
    text-align: ${({ toggle }) => (toggle ? "right" : "left")};
    h4 {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
    }
    span,
    svg {
        color: ${({ theme: { colors } }) => colors.gray.dark};
    }
    ${({ isAdminUser }) =>
        !isAdminUser &&
        `
        @media ${device.laptop} {
            margin: 37px 0 60px;
        }
    `}
`;

const PageWrapper = styled.div`
    padding-left: 0;
    transition: all 0.5s ease;
    position: fixed;
    width: 100%;
    height: 100%;
    display: block;
    background: ${({ theme: { colors } }) => colors.gray.light};
`;

const ContentWrapper = styled.div`
    padding: 20px 10px 30px 90px;
    transition: all 0.5s ease;
    @media ${device.tablet} {
        padding: 20px 20px 30px 115px;
        transition: all 0.5s ease;
    }
    @media ${device.laptop} {
        padding-left: ${({ toggle }) => (toggle ? "115px" : "280px")};
    }
`;

const PageHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    h3 {
        font-weight: 500;
        font-size: 32px;
        line-height: 1.2;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2px;
    }
    svg {
        width: 36px;
        height: 36px;
    }
    @media ${({ chatVisibility }) => (chatVisibility ? device.laptopL : device.laptop)} {
        flex-direction: row;
        justify-content: space-between;
    }
    @media only screen and ${device.maxMobileL} {
        svg {
            width: 26px !important;
        }
        .arrow {
            width: 16px !important;
            height: 16px !important;
        }
        h3 {
            margin-left: 10px !important;
            font-size: 22px !important;
        }
        a {
            padding: 5px !important;
        }
        button {
            padding: 5px !important;
            font-size: 12px !important;
        }
    }
`;

const PageTitleWrapper = styled.div`
    align-items: center;
    display: flex;
    color: ${({ theme: { colors } }) => colors.green.normal};
    h3 {
        margin-left: 25px;
        font-weight: 700;
        line-height: 1, 19;
        color: ${({ theme: { colors } }) => colors.black};
    }
    svg {
        margin-top: -4px;
        margin-left: 0px;
        height: 36px;
        width: 36px;
    }
    * {
        margin-bottom: 0;
    }
`;

const PageHeaderActionWrapper = styled.div`
    display: flex;
    @media ${device.mobileS} {
        flex-direction: column-reverse;
        justify-content: space-between;
    }
    @media ${device.tablet} {
        flex-direction: row;
    }
    align-items: center;
    @media only screen and ${device.maxMobileL} {
        img,
        svg {
            display: none;
        }
    }
`;

const DiscountBox = styled.div`
    width: 62px;
    height: 34px;
    border-radius: 4px;
    background-color: ${({ theme: { colors } }) => colors.red.medium};
    color: ${({ theme: { colors } }) => colors.base};
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DiscountWrapper = styled.div`
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-left: 35px;
    h6 {
        color: ${({ theme: { colors } }) => colors.black};
        margin-left: 11px;
        font-weight: bold;
    }
`;

const GoToCartWrapper = styled(NavLink)`
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 2px;
    border: 1px solid ${({ theme: { colors } }) => colors.gray.normal};
    border-radius: 4px;
    padding: 4px;
    justify-content: center;
    width: 37px;
    width: ${({ toggle }) => (toggle ? "37px" : "auto")};
    &:hover {
        opacity: 1;
        span {
            color: #282828 !important;
            font-weight: 700 !important;
        }
        span img {
            filter: none;
        }
        border-color: #282828 !important;
    }
`;
const BottomButtonsWrapper = styled.div`
    margin: ${({ toggle }) => (toggle ? "0px 20px 0px 40px" : "0px 20px 0px 30px")};
    font-size: 18px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media ${device.tabletHeight} {
        position: absolute;
        bottom: 20px;
    }
`;

const UserAndCompanyWrapper = styled.div`
    margin-left: ${({ toggle }) => (toggle ? "-20px" : "0px")};
    text-align: center;
    z-index: 2001111;
    h3 {
        display: none;
    }
    &:hover h3 {
        display: block;
        font-size: 15px;
        position: fixed;
        background: #f0dd9b;
        padding: 10px;
        left: 200px;
        top: 190px;
    }

    h4 {
        margin-bottom: 7px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
        span {
            margin-bottom: 7px;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
            text-align: center;
        }
    }
    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
    }
`;

const contextMenuTrigger = (user) => {
    return (
        <h4 style={{ width: "200px" }}>
            {user?.firstName} {user?.lastName}
        </h4>
    );
};

const UserInfo = ({ user, toggle, isAdminUser }) => {
    return (
        <StyledUserInfo toggle={toggle} isAdminUser={isAdminUser}>
            {toggle ? (
                ""
            ) : (
                <UserAndCompanyWrapper>
                    {/* <h3>{user.username}</h3> */}
                    <ContextMenu contextMenuTrigger={() => contextMenuTrigger(user)} contextMenu={[{ label: user?.username }]} />

                    <span>{user.companyName}</span>
                </UserAndCompanyWrapper>
            )}
        </StyledUserInfo>
    );
};

const GreenLineTitle = styled.h1`
    border-left: 6px solid ${({ theme: { colors } }) => colors.green.normal};
    padding: 0 20px;
    margin: 0 -25px 20px;
    @media (max-width: 767px) {
        font-size: 35px;
        border-left: 6px solid ${({ theme: { colors } }) => colors.green.normal};
        padding: 0 10px;
        margin: 0 -15px 20px;
        @media (max-width: 767px) {
            font-size: 35px;
            border-left: 6px solid ${({ theme: { colors } }) => colors.green.normal};
            padding: 0 10px;
            margin: 20px 0px;
        }
    }
`;

const BackgroundImage = styled.div`
    @media ${device.laptopM} {
        background-image: url(${LoginImg});
        width: 1200px;
        height: 744px;
        opacity: 0.2;
    }
`;

const LoginPageImgWrapper = styled.div`
    position: fixed;
    left: 35%;
    top: 10%;
    z-index: -1;
`;

const LogoWrapper = styled.div`
    width: 100%;
    padding: 40px 0 20px;
`;

const FooterWrapper = styled.div`
    bottom: 0;
    margin: 3% 3%;
    @media ${device.tablet} {
        display: flex;
        justify-content: space-between;
    }
`;

const UnauthenticatedPageContentWrapper = styled.div`
    margin: 0 5%;
`;

const SloganWrapper = styled.p`
    border-left: 3px solid ${({ theme: { colors } }) => colors.green.normal};
    padding: 0px 7px;
    line-height: 1.4rem;
    margin: 0;
    font-size: 18px;
    @media ${device.maxMobileL} {
        font-size: 14px;
    }
    b {
        color: ${({ theme: { colors } }) => colors.green.normal};
    }
`;

const PrivacyPolicy = styled.div`
    margin-right: 40%;
    text-decoration: underline;
    @media ${device.maxLaptopM} {
        margin-right: 0;
    }
    @media ${device.maxTablet} {
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
    }
`;

const StyledContainer = styled(Container)`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media ${device.maxMobileL} {
        position: absolute;
        height: 100%;
        min-height: fill-available;
        min-height: -webkit-fill-available;
    }
`;

const StyledLogoWraper = styled.div`
    margin-left: ${({ toggle }) => (toggle ? "-20px" : "0px")};
    text-align: center;
`;

const Footer = () => {
    return (
        <FooterWrapper>
            <SloganWrapper>
                {i18n.t(k.WE_ARE_CHANGING_THE_FUTURE_FOR)}
                <b>{i18n.t(k.GREEN)}</b>
            </SloganWrapper>
            <PrivacyPolicy>
                <a href="#" target="_blank" style={{ color: "#707070" }}>
                    {i18n.t(k.PRIVACY_POLICY)}
                </a>
            </PrivacyPolicy>
        </FooterWrapper>
    );
};

const UnauthenticatedPage = ({ children }) => {
    return (
        <StyledContainer>
            <LogoWrapper>
                <Logo />
            </LogoWrapper>
            <LoginPageImgWrapper>
                <BackgroundImage />
            </LoginPageImgWrapper>
            <UnauthenticatedPageContentWrapper>{children}</UnauthenticatedPageContentWrapper>
            <div style={{ maxHeight: "10px" }}></div>
            <Footer />
        </StyledContainer>
    );
};

const Sidebar = ({ user, logoutFunc, toggle, setToggle, activeNavTab, setActiveNavTab, refresh, setRefresh, ...props }) => {
    const [currentTab, setCurrentTab] = useState(1);
    const { cartOffers, cartPrice, getNewPrice, cartProducts, totalPrice, getCartOrder, currentCartTab, setCurrentCartTab, clickedTab, setClickedTab, setNextPage } = useCart();
    const { setCurrency, currentCurrency, currencies } = useProducts();
    const { distributorCurrency, currencyExchangeRate } = useSettings();
    const isAdminUser = user?.username === "export@heatdecor.com";
    const changeCurrency = (data) => {
        setCurrency(data.target.value);
        getNewPrice({ currency: currencies.find((x) => x.id == data.target.value).symbol, products: cartProducts });
    };

    useEffect(() => {
        getCartOrder();
        const pathname = window.location.href;
        checkPathname(pathname);
    }, []);

    useEffect(() => {
        setCurrentTab(activeNavTab);
    }, [activeNavTab]);

    useEffect(() => {
        if (currentTab != 0) {
            setCurrentCartTab(0);
        }
        setActiveNavTab(currentTab);
    }, [currentTab]);

    const defValue =
        currentCurrency || currencies
            ? currencies?.find((x) => x.id == currentCurrency)
                ? currencies?.find((x) => x.id == currentCurrency).id
                : currencies?.find((x) => x.symbol.toLowerCase() == "pln")?.id
            : "PLN";

    const checkPathname = (pathname) => {
        if (pathname.includes("/cart")) {
            setCurrentTab(0);
        } else if (pathname.includes("/investors")) {
            setCurrentTab(1);
        } else if (pathname.includes("/investments")) {
            setCurrentTab(2);
        } else if (pathname.includes("/projects")) {
            setCurrentTab(3);
        } else if (pathname.includes("/offers")) {
            setCurrentTab(4);
        } else if (pathname.includes("/orders")) {
            setCurrentTab(5);
        } else if (pathname.includes("/settings")) {
            setCurrentTab(6);
        } else if (pathname.includes("/chat")) {
            setCurrentTab(7);
        }
    };
    let newLogo;
    if (user?.distributor?.logo?.dataFiles == "" || user?.distributor?.logo == null) {
        newLogo = null;
    } else {
        newLogo = "data:" + user?.distributor?.logo?.fileType + ";base64," + user?.distributor?.logo?.dataFiles;
    }

    const changeCurrentTab = (tabId) => {
        if (window.location.href.includes("/settings")) {
            setClickedTab(!clickedTab);
            if (tabId == 0) {
                setNextPage("/cart");
            } else if (tabId == 1) {
                setNextPage("/investors");
            } else if (tabId == 2) {
                setNextPage("/investments");
            } else if (tabId == 3) {
                setNextPage("/projects");
            } else if (tabId == 4) {
                setNextPage("/offers");
            } else if (tabId == 5) {
                setNextPage("/orders");
            }
        }
        setCurrentTab(tabId);
        if (tabId == currentTab) {
            setRefresh(true);
        } else {
            setRefresh(false);
        }
    };

    return (
        <SidebarWrapper toggle={toggle}>
            <ToggleButton toggle={toggle} variant="inline" onClick={() => setToggle(!toggle)}>
                {toggle ? <Hamburger style={{ marginTop: "20px" }} /> : <FiX style={{ fontSize: "36px", marginRight: "-10px", color: "#3D3D3D" }} />}
            </ToggleButton>
            <div
                style={{
                    margin: toggle ? (isAdminUser ? "40px 0 40px 0px" : "40px 0 40px 0px") : isAdminUser ? "40px 0 10px 40px" : "40px 0 40px 40px",
                }}>
                <Logo newLogo={newLogo} />
            </div>
            <UserInfo user={user} toggle={toggle} isAdminUser={isAdminUser} />

            {!toggle ? (
                <GoToCartWrapper to="/cart" onClick={() => changeCurrentTab(0)} style={{ borderColor: currentTab == 0 ? "#282828" : "#696969", color: "#696969" }}>
                    <span style={{ marginRight: "10px", marginLeft: "0px", alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <CartImg currentTab={currentTab} style={{ marginRight: "15px", placeSelf: "center" }} />
                    </span>
                    <span
                        style={{
                            fontSize: "16px",
                            lineHeight: "21px",
                            marginLeft: "10px",
                            color: currentTab == 0 ? "#282828" : "#696969",
                            fontWeight: currentTab == 0 ? "700" : "400",
                        }}>
                        {currentTab == 0 ? (
                            <>
                                {currentCartTab > 2 && currentCartTab < 6 ? (
                                    (cartOffers?.bruttoPrice / currencyExchangeRate).toFixed(2)
                                ) : (
                                    <>{totalPrice.gross ? (totalPrice.gross / currencyExchangeRate).toFixed(2) : 0}</>
                                )}
                            </>
                        ) : (
                            <>{(cartPrice / currencyExchangeRate).toFixed(2)}</>
                        )}
                        {" " + distributorCurrency}
                    </span>
                </GoToCartWrapper>
            ) : (
                <SidebarNav currentTab={currentTab} toggle={toggle} onClick={() => changeCurrentTab(0)}>
                    <NavLink to="/cart" style={{ color: "#B1B1B1" }}>
                        <SidebarItem toggle={toggle}>
                            <CartImg currentTab={currentTab} />
                        </SidebarItem>
                    </NavLink>
                </SidebarNav>
            )}
            {isAdminUser && <DistributorClientSelector user={user} toggle={toggle} />}
            <SidebarNav currentTab={currentTab} toggle={toggle} isAdminUser={isAdminUser}>
                <SidebarItem toggle={toggle} onClick={() => changeCurrentTab(1)}>
                    <NavLink to="/investors">
                        <BsPeople /> {!toggle ? <span>{i18n.t(k.INVESTORS)}</span> : ""}
                    </NavLink>
                </SidebarItem>
                <SidebarItem toggle={toggle} onClick={() => changeCurrentTab(2)}>
                    <NavLink to="/investments">
                        <BsBuilding style={{ fontSize: "18px" }} /> {!toggle ? <span>{i18n.t(k.INVESTMENTS)}</span> : ""}
                    </NavLink>
                </SidebarItem>
                <SidebarItem toggle={toggle} onClick={() => changeCurrentTab(3)}>
                    <NavLink to="/projects">
                        <BsBoundingBoxCircles /> {!toggle ? <span>{i18n.t(k.PROJECTS)}</span> : ""}
                    </NavLink>
                </SidebarItem>
                <SidebarItem toggle={toggle} onClick={() => changeCurrentTab(4)}>
                    <NavLink to="/offers">
                        <TagsImg currentTab={currentTab} style={{ fontSize: "18px" }} /> {!toggle ? <span>{i18n.t(k.OFFERS)}</span> : ""}
                    </NavLink>
                </SidebarItem>
                <SidebarItem toggle={toggle} onClick={() => changeCurrentTab(5)}>
                    <NavLink to="/orders">
                        <BasketImg currentTab={currentTab} style={{ fontSize: "18px" }} /> {!toggle ? <span>{i18n.t(k.ORDERS)}</span> : ""}
                    </NavLink>
                </SidebarItem>
                {user?.login === "export@heatdecor.com" ? (
                    <SidebarItem toggle={toggle} onClick={() => changeCurrentTab(7)}>
                        <NavLink to="/chat">
                            <ChatImg currentTab={currentTab} style={{ fontSize: "18px" }} /> {!toggle ? <span>{i18n.t(k.CHAT)}</span> : ""}
                        </NavLink>
                    </SidebarItem>
                ) : null}
            </SidebarNav>
            <BottomButtonsWrapper toggle={toggle} style={toggle ? { marginLeft: "180px", fontSize: "22px", marginTop: "60px" } : { fontSize: "20px", marginTop: "60px" }}>
                <NavLink to="/settings" onClick={() => changeCurrentTab(6)}>
                    <GearImg currentTab={currentTab} />
                    {!toggle ? (
                        <span style={{ marginLeft: "20px", fontWeight: currentTab == 6 ? "bold" : "500", color: currentTab == 6 ? "#282828" : "#4A4A4A" }}>
                            {i18n.t(k.SETTINGS)}
                        </span>
                    ) : (
                        ""
                    )}
                </NavLink>
                <LogoutButton to="/logout" onClick={logoutFunc} style={{ background: "#ECECEC" }}>
                    <LogOutImg /> <span style={{ marginLeft: "20px", fontSize: "20px", background: "#ECECEC" }}> {!toggle ? i18n.t(k.LOGOUT) : ""}</span>
                </LogoutButton>
            </BottomButtonsWrapper>
        </SidebarWrapper>
    );
};

const PageHeader = ({
    title,
    logo,
    buttonTitle,
    buttonAction,
    searchPlaceholder,
    searchAction,
    getSearchList,
    suggestionList,
    chatVisibility,
    discount,
    currentTab,
    isHidden,
    buttonOnClick,
    ...props
}) => {
    const PageLogo = logo;
    return (
        <PageHeaderWrapper chatVisibility={chatVisibility}>
            <PageTitleWrapper>
                {typeof PageLogo == "string" ? <img src={PageLogo} alt="logo" /> : <PageLogo />}
                <h3 style={{ fontSize: "32px" }}>{title}</h3>
            </PageTitleWrapper>
            <PageHeaderActionWrapper>
                {searchPlaceholder && <Searcher action={searchAction} getSearchList={getSearchList} placeholder={searchPlaceholder} suggestionList={suggestionList} />}
                {buttonAction && (
                    <Button
                        to={buttonAction}
                        onClick={buttonOnClick}
                        style={{
                            marginRight: "0px",
                            padding: "10px 16px 9px 16px",
                            fontSize: "16px",
                            lineHeight: "19px",
                            fontWeight: "400px",
                            visibility: isHidden ? "hidden" : "visible",
                        }}
                        primary
                        icon={<FiPlusCircle style={{ fontSize: "16px", width: "16px", height: "16px", marginBottom: "3px" }} />}>
                        {buttonTitle}
                    </Button>
                )}
            </PageHeaderActionWrapper>
        </PageHeaderWrapper>
    );
};

export {
    Sidebar,
    SidebarNav,
    SidebarItem,
    NavLink,
    PageWrapper,
    ContentWrapper,
    PageHeaderWrapper,
    PageHeader,
    KnowledgeMenu,
    SidebarWrapper,
    PriceMenu,
    UnauthenticatedPage,
    LogoWrapper,
    GreenLineTitle,
};
