const k = require("./keys");

export const czech = {
    [k._]: `:`,
    [k._1]: ``,
    [k._10]: `4`,
    [k._11]: `5`,
    [k._12]: `6`,
    [k._13]: `06:00`,
    [k._14]: `10`,
    [k._15]: `1:`,
    [k._16]: `]`,
    [k._17]: `100%`,
    [k._2]: `-`,
    [k._3]: `/`,
    [k._4]: `.`,
    [k._5]: `(`,
    [k._6]: `12:00`,
    [k._7]: `1`,
    [k._8]: `2`,
    [k._9]: `3`,
    [k.ADD]: `Přidat`,
    [k.ADD_ADDRESS]: `Přidat adresu`,
    [k.ADD_ANOTHER_ROOM]: `Přidat další místnost`,
    [k.ADD_NEW_ADDRESS]: `Přidat novou adresu`,
    [k.ADDTOCART]: `Přidat do košíku`,
    [k.ANNUAL_ELECTRICITY_CONSUMPTION]: `Roční spotřeba elektřiny`,
    [k.ANNUAL_OPERATING_COSTS]: `Roční provozní náklady`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ADDRESS]: `Opravdu chcete odstranit tuto adresu?`,
    [k.ARCHITECT]: `Architekt`,
    [k.APARTMENT]: `Byt`,
    [k.BACK]: `Zpět`,
    [k.TRANSFER]: `Převod`,
    [k.BANK_TRANSFER]: `Bankovní převod`,
    [k.BLOCKOFFLATS]: `Blok`,
    [k.BUILDINGENERGYCLASS]: `Energetická třída budovy`,
    [k.BUYBYMETER]: `Koupit na metry`,
    [k.BUYONROLLS]: `Koupit na role`,
    [k.BYMETERS]: `(na metry)`,
    [k.CONFIRM]: `Potvrdit`,
    [k.CZECH]: `Česky`,
    [k.CALCULATECOSTS]: `Vypočítat náklady`,
    [k.CALCULATE_HEATING_COSTS]: `Vypočítat náklady na vytápění`,
    [k.CREATE_ACCOUNT]: `Vytvořit účet`,
    [k.CANCEL]: `Zrušit`,
    [k.CHOOSELANG]: `Vybrat jazyk`,
    [k.CHOOSE_NEXT_STEP]: `Vyberte další krok`,
    [k.COSTCALCULATION]: `Výpočet nákladů`,
    [k.CONSTRUCTION]: `Stavebnictví`,
    [k.DELETE]: `Smazat`,
    [k.DEVELOPER]: `Developer`,
    [k.DISCOUNTGRANTED]: `Poskytnutá sleva`,
    [k.DISCOUNTLEVEL]: `Vaše aktuální úroveň slevy`,
    [k.DISTRIBUTORS]: `Distributoři`,
    [k.ELECTRICHEATERS]: `Elektrické topení`,
    [k.EMPLOYEE]: `Zaměstnanec`,
    [k.ENGLISH]: `Anglicky`,
    [k.ENTER_ROOMS_DIMENSIONS]: `Zadejte rozměry místnosti`,
    [k.ENTER_ROOMS_DIMENSIONS_BASED_ON_FIGURE]: `Zadejte rozměry místnosti na základě tvaru`,
    [k.ENTER_A_NEW_PASSWORD]: `Nové heslo by mělo obsahovat minimálně 8 znaků, malé a velké písmeno a speciální znak`,
    [k.FINISHED]: `Hotovo`,
    [k.FOILPOWER]: `Výkon fólie`,
    [k.FOILWIDTH]: `Šířka fólie`,
    [k.FRENCH]: `Francouzsky`,
    [k.GERMAN]: `Německy`,
    [k.GROSS]: `hrubý`,
    [k.ELECRICITYPRICE]: `Cena elektřiny (CZK / kWh)`,
    [k.ENERGYCLASS]: `Energetická třída budovy (kWh / m2 za rok)`,
    [k.ENTER_INSTALLATION_PARAMS]: `Zadejte parametry instalace pro výpočet spotřeby`,
    [k.FOR_CLIENT]: `Pro klienta`,
    [k.GENERAL_HEATING_COST_CALCULATOR]: `Obecná kalkulačka nákladů na infračervené vytápění s třídou energetické účinnosti budovy:`,
    [k.LOWENERGY]: `A Nízkoenergetický`,
    [k.GOTOCASHREGISTER]: `Přejít k pokladně`,
    [k.HEATINGCOSTCALCULATOR]: `Kalkulačka nákladů na vytápění`,
    [k.HEATINGFOIL]: `Topná fólie`,
    [k.HEATINGMAT]: `Topná rohož`,
    [k.HOUSE]: `Dům`,
    [k.HOUSINGESTATE]: `Sídliště`,
    [k.INSTALLER]: `Montér`,
    [k.I_ORDER_WITH_SHIPPING_TO_CUSTOMERS]: `Objednávám s dopravou k zákazníkům`,
    [k.I_AM_ORDERING_FOR_MY_OWN_WAREHOUSE]: `Objednávám pro vlastní sklad`,
    [k.ITALIAN]: `Italsky`,
    [k.HEATING_SYSTEM_POWER]: `Jmenovitý výkon topného systému`,
    [k.INSTALLATIONDESIGN]: `Návrh instalace`,
    [k.INVESTMENT_MATERIALS_COSTS]: `Náklady na investiční materiály`,
    [k.INVESTORS]: `Investoři`,
    [k.INVESTMENTS]: `Investice`,
    [k.KNOWLEDGEBASE]: `Znalostní databáze`,
    [k.LARGEQUANTITYINSTOCK]: `Velké množství na skladě`,
    [k.LEAVEPROJECT]: `Opustit projekt`,
    [k.LENGTH_IN_CM]: `Délka v cm`,
    [k.LOGIN]: `Přihlásit se`,
    [k.LOG_IN]: `Přihlášení`,
    [k.LOGOUT]: `Odhlásit se`,
    [k.MANAGER]: `Manažer`,
    [k.MARKETING]: `Marketing`,
    [k.MEDIUMENERGYSAVING]: `C Středně energeticky úsporný`,
    [k.ENERGYSAVING]: `B Energeticky úsporný`,
    [k.MIN_PV_INSTALATION_REQUIRED]: `Požadována minimální fotovoltaická instalace pro vyrovnání nákladů na vytápění`,
    [k.MORE_PRODUCTS_AND_GO_TO_HIGHER_DISCOUNT_LEVEL]: `produkty a přejít na vyšší úroveň slevy`,
    [k.NO]: `Ne`,
    [k.NODATA]: `Žádná data`,
    [k.NORWEGIAN]: `Norsky`,
    [k.ONROLLS]: `(na role)`,
    [k.POLISH]: `Polsky`,
    [k.MONTHLY_OPERATING_COSTS]: `Měsíční provozní náklady`,
    [k.NET]: `čistý`,
    [k.NEWROOM]: `Nová místnost`,
    [k.OFFERGENERATING]: `Generování nabídky`,
    [k.OFFERS]: `Nabídky`,
    [k.ORDERS]: `Objednávky`,
    [k.ONLINE_PAYMENT]: `Online platba`,
    [k.PAYMENT_METHOD]: `Platební metoda`,
    [k.OWN]: `Vlastní`,
    [k.OWNER]: `Majitel`,
    [k.PASSIVE_A_PLUS]: `A+ Pasivní`,
    [k.PHOTOVOLTAIC]: `Fotovoltaika`,
    [k.PRESENTED_CALC_ARE_INFORMATIVE]: `*Představené výpočty jsou informativní a nepředstavují nabídku v smyslu čl. 66. odst. 1 Občanského zákoníku`,
    [k.PRICELIST]: `Ceník`,
    [k.PRODUCT]: `Produkt`,
    [k.PRODUCTSINCART]: `Produkty v košíku`,
    [k.PRODUCTAVAILABLE]: `Produkt k dispozici`,
    [k.PROFIT]: `Zisk`,
    [k.PROJECTS]: `Projekty`,
    [k.PUBLICFACILITY]: `Veřejné zařízení`,
    [k.RATED_ENERGY_CONSUMPTION]: `Jmenovitá spotřeba energie`,
    [k.REJECT_CHANGES]: `Odmítnout změny`,
    [k.REMIND_PASSWORD]: `Připomenutí hesla`,
    [k.REMEMBER_ME]: `Zapamatovat si mě`,
    [k.RETURN_TO_THE_LOGIN_PANEL]: `zpět na přihlašovací panel`,
    [k.QUANTITY]: `Množství`,
    [k.ROMANIAN]: `Rumunsky`,
    [k.SALE]: `Sleva`,
    [k.SAVEANDCLOSE]: `Uložit a zavřít`,
    [k.SAVECHANGES]: `Uložit změny`,
    [k.SEARCHFORPRODUCTS]: `Vyhledat produkty`,
    [k.SEETHEPRODUCT]: `Podívat se na produkt`,
    [k.SETTINGS]: `Nastavení`,
    [k.SHOWCROSS_SECTION]: `Ukázat průřez`,
    [k.SHOWLAYERS]: `Ukázat vrstvy`,
    [k.SLOVAK]: `Slovensky`,
    [k.SMALLQUANTITYINSTOCK]: `Malé množství na skladě`,
    [k.SOMETHING_WENT_WRONG]: `Něco se pokazilo:`,
    [k.SPAIN]: `Španělsky`,
    [k.RELOADAPPLICATION]: `Obnovit aplikaci`,
    [k.SWEDISH]: `Švédsky`,
    [k.THERMOSTAT]: `Termostat`,
    [k.THERMOSTATFUNCTIONS]: `Funkce termostatu`,
    [k.THERMOSTATS]: `Termostaty`,
    [k.TOGETHER]: `Dohromady`,
    [k.TOGETHERTOPAY]: `Celkem k platbě`,
    [k.USABLEAREA]: `Užitná plocha (m2)`,
    [k.ZEROENERGY]: `A++ Nulová energie`,
    [k.FLOOR]: `Podlaha`,
    [k.CEILING]: `Strop`,
    [k.WALL]: `Stěna`,
    [k.WAREHOUSE]: `Sklad`,
    [k.WHOLESALER]: `Velkoobchod`,
    [k.WT2021]: `WT2021`,
    [k.ADDROOM]: `Přidat místnost`,
    [k.CHANGE]: `Změnit`,
    [k.YES]: `Ano`,
    [k.GOBACKTOCREATOR]: `Zpět do tvůrce`,
    [k.GOBACKTOCART]: `Zpět do košíku`,
    [k.SQUAREM]: `m²`,
    [k.PIECE]: `ks`,
    [k.RUNNINGM]: `bm`,
    [k.COLOR]: `Barva`,
    [k.UNDO]: `Zpět`,
    [k.REDO]: `Znovu`,
    [k.ZOOMIN]: `Přiblížit`,
    [k.ZOOMOUT]: `Oddálit`,
    [k.MEASURE]: `Měřit`,
    [k.CLOSEPOLYGON]: `Uzavřít mnohoúhelník`,
    [k.CONTACTORREQUIRED]: `je vyžadován stykač`,
    [k.WRONGLOGINORPASSWORD]: `Nesprávné přihlašovací jméno nebo heslo.`,
    [k.WIDTH_IN_CM]: `Šířka v cm`,
    [k.WIDTH]: `Šířka`,
    [k.MISSINGUSER]: `Uživatel nenalezen.`,
    [k.PASSWORD]: `Heslo`,
    [k.PASSWORDREQUIRED]: `Heslo je vyžadováno.`,
    [k.CONFIRMPASSWORDMUSTBESAME]: `Potvrzení hesla musí být stejné.`,
    [k.PASSWORDSHOULDBEMIN8CHARLONG]: `Heslo musí mít minimálně 8 znaků.`,
    [k.PASSWORDPATTERNQUOTE]: `Heslo musí obsahovat alespoň jedno číslo, jedno velké písmeno, jedno malé písmeno a speciální znak (např.: @, #, &, !, $).`,
    [k.PASSWORD_HAS_BEEN_RESET]: `Heslo bylo resetováno`,
    [k.CALIBRATION]: `Kalibrace`,
    [k.READY]: `Připraveno`,
    [k.EDIT]: `Upravit`,
    [k.START]: `Start`,
    [k.UNFOLDAUTOMATICALLY]: `Rozvinout automaticky`,
    [k.CUTTINGPOINT]: `Bod řezu`,
    [k.TYPE]: `Typ`,
    [k.FURNITURE]: `Nábytek`,
    [k.BATH_SHOWERTRAY]: `Vana/sprchová vanička`,
    [k.SINK]: `Umyvadlo/závěsné WC`,
    [k.KITCHEN_FURNITURE]: `Kuchyňský nábytek`,
    [k.FIREPLACE]: `Krb`,
    [k.HOUSEHOLD_APPLIANCES]: "Domácí spotřebiče",
    [k.YOUR_ADDRESSES]: "Vaše adresy",
    [k.YOUR_NET_PRICE]: "Vaše čistá cena",
    [k.YOUR_GROSS_PRICE]: "Vaše hrubá cena",
    [k.OPTIMAL]: "Optimálně",
    [k.VERTICALY]: "Vertikálně",
    [k.HORIZONTALY]: "Horizontálně",
    [k.ALLWIDTHS]: "Všechny šířky",
    [k.DRAWOFFAREA]: "Chcete přidat prvky pevné zástavby?",
    [k.INNER]: "Vnitřní",
    [k.OUTER]: "Vnější",
    [k.OTHER]: "Jiné",
    [k.ROOM]: "Pokoj",
    [k.BATHROOM]: "Koupelna",
    [k.BASEMENT]: "Suterén",
    [k.GROUND_FLOOR]: "Přízemí",
    [k.FIRST_FLOOR]: "První patro",
    [k.ATTIC]: "Půda",
    [k.STOREY]: "Podlaží",
    [k.LEVEL]: "Úroveň",
    [k.ROOM]: "Místnost",
    [k.ADDFILE]: "Přidat soubor",
    [k.DRAWPLAN]: "Kreslit plán",
    [k.LIVINGROOM]: "Obývací pokoj",
    [k.KITCHEN]: "Kuchyně",
    [k.VESTIBULE]: "Vstupní hala",
    [k.BEDROOM]: "Ložnice",
    [k.DININGROOM]: "Jídelna",
    [k.WARDROBE]: "Šatna",
    [k.TOILET]: "Toaleta",
    [k.LAUNDRY]: "Prádelna",
    [k.HALL]: "Chodba",
    [k.CABINET]: "Kancelář",
    [k.ANTEROOM]: "Předsíň",
    [k.CORRIDOR]: "Chodba",
    [k.PANTRY]: "Spíž",
    [k.LIVING_DINING_ROOM]: "Obývací pokoj s jídelnou",
    [k.LIVINGROOM_WITH_KITCHENETTE]: "Obývací pokoj s kuchyňským koutem",
    [k.GARAGE]: "Garáž",
    [k.TECHNICALROOM]: "Technická místnost",
    [k.UTILITYROOM]: "Hospodářská místnost",
    [k.RECREATIONROOM]: "Rekreační místnost",
    [k.ENTRESOL]: "Mezipatro",
    [k.SAUNA]: "Sauna",
    [k.BOILERROOM]: "Kotelná",
    [k.CUBBY]: "Skříňka",
    [k.STAIRS]: "Schodiště",
    [k.FIREPLACE_ROOM]: "Pokoj s krbem",
    [k.GO_BACK_EDIT]: "Zpět na úpravy",
    [k.GO_FURTHER]: "Pokračovat",
    [k.DRAW_NEXT]: "Nakreslit další",
    [k.START_DESIGNING]: "Začít navrhovat",
    [k.IN_THE_WIZARD_HEAT_DECOR]: "v průvodci Heat Decor",
    [k.YOU_CAN_CREATE_AN_INFRARED_HEATING_PROJECT_IN_JUST_FEW_STEPS]: "Během několika jednoduchých kroků vytvoříte projekt infračerveného vytápění.",
    [k.ENTER_THE_EMAIL_PROVIDED_WHEN_CREATING_THE_ACCOUNT]: "Zadejte e-mail uvedený při vytváření účtu.",
    [k.WHAT_WILL_YOU_GAIN_BY_WORKING]: "Co získáte spoluprací",
    [k.WITH_THE_HEAT_DECOR_CREATOR]: "s průvodcem Heat Decor?",
    [k.PRECISE_DESIGNS_OF_THE_HEATING_SYSTEM_IN_THE]: "• Přesné návrhy topného systému v",
    [k.HEAT_DECOR_STANDARD_READY_FOR_USE_BY]: "standardu Heat Decor, připravené k použití",
    [k.THE_INSTALLATION_TEAM]: "instalačním týmem",
    [k.EASIER_AND_MORE_COMPLETE_PREPARATION_OF_AN_OFFER_FOR]: "• Snadnější a kompletnější příprava nabídky pro",
    [k.YOUR_CLIENT]: "vašeho klienta",
    [k.FACILITATING_THE_PROCESS_OF_ORDERING_PRODUCTS]: "• Usnadnění procesu objednávání produktů",
    [k.HEAT_DECOR]: "Heat Decor",
    [k.WE_ARE_CHANGING_THE_FUTURE_FOR]: "Měníme budoucnost na",
    [k.GREEN]: "zelenou",
    [k.WATCH_HEAT_DECOR]: "Sledujte Heat Decor",
    [k.READ_AND_SUBSCRIBE]: "číst a přihlásit se k odběru",
    [k.BLOG]: "blog",
    [k.PRIMARY_USER]: "Hlavní uživatel:",
    [k.HAS_ACCESS_TO_ALL_WIZARDS_FUNCTIONALITIES]: "• Má přístup ke všem funkcím průvodce.",
    [k.DEFINING_THEROLE_IN_THE_COMPANYS_STRUCTURE_WILL_FACILITATE_FURTHER_COOPERATION_WITH_HEAT_DECOR]: "• Určení role ve struktuře firmy usnadní další spolupráci s Heat Decor.",
    [k.SPECIFYING_THE_TYPE_OF_COMPANY_WILL_ALLOW_YOU_TO_ADJUST_THE_WIZARD_TO_YOUR_NEEDS]: "• Určení typu firmy umožní přizpůsobení průvodce vašim potřebám.",
    [k.AFTER_REGISTRATION_HEAT_DECOR_WILL_CONTACT_THE_INDICATED_MAIN_USER]: "• Po registraci bude Heat Decor kontaktovat uvedeného hlavního uživatele.",
    [k.ADDITIONAL_USERS]: "Další uživatelé:",
    [k.THEY_CAN_USE_THE_WIZARD_TO_DEVELOP_DESIGNS_AND_QUOTES_FOR_THEIR_CLIENTS]: "• Mohou používat průvodce k tvorbě návrhů a nabídek pro své klienty.",
    [k.THEY_HAVE_LIMITED_ACCOUNT_EDITING_CAPABILITIES]: "• Mají omezené možnosti úprav účtu.",
    [k.IN_CASE_OF_REPEATED_DIFFICULTIES_WITH_USING_THE_WIZARD_CONTACT_THE_ADMINISTRATOR_OF_THE_HEAT_DECOR_WIZARD]:
        "V případě opakujících se obtíží s používáním průvodce kontaktujte správce průvodce Heat Decor:",
    [k.EMAIL]: "Email",
    [k.TELEPHONE]: "Telefon:",
    [k.PRIVACY_POLICY]: "Zásady ochrany osobních údajů",
    [k.WEBSITE_REGULATIONS]: "Pravidla webu",
    [k.THANK_YOU_FOR_YOUR_REGISTRATION]: "Děkujeme za registraci",
    [k.LOGGING_IN_WILL_BE_POSSIBLE_AFTER_SUCCESSFULLY]: "Přihlášení bude možné po úspěšném",
    [k.SETTING_THE_PASSWORD_AND_VERIFYING_THE_ACCOUNT_BY_THE]: "nastavení hesla a ověření účtu správcem",
    [k.ADMINISTRATOR]: "Administrátorem.",
    [k.YOU_WILL_RECEIVE_A_LINK_TO_SET_A_NEW_PASSWORD]: "Obdržíte odkaz pro nastavení nového hesla.",
    [k.EMAIL_WAS_SENT_FOLLOW_THE_INSTRUCTIONS]: "Email byl odeslán. Postupujte podle pokynů",
    [k.IN_THE_EMAIL]: " uvedených v emailu.",
    [k.CHECK_EMAIL]: "Zkontrolujte email",
    [k.TO_COMPLETE_THE_REGISTRATION_PROCESS_CHECK_THE_MESSAGE_SENT_AUTOMATICALLY_TO_THE_EMAIL_ADDRESS_PROVIDED_AND_CLICK_THE_LINK_TO_CONFIRM_THE_REGISTRATION]:
        "Pro dokončení procesu registrace zkontrolujte zprávu automaticky odeslanou na zadaný email a klikněte na odkaz pro potvrzení registrace.",
    [k.REGISTRATION_ERROR]: "Došlo k chybě při registraci",
    [k.AN_ERROR_OCCURRED_WHILE_CREATING_A_NEW_ACCOUNT]: "Při vytváření nového účtu došlo k chybě.",
    [k.TRY_AGAIN_IF_THE_ERROR_REPEATS_TRY_TO_REGISTER_TO_A_DIFFERENT_EMAIL_ADDRESS_OR_CONTACT_US]:
        "Pokud se chyba opakuje, zkuste se zaregistrovat s jinou emailovou adresou nebo nás kontaktujte.",
    [k.ENTER_THE_ACCOUNT_PRIMARY_USER_INFORMATION]: "Zadejte informace o hlavním uživateli účtu.",
    [k.REGISTRATION]: "Registrace",
    [k.ADDITIONAL_USERS_OPTIONAL]: "Další uživatelé (volitelné)",
    [k.INVESTMENT]: "Investice",
    [k.INVESTORS_NAME]: "Název investora:",
    [k.ADDED_BY]: "Přidáno kým:",
    [k.NOTES]: "Poznámky",
    [k.STREET]: "Ulice",
    [k.CITY]: "Město",
    [k.ZIP_CODE]: "PSČ",
    [k.GROSS]: "hrubý",
    [k.NET_PRICE_FOR_THE_CLIENT]: "Čistá cena pro klienta:",
    [k.NET_PROFIT]: "Čistý zisk:",
    [k.DOWNLOAD_MULTIPLE_PDF_FILES]: "Stáhnout více PDF",
    [k.DELETE_PROJECTS]: "Smazat projekty",
    [k.ADD_TO_CART]: "Přidat do košíku",
    [k.REMOVE_OFFERS]: "Odstranit nabídky",
    [k.GENEROWANIE_OFERTY]: "Generování nabídky",
    [k.RESET_PASS]: "Resetovat heslo",
    [k.VALUE_OF_THE_OFFER]: "Hodnota nabídky",
    [k.YOUR_COST]: "Vaše náklady",
    [k.YOUR_PRICE]: "Vaše cena",
    [k.THE_INVESTOR_PAYS]: "Investor platí",
    [k.YOU_EARN]: "Vyděláváte",
    [k.GO_ON]: "Pokračujte",
    [k.ENTER_NEW_PASSWORD]: "Zadejte nové heslo",
    [k.NEW_PASSWORD]: "Nové heslo",
    [k.PRICE]: "Cena",
    [k.PRICE_FOR_THE_CLIENT]: "Cena pro klienta:",
    [k.A_PRICE_FOR_YOU]: "Cena pro vás:",
    [k.INCORRECT_LOGIN_OR_PASSWORD]: "Nesprávné přihlašovací jméno nebo heslo",
    [k.OFFER]: "Nabídka",
    [k.BY]: "od",
    [k.LIST_OF_ORDERS]: "Seznam objednávek",
    [k.THANK_YOU_FOR_YOUR_ORDER]: "Děkujeme za objednávku",
    [k.GO_TO_ORDERS]: "Přejít na objednávky",
    [k.SMS_CODE]: "SMS kód",
    [k.INCORRECT_AUTHORIZATION_CODE]: "Chybný autorizační kód",
    [k.ENTER_AUTHORIZATION_CODE]: "Zadejte autorizační kód",
    [k.CONFIRMED]: "Potvrzeno",
    [k.RESEND_CODE]: "Znovu odeslat kód",
    [k.REENTER_THE_6_DIGIT_CODE]: "Zadejte znovu 6místný SMS kód č.",
    [k.TO_VERIFY_YOUR_ACCOUNT]: "pro ověření účtu",
    [k.ENTER_6_DIGIT_SMS_CODE_TO_VERIFY_YOU_ACCOUNT]: "Zadejte 6místný SMS kód pro ověření účtu",
    [k.NR]: "č.",
    [k.GO_TO_PAYMENT]: "Přejít k platbě",
    [k.ORDERED_PRODUCTS]: "Objednané produkty",
    [k.NOTES_TO_THE_ORDER]: "Poznámky k objednávce",
    [k.NO_PRODUCTS]: "Žádné produkty",
    [k.THERE_ARE_NO_PRODUCTS_IN_YOUR_CART]: "V košíku nejsou žádné produkty",
    [k.NO_OFFERS]: "Žádné nabídky",
    [k.YOUR_CART]: "Váš košík",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_THE_CUSTOMER]: "Vaše objednávka s doručením zákazníkovi",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_YOUR_WEREHOUSE]: "Vaše objednávka s doručením do vašeho skladu",
    [k.PAY_FOR_YOUR_ORDER]: "Zaplaťte za objednávku",
    [k.PHONE]: "Telefon",
    [k.SHIPPING_TO]: "Doručení do",
    [k.SAVE]: "Uložit",
    [k.EMAIL_IS_REQUIRED]: "Email je vyžadován",
    [k.CODE_AND_CITY_ARE_REQUIRED]: "PSČ a město jsou vyžadovány",
    [k.POSTAL_CODE_AND_CITY]: "PSČ a město",
    [k.STREET_AND_BUILDING_NUMBER_ARE_REQUIRED]: "Ulice a číslo budovy jsou vyžadovány",
    [k.STREET_AND_NUMBER]: "Ulice a číslo",
    [k.NAME_AND_SURNAME]: "Jméno a příjmení",
    [k.DISCOUNT]: "Sleva",
    [k.SUM]: "Součet",
    [k.YOU_WANT_TO_GIVE_A_DISCOUNT_ON_ALL_MATERIAL]: "Chcete dát slevu na celý materiál",
    [k.ALL_MATERIAL_DISCOUNT]: "Sleva na celý materiál",
    [k.SORRY_NOTHING_HERE]: "Omlouváme se... nic zde není.",
    [k.GO_HOME]: "Vraťte se na hlavní stránku",
    [k.I_UNDERSTAND_DO_NOT_SHOW_ON_THE_NEXT_LOGIN]: "Rozumím, nezobrazuj při příštím přihlášení",
    [k.DISTRIBUTOR]: "Distributor",
    [k.GENERATE_REPORT]: "Generovat zprávu",
    [k.SKETCH]: "Náčrt",
    [k.GENERATE_AN_OFFER]: "Generovat nabídku",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECT]: "Opravdu chcete odstranit vybraný projekt?",
    [k.FIND_A_PROJECT]: "Najít projekt",
    [k.THERE_IS_NO_SUCH_PROJECT]: "Takový projekt neexistuje",
    [k.YOU_DONT_HAVE_ANY_PROJECT_YET]: "Zatím nemáte žádný projekt",
    [k.YOU_MUST_HAVE_AN_INVESTOR_AND_AN_INVESTMENT_TO_CREATE_A_PROJECT_FOR_IT]: "Musíte mít investora a investici, abyste mohli vytvořit projekt",
    [k.DO_YOU_WANT_TO_CREATE_AN_INVESTOR]: "Chcete vytvořit investora",
    [k.GETTING_STARTED_WITH_ADDING_AN_INVESTOR]: "Začátek práce začíná přidáním investora",
    [k.ADD_AN_INVESTOR]: "Přidat investora",
    [k.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN]: "Něco se pokazilo, zkuste to znovu",
    [k.SELECT_THE_TYPE_OF_ROOM]: "Vyberte typ místnosti",
    [k.GO_BACK_TO_EDITING]: "Vraťte se k úpravám",
    [k.TITLE]: "Název",
    [k.GROUP]: "Skupina",
    [k.GENERAL]: "Obecné",
    [k.HEATING_FOIL]: "Tepelná fólie",
    [k.ELECTRIC_HEATERS]: "Elektrické topení",
    [k.HEATING_MAT]: "Topná rohož",
    [k.IMAGE]: "Obrázek",
    [k.DRAG_AND_DROP_THE_IMAGE_OR_CLICK_AND_SELECT]: "Přetáhněte a upusťte obrázek nebo klikněte a vyberte",
    [k.PREVIEW]: "Náhled",
    [k.TEXT_EDITOR]: "Editor textu",
    [k.SAVE_CHANGES]: "Uložit změny",
    [k.EXIT]: "Ukončit",
    [k.ADD_A_NEW_COMPANY]: "Přidat novou firmu",
    [k.GO_BACK_TO_THE_LIST]: "Vraťte se na seznam",
    [k.FIRST_NAME]: "Jméno",
    [k.LAST_NAME]: "Příjmení",
    [k.USER]: "Uživatel",
    [k.IS_REQUIRED]: "je vyžadováno",
    [k.FIRST_NAME_IS_REQUIRED]: "Jméno je vyžadováno",
    [k.LAST_NAME_IS_REQUIRED]: "Příjmení je vyžadováno",
    [k.PHONE_IS_REQUIRED]: "Telefon je vyžadován",
    [k.EMAIL_IS_INVALID]: "Email je neplatný",
    [k.ADD_USER]: "Přidat uživatele",
    [k.REGISTER]: "Zaregistrovat",
    [k.COUNTRY]: "Země",
    [k.PHONE_NUMBER]: "Telefonní číslo",
    [k.PREFIX_IS_REQUIRED]: "Prefix je vyžadován",
    [k.NEXT_STEP]: "Další krok",
    [k.TAX_ID_IS_REQUIRED]: "DIČ je vyžadováno",
    [k.COUNTRY_IS_REQUIRED]: "Země je vyžadována",
    [k.TAX_ID]: "DIČ",
    [k.TAX_ID_IS_INCORRECT]: "DIČ je nesprávné",
    [k.ZIP_CODE_IS_REQUIRED]: "PSČ je vyžadováno",
    [k.CITY_IS_REQUIRED]: "Město je vyžadováno",
    [k.HOUSE_NUMBER_IS_REQUIRED]: "Číslo domu je vyžadováno",
    [k.NUMBER]: "Číslo",
    [k.DOCUMENT_NUMBER]: "Číslo dokumentu",
    [k.DOWNLOAD]: "Stáhnout",
    [k.WAS_THIS_ARTICLE_HELPFUL]: "Byl tento článek užitečný",
    [k.MIN_READING]: "min čtení",
    [k.SEE_MORE]: "VÍCE",
    [k.ADDRESS]: "Adresa",
    [k.DEFAULT_ADDRESS]: "Výchozí adresa",
    [k.NAME]: "Název",
    [k.NAME_OF_THE_INVESTMENT]: "Název investice",
    [k.SEARCH_CRITERIA]: "Kritéria vyhledávání",
    [k.WHO_ADDED]: "Kdo přidal",
    [k.IS_IT_A_COMPANY]: "Je to firma",
    [k.BUSINESS]: "Firma",
    [k.NATURAL_PERSON]: "Fyzická osoba",
    [k.YOU_DONT_HAVE_ANY_INVESTOR_YET]: "Zatím nemáte žádného investora",
    [k.DO_YOU_WANT_TO_ADD_IT_NOW]: "Chcete ho nyní přidat",
    [k.THERE_IS_NO_SUCH_INVESTOR]: "Takový investor neexistuje",
    [k.YOU_DONT_HAVE_ANY_INVESTMENTS_YET]: "Zatím nemáte žádné investice",
    [k.ADD_YOUR_FIRST_INVESTMENT]: "Přidat první investici",
    [k.THERE_IS_NO_SUCH_INVESTMENT]: "Taková investice neexistuje",
    [k.ADD_AN_INVESTMENT]: "Přidat investici",
    [k.INVESTOR]: "Investor",
    [k.CONTACT_PERSON]: "Kontaktní osoba",
    [k.STREET_IS_REQUIRED]: "Ulice je vyžadována",
    [k.NO_IS_REQUIRED]: "Číslo je vyžadováno",
    [k.CODE_IS_REQUIRED]: "Kód je vyžadován",
    [k.THE_ENERGY_CLASS_IS_REQUIRED]: "Energetická třída je vyžadována",
    [k.CUSTOM_ENERGY_CLASS_IS_REQUIRED]: "Vlastní energetická třída je vyžadována",
    [k.GENERATE_OFFERS]: "Generovat nabídky",
    [k.THE_OFFER_IS_ALREADY_IN_THE_CART_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_IT]: "Tato nabídka je již v košíku, opravdu ji chcete odstranit?",
    [k.COMPANY_NAME_IS_REQUIRED]: "Název firmy je vyžadován",
    [k.THE_ADDRESS_IS_REQUIRED]: "Adresa je vyžadována",
    [k.THE_ZIP_CODE_IS_INCORRECT]: "PSČ je nesprávné",
    [k.THE_CITY_IS_NOT_CORRECT]: "Město je nesprávné",
    [k.THE_HOUSE_NUMBER_IS_INCORRECT]: "Číslo domu je nesprávné",
    [k.COMPANY_NAME]: "Název firmy",
    [k.PREFIX_AND_PHONE_NUMBER_ARE_REQUIRED]: "Prefix a telefonní číslo jsou vyžadovány",
    [k.POSTAL_CODE_AND_CITY_ARE_REQUIRED]: "PSČ a město jsou vyžadovány",
    [k.STREET_AND_HOUSE_NUMBER_ARE_REQUIRED]: "Ulice a číslo domu jsou vyžadovány",
    [k.FULL_NAME_IS_REQUIRED]: "Celé jméno je vyžadováno",
    [k.REQUIRED_FIELD]: "Povinné pole",
    [k.REQUIRED]: "Povinné",
    [k.ADD_A_PROJECT]: "Přidat projekt",
    [k.PROJECT_NAME_IS_REQUIRED]: "Název projektu je vyžadován",
    [k.THE_BUILDING_NUMBER_IS_REQUIRED]: "Číslo budovy je vyžadováno",
    [k.No]: "Č.",
    [k.ADDITIONAL_CONTACT_PERSON]: "Další kontaktní osoba",
    [k.CLASS_ENERGY_IS_REQUIRED]: "Energetická třída je vyžadována",
    [k.ENERGY_CLASS]: "Energetická třída",
    [k.THE_STREET_IS_NOT_CORRECT]: "Ulice není správná",
    [k.HOUSE_NUMBER]: "Číslo domu",
    [k.TYPE_OF_INVESTMENT]: "Typ investice",
    [k.CODE]: "Kód",
    [k.THE_INVESTMENT_HAS_NOT_BEEN_ADDED_TRY_AGAIN]: "Investice nebyla přidána. Zkuste to znovu",
    [k.COMPANY_TYPE]: "Typ společnosti",
    [k.PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_AN_SMS_CODE]: "Telefon pro ověření účtu pomocí SMS kódu",
    [k.GRAPHICS]: "Grafika",
    [k.GO_BACK_TO_LOGIN]: "Vraťte se k přihlášení",
    [k.ADD_A_NEW_INVESTMENT]: "Přidat novou investici",
    [k.GO_BACK_TO_THE_LIST_OF_INVESTMENTS]: "Vraťte se na seznam investic",
    [k.ADD_A_NEW_INVESTOR]: "Přidat nového investora",
    [k.COMPLETE_THE_INVESTOR_PROFILE]: "Doplňte profil investora",
    [k.GO_BACK_TO_INVESTORS]: "Vraťte se k investorům",
    [k.PROJECT_NAME]: "Název projektu",
    [k.WHAT_DO_YOU_WANT_TO_DESIGN]: "Co chcete navrhnout",
    [k.UPLOAD_THE_FILE_WITH_THE_VIEW]: "Nahrajte soubor s pohledem",
    [k.ADD_FILE]: "PŘIDAT SOUBOR",
    [k.DISCARD_CHANGES]: "Zrušit změny",
    [k.DRAW_ANY_ROOM_PLAN]: "Nakreslete libovolný plán místnosti",
    [k.GENERATE_AS_IMAGE]: "Generovat jako obrázek",
    [k.DRAW_PLAN]: "KRESLIT PLÁN",
    [k.CREATE_A_PROJECT]: "Vytvořit projekt",
    [k.CREATE_A_THROW_IN_THE_WIZARD]: "Vytvořit návrh v průvodci",
    [k.COME_BACK]: "Vraťte se",
    [k.HEATING_MAT_UNDER_THE_TILES]: "Topná rohož pod dlaždice",
    [k.HEATING_FOIL_UNDER_THE_SPOUT]: "Tepelná fólie pod potěr",
    [k.HEATING_FOIL_UNDER_THE_SPC_VINYL_PANELS]: "Tepelná fólie pod SPC vinylové panely",
    [k.HEATING_FOIL_FOR_LAMINATED_PANELS]: "Tepelná fólie pod laminátové panely",
    [k.SELECT_A_HEATING_SYSTEM]: "Vyberte topný systém",
    [k.GIVE_THE_REAL_DIMENSION]: "ZADEJTE SKUTEČNÝ ROZMĚR",
    [k.ENTER_THE_REAL_LENGTH]: "Zadejte skutečnou délku",
    [k.DESIGNATED_EPISODE]: "označeného úseku",
    [k.SEE_PREVIEW]: "Zobrazit náhled",
    [k.ADJUST_THE_VIEW_BY_TRIMMING_AND_ROTATING_IT]: "Přizpůsobte pohled oříznutím a otočením",
    [k.THERE_ARE_NO_PRODUCTS_FOR_THE_GIVEN_CRITERIA]: "Nejsou žádné produkty pro zadaná kritéria",
    [k.CHOOSE_A_MODEL]: "VYBERTE MODEL",
    [k.PLN_GROSS]: "Kč hrubý",
    [k.FOR_CALIBRATION_MARK_THE_LONGEST_SIDE_OF_THE_ROOM]:
        "Pro kalibraci vyberte libovolnou stranu místnosti. Klikněte na bod v náčrtu a přetáhněte jej na konec vybrané čáry, aby byla kalibrace dokončena.",
    [k.STEP1_DRAW_THE_OUTLINE_OF_THE_ROOM]: "Krok 1: Nakreslete obrys místnosti",
    [k.SELECT_THE_TYPE_OF_THE_SELECTED_FIXED_BUILDING_ELEMENT]: "Vyberte typ vybraného pevného stavebního prvku",
    [k.STEP2_APPLY_THE_SURFACES_EXCLUDED_FOR_PERMANENT_CONSTRUCTION]: "Krok 2: Naneste povrchy vyloučené pro pevnou zástavbu",
    [k.ADD_A_LAYER_OF_VAPOR_BARRIER_FOIL]: "Přidejte vrstvu parotěsné fólie",
    [k.PLACE_THE_THERMOREGULATOR]: "Umístěte termoregulátor na okraj místnosti",
    [k.SELECT_THE_ENERGY_CLASS_OF_THE_BUILDING]: "Vyberte energetickou třídu budovy",
    [k.THE_PROJECT_HAS_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_EXIT_THE_WIZARD]: "Projekt má neuložené změny. Opravdu chcete opustit průvodce?",
    [k.DO_YOU_WANT_TO_SAVE_THE_CHANGES]: "Chcete uložit změny?",
    [k.CONFIRM_NEW_PASSWORD]: "Potvrďte nové heslo",
    [k.SET_A_PASSWORD]: "Nastavit heslo",
    [k.THE_PHONE_IS_NOT_VALID]: "Telefon není platný",
    [k.MANAGE_ADDRESSES]: "Spravujte adresy",
    [k.PLEASE_SELECT_A_SHIPPING_ADDRESS]: "Vyberte doručovací adresu",
    [k.ADD_A_COMPANY]: "Přidat společnost",
    [k.SEARCH_FOR_A_COMPANY]: "Hledat společnost",
    [k.MESSAGE]: "Zpráva",
    [k.NO_EMAIL_ADDRESS]: "Žádná emailová adresa",
    [k.EDIT_CUSTOMER_DETAILS]: "Upravit údaje zákazníka",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMPANY]: "Opravdu chcete tuto společnost odstranit?",
    [k.RULER_CALIBRATION]: "Kalibrace pravítka",
    [k.CALIBRATE_TOOL_TO_TEACH_A_DISTANCE_CALCULATION_PROGRAM_FOR_YOUR_THROW]: "Kalibrujte nástroj, aby program naučil výpočet vzdálenosti pro váš plán",
    [k.SEE_AN_EXAMPLE]: "Zobrazit příklad",
    [k.CALCULATE_YOUR_HEATING_COSTS]: "Vypočítejte si náklady na vytápění",
    [k.THE_AREA_OF_THE_ROOM]: "Plocha místnosti",
    [k.PERMANENT_BUILDINGS]: "Pevná zástavba",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ROOM]: "Opravdu chcete místnost odstranit?",
    [k.THERMOREGULATORS]: "Termoregulátory",
    [k.FLOOR_SENSOR]: "Podlahový senzor",
    [k.INDOOR_AIR_SENSOR]: "Vnitřní senzor vzduchu",
    [k.OUTSIDE_AIR_SENSOR]: "Venkovní senzor vzduchu",
    [k.OPTIONAL]: "Volitelně",
    [k.PROGRAMMING]: "Programování",
    [k.LEVEL_OF_SECURITY]: "Úroveň zabezpečení",
    [k.CONTROL_PANEL]: "Ovládací panel",
    [k.TOUCH]: "Dotykový",
    [k.KEYBOARD]: "Klávesnice",
    [k.KNOB]: "Knoflík",
    [k.FIND_OUT_MORE]: "Zjistěte více",
    [k.CHOOSE]: "Vybrat",
    [k.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT_FROM_YOUR_CART]: "Opravdu chcete tento produkt odstranit z košíku?",
    [k.FILTER]: "FILTROVAT",
    [k.CUTTING]: "Řezání",
    [k.POWER]: "Výkon",
    [k.MAX_TEMP]: "Max teplota",
    [k.AREA]: "Plocha",
    [k.HEATING_MAT_POWER]: "Výkon topné rohože",
    [k.HEIGHT]: "Výška",
    [k.THICKNESS]: "Tloušťka",
    [k.PRODUCT_PARAMETERS]: "PARAMETRY PRODUKTU",
    [k.LENGTH]: "Délka",
    [k.HEATING_POWER]: "Tepelný výkon",
    [k.FOIL_SURFACE]: "Povrch fólie",
    [k.MAX_HEATING_TEMPERATURE]: "Max teplota vytápění",
    [k.THE_WIDTH_OF_THE_FILM]: "Šířka fólie",
    [k.POSSIBILITY_TO_CUT_THE_FILM_CO]: "Možnost řezání fólie každých",
    [k.MAT_AREA]: "Plocha rohože",
    [k.THERMOSTAT_MODEL]: "Model termostatu",
    [k.RESISTANCE]: "Odpor",
    [k.FOIL_MODEL]: "Model fólie",
    [k.BRAND]: "Značka",
    [k.WEIGHT]: "Hmotnost",
    [k.FOIL_GUARANTEE]: "Záruka na fólii",
    [k.CONNECTION_KITS_PCS]: "Spojovací sady [ks]",
    [k.WIRES]: "Dráty",
    [k.TAPE_PCS]: "Páska [ks]",
    [k.THERMOSTAT_WARRANTY]: "Záruka na termostat",
    [k.CONNECTOR]: "Konektor",
    [k.MAT_GUARANTEE]: "Záruka na rohož",
    [k.PRO_TAPE_PCS]: "PRO páska [ks]",
    [k.PRODUCT_DESCRIPTION]: "Popis produktu",
    [k.RETURN_TO_THE_KNOWLEDGE_BASE]: "Vraťte se do znalostní báze",
    [k.SIMILAR_ARTICLES]: "Podobné články",
    [k.NUMBER_OF_INVESTMENTS]: "Počet investic",
    [k.VIEW_PROFILE]: "Zobrazit profil",
    [k.LATEST_INVESTMENTS]: "Nejnovější investice",
    [k.THERE_ARE_NO_PRODUCTS_IN_THE_PROJECT]: "V projektu nejsou žádné produkty",
    [k.BILL_OF_MATERIALS_FOR_PROJECTS]: "Seznam materiálů pro projekty",
    [k.YOUR_CALCULATION_WITHOUT_ASSEMBLY]: "VAŠE KALKULACE BEZ MONTÁŽE",
    [k.YOU_WANT_TO_ADD_A_MONTAGE]: "Chcete přidat montáž",
    [k.NUMBER_OF_KILOMETERS]: "Počet kilometrů",
    [k.PRICE_FOR_ASSEMBLY]: "Cena za montáž",
    [k.YOUR_CALCULATION_WITH_ASSEMBLY]: "VAŠE KALKULACE S MONTÁŽÍ",
    [k.CHECK_WHAT_RATE_YOU_ARE_AT]: "Zkontrolujte, na jaké sazbě jste",
    [k.SHOW_HEATING_COSTS]: "Zobrazit náklady na vytápění",
    [k.USABLE_AREA]: "Užitná plocha",
    [k.SELECTED_ENERGY_CLASS]: "Vybraná energetická třída",
    [k.THE_PRICE_OF_ELECTRICITY]: "Cena elektřiny",
    [k.HEATING_PRICE_IN_KWH_YEAR]: "Cena vytápění v kWh/rok",
    [k.SAVE_AND_GENERATE_AN_OFFER]: "Uložit a generovat nabídku",
    [k.NUMBER_OF_PROJECTS]: "Počet projektů",
    [k.GO_TO_INVESTMENT]: "Přejít na investici",
    [k.LATEST_PROJECTS]: "Nejnovější projekty",
    [k.ASSEMBLY]: "Montáž",
    [k.START_TYPING]: "Začněte psát",
    [k.ORDERS_PLACED]: "Podané objednávky",
    [k.NO_ORDERS_PLACED]: "Žádné podané objednávky",
    [k.SUBMIT]: "Odeslat",
    [k.SAYS]: "říká",
    [k.ROTATE]: "Otočit",
    [k.SCALE]: "Měřítko",
    [k.ADD_A_NEW_PROJECT]: "Přidat nový projekt",
    [k.GO_BACK_TO_PROJECTS]: "Vraťte se k projektům",
    [k.SELECT_THE_SIDE_ON_WHICH_THE_VIEW_IS_LOCATED]: "Vyberte stranu, na které se nachází pohled",
    [k.STEP]: "Krok",
    [k.CREATED_BY]: "vytvořeno",
    [k.INVESTOR_NAME]: "Název investora",
    [k.YOU_DONT_HAVE_ANY_OFFER_YET]: "Zatím nemáte žádnou nabídku",
    [k.CURRENT_VIEW_CREATOR_SAVED]: `Po stisknutí tlačítka "ULOŽIT A UKONČIT" bude aktuální zobrazení z průvodce uloženo do nabídky PDF. V ostatních případech nebude zobrazení uloženo.`,
    [k.ENTER_DWG_FILE_SIZE]: `Zadejte velikost souboru DWG.`,
    [k.CREATED_BY2]: `Vytvořeno`,
    [k.DO_YOU_WANT_TO_SAVE_CHANGES]: `Chcete uložit změny?`,
    [k.NUMBER_OF_ROOMS]: `Počet místností`,
    [k.FOIL]: "Fólie",
    [k.MAT]: "Rohož",
    [k.NETTO_PRICE]: "Cena netto",
    [k.QUANTITY_PCS_M]: "Množství ks/m",
    [k.NET_VALUE]: "Čistá hodnota",
    [k.MATERIAL_COST]: "Náklady na materiál",
    [k.INVESTOR_COST]: "Náklady investora",
    [k.ADD_NOTE]: "Přidat poznámku",
    [k.REMOVE_PROJECT_FROM_OFFER]: "Odstranit projekt z nabídky",
    [k.COVERING_AREA_OF_ROOM]: "Pokrytí plochy místnosti",
    [k.SEE_ASSORTMENT]: "Zobrazit sortiment",
    [k.LP]: "Poř. č.",

    [k.SUMMARY]: "Souhrn",
    [k.THE_ORDER_HAS_BEEN_FORWARDED_FOR_EXECUTION]: "Objednávka byla předána k realizaci",
    [k.THE_STATUS_OF_THE_ORDER_CAN_BE_TRACKED_IN_THE_ORDERS_TAB]: "Stav objednávky můžete sledovat na kartě Objednávky",
    [k.THANK_YOU_FOR_YOUR_TRUST]: "Děkujeme za důvěru",

    [k.TO_PAY]: "K zaplacení",
    [k.SHIPPING_TO_YOUR_OWN_WAREHOUSE]: "Doručení do vašeho skladu",
    [k.SHIPPING_TO_THE_CUSTOMER]: "Doručení zákazníkovi",
    [k.YOU_HAVE_RECEIVED_AN_ADDITIONAL_DISCOUNT_RESULTING_FROM_COMBINING_TWO_INVESTMENTS_INTO_ONE_ORDER]:
        "Obdrželi jste dodatečnou slevu vyplývající ze spojení dvou investic do jedné objednávky",
    [k.SHIPPING_ADDRESS]: "Doručovací adresa",
    [k.ADDRESSEE]: "Adresát",
    [k.WAREHOUSE_ADDRESS]: "Adresa skladu",
    [k.CONTACT]: "Kontakt",
    [k.CHOOSE_PAYMENT_METHOD]: "Vyberte způsob platby",
    [k.TRADITIONAL_BANK_TRANSFER]: "Tradiční bankovní převod",
    [k.PAYMENT_DEADLINE]: "Termín platby",
    [k.CASH_ON_DELIVERY]: "Dobírka",
    [k.INVESTMENT_DATA]: "Údaje o investici",
    [k.DELIVERY_ADDRESS]: "Doručovací adresa",
    [k.LIST_OF_PRODUCTS]: "Seznam produktů",
    [k.PRODUCT_NAME]: "Název produktu",
    [k.AMOUNT_OF_PRODUCTS]: "Počet produktů",
    [k.NET_PRICE]: "Čistá cena",
    [k.GROSS_PRICE]: "Hrubá cena",
    [k.INFO_IF_YOU_WANT_TO_ADD_RESERVE_MATERIAL_TO_AN_INVESTMENT_CLICK_EDIT_IN_THE_TOP_RIGHT_CORNER_OF_THE_PRODUCT_LIST]:
        "Info: Pokud chcete přidat rezervní materiál k investici, klikněte na upravit v pravém horním rohu seznamu produktů.",
    [k.INVESTMENT_ADDRESS]: "Adresa investice",
    [k.BANK_TRANSFER_DETAILS]: "Údaje pro bankovní převod",
    [k.RECIPIENT]: "Příjemce",
    [k.ACCOUNT_NUMBER]: "Číslo účtu",
    [k.DOWNLOAD_PDF]: "Stáhnout PDF",
    [k.GO_TO_ORDER]: "Přejít k objednávce",
    [k.CHOOSE_A_BANK]: "Vyberte banku",
    [k.THE_VALUE_OF_THE_MATERIALS]: "Hodnota materiálů",
    [k.SHOPPING_CART_ITEMS]: "Položky v košíku",
    [k.INFO_TO_EDIT_THE_SHIPPING_ADDRESS_CLICK_ON_THE_PENCIL_IN_THE_UPPER_RIGHT_CORNER]: "Info: Pro úpravu doručovací adresy klikněte na tužku v pravém horním rohu",
    [k.NO_ORDERS]: "Žádné objednávky",
    [k.STATUS_OF_THE_ORDER]: "Stav objednávky",
    [k.DATE]: "Datum",
    [k.SHIPPING_NO]: "Číslo přepravy",
    [k.DOWNLOAD_THE_INVOICE]: "Stáhnout fakturu",
    [k.MORE_INFORMATION]: "Více informací",
    [k.SHIPPING_INFORMATION]: "Doručovací údaje",
    [k.ORDER_STATUS]: "Stav objednávky",
    [k.CONTACT_PERSON2]: "Kontaktní osoba",
    [k.ORDER_NOTES]: "Poznámky k objednávce (volitelné)",
    [k.QUOTA]: "Kvóta",
    [k.GETTING_STARTED_ON_A_PROJECT_STARTS_WITH_ADDING_AN_INVESTMENT]: "Začátek práce na projektu začíná přidáním investice",
    [k.DUPLICATE]: "Duplikovat",
    [k.ROOMS]: "Místnosti",
    [k.COMPLETED_OFFERS]: "Dokončené nabídky",
    [k.NO_PROJECTS]: "Žádné projekty",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECTS]: "Opravdu chcete smazat vybrané projekty?",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_OFFERS]: "Opravdu chcete smazat vybrané nabídky?",
    [k.COMPANY]: "Společnost",
    [k.YOUR_ADVANTAGE_IN_ASSEMBLY]: "Vaše výhoda při montáži",
    [k.YOUR_BENEFIT_ON_THE_MATERIAL]: "Vaše výhoda na materiálu",
    [k.ADDED_TO_CART]: "Přidáno do košíku",
    [k.CREATED]: "Vytvořeno",
    [k.PENDING]: "Čekající",
    [k.COMPLETE]: "Dokončeno",
    [k.REJECTED]: "Odmítnuto",
    [k.GO_TO_OFFERS]: "Přejít na nabídky",
    [k.DONE_WITH_INSTALLATION]: "Provedeno s montáží",
    [k.DONE_WITHOUT_INSTALLATION]: "Provedeno bez montáže",
    [k.THE_NAME_OF_THE_INVESTMENT_IS_REQUIRED]: "Název investice je vyžadován",
    [k.INVESTOR_DATA]: "Údaje investora",
    [k.USERS]: "Uživatelé",
    [k.RULES]: "Pravidla",
    [k.CONTACT_DETAILS]: "Kontaktní údaje",
    [k.THE_LOGO_WILL_BE_USED_ON_THE_OFFER_AND_INVOICES_FOR_THE_CUSTOMER]: "Logo bude použito na nabídce a fakturách pro zákazníka",
    [k.UPLOAD_THE_FILE]: "Nahrajte soubor",
    [k.UPLOAD_THE_LOGO_FILE]: "Nahrajte soubor s logem",
    [k.MAX_SIZE]: "Max. velikost",
    [k.MAX_DIMENSIONS]: "Max. rozměry",
    [k.CURRENT_FILE]: "Aktuální soubor",
    [k.NO_PHOTO]: "Žádná fotografie",
    [k.DELETE_FILE]: "Smazat soubor",
    [k.WIZARD_LANGUAGE]: "Jazyk průvodce",
    [k.COMPANY_LOGO]: "Logo společnosti",
    [k.MAIN_USER]: "Hlavní uživatel",
    [k.OTHER_USERS]: "Ostatní uživatelé",
    [k.ADD_NEW_PROFILE]: "přidat nový profil",
    [k.CHANGES_HAVE_BEEN_MADE_DO_YOU_WANT_TO_SAVE_THEM]: "Byly provedeny změny, chcete je uložit?",
    [k.DISCARD]: "Zahodit",
    [k.POSITION_OPTIONAL]: "Pozice (volitelně)",
    [k.LOCK_PROFILE]: "zablokovat profil",
    [k.DELETE_PROFILE]: "smazat profil",
    [k.DO_SAVE_CHANGES]: "Chcete uložit změny?",
    [k.POSITION]: "Pozice",
    [k.THE_NAME_OF_THE_ENERGY_CLASS_IS_REQUIRED]: "Název energetické třídy je vyžadován",
    [k.THE_NAME_OF_THE_ENERGY_CLASS]: "Název energetické třídy",
    [k.EU_DEMAND]: "Požadavek EU",
    [k.EU_REQUIREMENT_IS_REQUIRED]: "Požadavek EU je vyžadován",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT]: "Topení pod potěrem",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_IS_REQUIRED]: "Topení pod potěrem je vyžadováno",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "Topení pod potěrem pro místnosti: koupelna, toaleta, prádelna, sauna.",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_SAUNA_IS_REQUIRED]: "Topení pod potěrem pro místnosti: koupelna, toaleta, prádelna, sauna je vyžadováno",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL]: "Topení pod panelem",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL_IS_REQUIRED]: "Topení pod panelem je vyžadováno",
    [k.REMOVE_ENERGY_CLASS]: "smazat energetickou třídu",
    [k.TABLE_OF_ENERGY_CLASSES]: "Tabulka energetických tříd",
    [k.EU_DEMAND_EVERY_KWH]: "Požadavek EU na KWh/m2*rok",
    [k.SYSTEM_UNDER_THE_SPOUT]: "Systém pod potěrem",
    [k.SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "Systém pod potěrem pro místnosti (koupelna, toaleta, prádelna, sauna)",
    [k.SYSTEM_UNDER_THE_PANEL]: "Systém pod panelem",
    [k.INFO_YOU_CAN_ADD_YOUR_OWN_ENERGY_CLASS_SETTINGS]: "Info: Můžete přidat vlastní nastavení energetické třídy.",
    [k.ADD_YOUR_OWN_ENERGY_CLASS]: "Přidat vlastní energetickou třídu",
    [k.ASSEMBLY_RULE]: "Pravidlo montáže",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_FROM_THE_USABLE_AREA_OF_THE_ROOM]: "Pozor! Čistá částka se počítá z užitné plochy místnosti (obrys).",
    [k.HEAT_DECOR_RECOMMENDATION]: "Doporučení Heat Decor",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_ON_THE_SURFACE_OF_THE_HEATING_MAT]: "Pozor! Čistá částka se počítá z povrchu topné rohože.",
    [k.VAT_RULE]: "Pravidlo DPH",
    [k.ACTIVE_VAT_RULES]: "Aktivní pravidla DPH",
    [k.ADD_A_NEW_USER]: "Přidat nového uživatele",
    [k.UNLOCK_PROFILE]: "odblokovat profil",
    [k.NET_PRICE_PER_M2]: "Čistá cena za m2",
    [k.SYSTEM_FOR_TILES]: "Systém pro dlaždice",
    [k.INSULATION_MAT]: "Izolační rohož 5 mm",
    [k.XPS_EXTRUDED_POLYSTYRENE]: "Extrudovaný polystyren XPS 6 mm",
    [k.SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY]: "Systém pro panely (podložka XPS 6 mm)",
    [k.SYSTEM_FOR_PANELS_5_MM_BASE]: "Systém pro panely (podložka 5 mm)",
    [k.THE_MINIMUM_VALUE_MUST_NOT_BE_GREATER_THAN_THE_MAXIMUM]: "Minimální hodnota nesmí být větší než maximální",
    [k.UNDER_LAMINATED_PANELS]: "pod laminátové panely",
    [k.UNDER_THE_SPOUT]: "pod potěr",
    [k.UNDER_SPC_VINYL_PANELS]: "pod SPC vinylové panely",
    [k.PRODUCT_TAILORED_TO_YOUR_SYSTEM]: "produkt přizpůsobený vašemu systému",
    [k.YEARS_WARRANTY]: "let záruky",
    [k.THERMOREGULATOR]: "Termoregulátor",
    [k.INTERNAL_AIR_SENSOR]: "Vnitřní senzor vzduchu",
    [k.EXTERNAL_AIR_SENSOR]: "Vnější senzor vzduchu",
    [k.CONNECTION]: "Připojení",
    [k.HAS]: "Má",
    [k.DOES_NOT_HAVE]: "Nemá",
    [k.WHITE]: "Bílý",
    [k.BLACK]: "Černý",
    [k.MANUAL]: "Manuální",
    [k.INFO_ZIGBEE]: "Info: *Do projektu bude přidána centrála ZigBee 3.0 nezbytná pro provoz systému. Podporuje až 50 zařízení.",
    [k.SKIP]: "Přeskočit",
    [k.ATTENTION]: "Pozor!",
    [k.ERROR]: "Chyba",
    [k.INFO]: "Info",
    [k.DO_YOU_WANT_TO_ADD_ANOTHER_ELEMERNTS]: "Chcete přidat další prvky pevné zástavby?",
    [k.RECTANGLE]: "Obdélník",
    [k.LINE]: "Čára",
    [k.ADDITIONAL_DISCOUNT_HAS_BEEN_CHARGED]: "Byla uplatněna další sleva!",
    [k.IRREGULAR_SHAPE]: "Nepravidelný tvar",
    [k.VAPOR_BARRIER_FILM]: "Parotěsná fólie",
    [k.INSULATING_UNDERLAY]: "Izolační podložka",
    [k.WIRES_BROWN]: "Kabely - hnědý",
    [k.WIRES_BLUE]: "Kabely - modrý",
    [k.CONNECTORS_FOR_HEATING_FOIL]: "Konektory pro topnou fólii (typ A nebo B)",
    [k.SELF_FUSING_TAPE]: "Samovulkanizační páska",
    [k.HEAT_DECOR_ADHESIVE_TAPE]: "Lepicí páska Heat Decor",
    [k.ARRANGEMENT]: "Uspořádání",
    [k.DRAG]: "Přetáhnout",
    [k.DELETE_ROOM]: "Smazat místnost",
    [k.INCORRECT_FORMAT]: "Nesprávný formát",
    [k.USE_HAND]: "Posunout pohled",
    [k.FILE_TYPE_MUST_BE]: "Nesprávný typ souboru. Soubor musí být typu: image/jpeg, image/png, .pdf, image/gif, .dwg image/jpeg, image/png, .pdf, image/gif nebo .dwg",
    [k.THERE_IS_NO_SUCH_OFFER]: "Taková nabídka neexistuje",
    [k.COPY_DATA]: "Kopírovat data",
    [k.DELETE_THERMOSTAT]: `Smazat termostat`,
    [k.SHOWER_BATH__WASHING_MACHINE]: `Sprcha/vana/pračka`,
    [k.FREESTANDING_WASHBASIN__FREESTANDING_BOWL]: `Stojící umyvadlo/stojící mísa`,
    [k.WALL_HUNG_WASHBASIN__HUNG_BOWL]: `Závěsné umyvadlo/závěsná mísa`,
    [k.SEWAGE_GRATE]: `Kanalizační mřížka`,
    [k.SELECT_POINTS_TO_CREATE_A_FIGURE]: `Vyberte body pro vytvoření obrazce`,
    [k.STAINLESS_STEEL_MESH]: "Nerezová ocelová síť",
    [k.GROUNDING_WIRE]: "Uzemňovací kabel",
    [k.CONNECTORS_FOR_STAINLESS_STEEL_MESH]: "Konektory pro nerezovou ocelovou síť (typ A nebo B)",
    [k.SELF_VULCANIZING_TAPE_FOR_HEATING_FOIL_CONNECTORS]: "Samovulkanizační páska pro konektory topné fólie",
    [k.REINFORCED_TAPE]: "Zesílená páska",
    [k.INSTALLATION_BOX]: "Instalační krabice",
    [k.PROTECTIVE_CONDUIT]: "Ochranný peszel",
    [k.CITY]: "Město",
    [k.INPUTENERGY]: "Energetická třída",
    [k.LISTOFINVEST]: "Seznam investic",
    [k.PROJECTSSTATUS]: "Stav projektu",
    [k.FLOORAREA]: "Plocha",
    [k.COMPLETENOTES]: "Doplňte poznámky",
    [k.TEXTADDINVEST]:
        "Investor v Průvodci Heat Decor je fyzická nebo právnická osoba, která plánuje zakoupit materiály potřebné pro instalaci moderního topného systému a případně zadat jeho montáž. Identifikační údaje investora zahrnují DIČ, název v případě právnické osoby a jméno a příjmení v případě fyzické osoby, a také kontaktní údaje.",
    [k.IMPORTANT]: "Důležité!",
    [k.TEXTADDINVEST2]: "Místo bydliště nebo podnikání investora musí být v Polsku.",
    [k.INVESTORPROFILTEXT]: "Pro tohoto investora neexistuje žádná investice",
    [k.INVESTORPROFILTEXT2]: "Chcete ji nyní přidat?",
    [k.INVESTORPROFILTEXT3]: "Začátek práce na projektu začíná přidáním investice",
    [k.HEATING_SYSTEM]: "Topení",
    [k.SYSTEMCOMPONENTS]: "Komponenty systému",
    [k.MODIFY_THE_PERMANENT_STRUCTURE]: "Upravit pevnou zástavbu",
    [k.ACCESSORIES]: "Příslušenství",
    [k.DATE_CREATED]: "Datum vytvoření",
    [k.COPY_OFFER]: "Kopírovat nabídku",
    [k.UNDO]: "ZPĚT",
    [k.FILL_IN_THE_DETAILS]: "Doplňte údaje kontaktní osoby",
    [k.E_G]: "např. Architekt",
    [k.ADD_INVEST_TEXT]:
        "Investice v Průvodci Heat Decor zahrnuje všechny místnosti v budově, ve kterých je plánován moderní topný systém. Identifikační údaje investice zahrnují nahrané plány jednotlivých místností nebo podlaží, užitnou plochu jednotlivých místností, vyznačené bezpečnostní zóny, ve kterých není dovolena instalace elektrického podlahového topení, označená místa s pevnou zástavbou bez nožiček o minimální výšce 3 cm, potřebu energie potřebnou k vytápění vyplývající z tepelné izolace budovy (EUco) a adresní údaje.",
    [k.COMPLATE_THE_INVEST]: "Doplňte údaje o investici",
    [k.DISCOUNT_VALUE]: "Hodnota slevy",
    [k.ADD_MONTAGE]: "Přidat montáž",
    [k.MOUNTING_SYSTEM]: "Montážní systém",
    [k.COST_OF_INSTALL]: "Cena montážní služby",
    [k.NOTE_CHECK]: "Pozor! Zkontrolujte, jak máte nastaveno pravidlo montáže",
    [k.VAT_RATE]: "Sazba DPH",
    [k.NOTE_CHECK_VAT]: "Pozor! Zkontrolujte, na jaké sazbě DPH se nacházíte!",
    [k.HEATING_COSTS]: "Náklady na vytápění",
    [k.ADD_HEATING_COSTS]: "Přidat náklady na vytápění",
    [k.RESET]: "Resetovat",
    [k.RETURN_TO_OFFERS]: "ZPĚT K NABÍDKÁM",
    [k.LACK_OF_ROOMS]: "Žádné místnosti",
    [k.DRAW_A_ROOM]: "Namalujte místnost",
    [k.HEATING_FOIL_UNDER_THE_LAYERED]: "Topná fólie pod vrstvenou deskou",
    [k.CONFIGURATION_OF_PRICE]: "Nastavení ceny za 1 km",
    [k.SALEOFFER]: "Nabídka prodeje",
    [k.COSTOFINSTALLSERVICE]: "Cena montážní služby",
    [k.COSTOFALLMATERIALS]: "Cena všech materiálů",
    [k.LISTOFMATERIALS]: "Seznam materiálů",
    [k.MATERIAL]: "Materiál",
    [k.NO]: "Ne",
    [k.MANUFACTUREROFHEATING]: "Výrobce topných fólií a topných rohoží: Heat Decor",
    [k.VISUALIZATION]: "Vizualizace topného systému",
    [k.VISUALIZATIONOFTHEHEATING]: "Vizualizace topného systému je přílohou této nabídky.",
    [k.LOGODISTRIBUTOR]: "Logo distributora",
    [k.VISUALIZATIONMADE]: "Vizualizace provedena společností",
    [k.AREAOFTHEHEATING]: "Plocha topné fólie",
    [k.POWEROFTHEHEATING]: "Výkon topné fólie",
    [k.ELECTRICALCONNECTION]: "Elektrické připojení",
    [k.ESTIMATEDANNUAL]: "Odhadované roční provozní náklady",
    [k.ENERGYCLASSSOLO]: "Energetická třída",
    [k.CHANGETHEPOWER]: "Změnit výkon fólie",
    [k.GERMAN]: "Německý",
    [k.ROMANIAN]: "Rumunský",
    [k.HUNGARIAN]: "Maďarský",
    [k.RODO]: "Rodo distributora",
    [k.RODOTEXT]:
        "Heat Decor sp. z o.o. nenese odpovědnost za projekt/souhrn vytvořený Uživatelem, včetně volby typu topného systému pro místnost, volby jeho výkonu a materiálů. Uživatel nese odpovědnost a riziko za volbu, případnou nesprávnou funkčnost a účinnost jím nakonfigurovaného topného systému.",
    [k.RODOHD]: "Rodo Heat Decor",
    [k.SCOPEOFRESPONSIBILITY]: "Rozsah odpovědnosti",
    [k.ATTACHMENTS]: "Přílohy",
    [k.ATTACHMENTSTEXT]: "Nabídka je kompletní spolu s přílohou obsahující vizualizaci topného systému v jednotlivých místnostech.",
    [k.ANNOTATIONS]: "Poznámky k nabídce",
    [k.THANKYOU]: "Děkujeme",
    [k.THANKYOUTEXT]: "za váš zájem o moderní topný systém.",
    [k.THANKYOUQUESTION]: "V případě dotazů kontaktujte osobu připravující nabídku.",
    [k.OFFERINGPERSON]: "Nabízející osoba",
    [k.OFFERINGCOMPANY]: "Nabízející společnost",
    [k.RECOMMENDMODERN]: "Doporučujeme moderní topné systémy Heat Decor",
    [k.MAINTENANCE]: "bezúdržbové",
    [k.COMPLETLY]: "zcela",
    [k.SILENT]: "tiché",
    [k.FRIENDLY]: "přátelské",
    [k.ENVIRONMENT]: "k životnímu prostředí",
    [k.WITHINTELLIGENT]: "s inteligentním",
    [k.CONTROL]: "ovládáním",
    [k.VISUALIZATIONOFTHEDISTRIBUTION]: "Vizualizace rozložení",
    [k.HEATINGSYSTEM]: "topného systému",
    [k.TOTALPOWER]: "Celkový výkon systémů",
    [k.HEATINGFLOORS]: "topených podlaží",
    [k.LEGEND]: "Legenda",
    [k.PERMANENT_BUILDINGS2]: "pevná zástavba",
    [k.CAPACITYOFHEATING]: "VÝKONY TOPNÝCH SYSTÉMŮ V JEDNOTLIVÝCH MÍSTNOSTECH",
    [k.NAMEOFROOM]: "Název místnosti",
    [k.SYSTEMTYPE]: "Typ systému",
    [k.AREACOVERAGE]: "Pokrytí plochy [ % ]",
    [k.POWERPER]: "Výkon na (m2) [ W/(m2) ]",
    [k.SYSTEMPOWER]: "Výkon systému v místnosti [ W ]",
    [k.HEATINGCIRCUIT]: "Odpor topného okruhu [ \u03A9 ]",
    [k.CONTACTOR]: "* - pro místnost je vyžadován stykač",
    [k.OFFERNO]: "NABÍDKA Č.",
    [k.DEVELOPMENT]: "Zpracování",
    [k.BASEMENTPLAN]: "Půdorys sklepa",
    [k.FLOORPLAN]: "Půdorys patra",
    [k.ATTICPLAN]: "Půdorys podkroví",
    [k.STOREYPLAN]: "Půdorys podlaží",
    [k.LEVELPLAN]: "Půdorys úrovně",
    [k.ROOMPLAN]: "Půdorys místnosti",
    [k.GROUNDFLOORPLAN]: "Půdorys přízemí",
    [k.NO_THERMOSTAT]: "Žádný termostat",
    [k.EXTERNAL_SENSOR]: "Externí senzor",
    [k.APPLY_DISCOUNT_TO_ALL_PRODUCTS]: "Použít slevu na všechny produkty",
    [k.INVESTOR_PRICE_BELOW_COST]: "Upozornění! Cena pro investora u jednoho z produktů je pod vaší nákupní cenou!",
    [k.CURRENCY]: "Měna",
    [k.POLISH_ZLOTY]: "Polský zlotý (PLN)",
    [k.EURO]: "Euro (EUR)",
    [k.CZECH_KORUNA]: "Česká koruna (CZK)",
    [k.BRITISH_POUND]: "Britská libra (GBP)",
    [k.CURRENCY_IS_REQUIRED]: "Měna je povinná",
    [k.SELECT_DISTRIBUTOR]: "Vyberte distributora",
    [k.SELECT_ACTIVE_PRODUCTS]: "Vyberte aktivní produkty",
    [k.MANAGE_PRODUCTS]: "Správa produktů",
    [k.ENTER_MESSAGE]: "Zadejte zprávu",
    [k.LOGFF]: "Odhlásit uživatele",
    [k.SELECTUSER]: "Vyberte uživatele",
    [k.USERACCOUNT]: "Uživatelský účet",
    [k.US_DOLLAR]: "Americký dolar (USD)",
    [k.PRODUCT_SUMMARY]: "Souhrn produktů",
    [k.ERROR_OCCURRED]: "Došlo k chybě",
    [k.PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_SMS_CODE]: "Telefonní číslo k ověření účtu pomocí SMS kódu",
    [k.DOWNLOAD_EXCEL]: "Stáhnout Excel",
    [k.RESET_TO_DEFAULTS]: "Přejít na výchozí hodnoty",
    [k.ORDER_VALUE]: "Hodnota objednávky",
    [k.SHOW_HINTS]: "Zobrazit nápovědy?",
};
