module.exports = {
    _: "_",
    _1: "_1",
    _10: "_10",
    _11: "_11",
    _12: "_12",
    _13: "_13",
    _14: "_14",
    _15: "_15",
    _16: "_16",
    _17: "_17",
    _2: "_2",
    _3: "_3",
    _4: "_4",
    _5: "_5",
    _6: "_6",
    _7: "_7",
    _8: "_8",
    _9: "_9",
    ADD: "ADD",
    ADD_ADDRESS: "ADD_ADDRESS",
    ADD_ANOTHER_ROOM: "ADD_ANOTHER_ROOM",
    ADD_NEW_ADDRESS: "ADD_NEW_ADDRESS",
    ADDTOCART: "ADDTOCART",
    ANNUAL_ELECTRICITY_CONSUMPTION: "ANNUAL_ELECTRICITY_CONSUMPTION",
    ANNUAL_OPERATING_COSTS: "ANNUAL_OPERATING_COSTS",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ADDRESS: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ADDRESS",
    ARCHITECT: "ARCHITECT",
    APARTMENT: "APARTMENT",
    BACK: "BACK",
    TRANSFER: `TRANSFER`,
    BANK_TRANSFER: "BANK_TRANSFER",
    BLOCKOFFLATS: "BLOCKOFFLATS",
    BUILDINGENERGYCLASS: "BUILDINGENERGYCLASS",
    BUYBYMETER: "BUYBYMETER",
    BUYONROLLS: "BUYONROLLS",
    BYMETERS: "BYMETERS",
    CONFIRM: "CONFIRM",
    CZECH: "CZECH",
    CALCULATECOSTS: "CALCULATECOSTS",
    CALCULATE_HEATING_COSTS: "CALCULATE_HEATING_COSTS",
    CANCEL: "CANCEL",
    CHOOSELANG: "CHOOSELANG",
    CHOOSE_NEXT_STEP: "CHOOSE_NEXT_STEP",
    COSTCALCULATION: "COSTCALCULATION",
    CONSTRUCTION: "CONSTRUCTION",
    CREATE_ACCOUNT: "CREATE_ACCOUNT",
    DELETE: "DELETE",
    DEVELOPER: "DEVELOPER",
    DISCOUNTGRANTED: "DISCOUNTGRANTED",
    DISCOUNTLEVEL: "DISCOUNTLEVEL",
    DISTRIBUTORS: "DISTRIBUTORS",
    ELECTRICHEATERS: "ELECTRICHEATERS",
    EMPLOYEE: "EMPLOYEE",
    ENGLISH: "ENGLISH",
    ENTER_ROOMS_DIMENSIONS: "ENTER_ROOMS_DIMENSIONS",
    ENTER_ROOMS_DIMENSIONS_BASED_ON_FIGURE: "ENTER_ROOMS_DIMENSIONS_BASED_ON_FIGURE",
    ENTER_A_NEW_PASSWORD: "ENTER_A_NEW_PASSWORD",
    FINISHED: "FINISHED",
    FOILPOWER: "FOILPOWER",
    FOILWIDTH: "FOILWIDTH",
    FRENCH: "FRENCH",
    GERMAN: "GERMAN",
    GROSS: "GROSS",
    ELECRICITYPRICE: "ELECRICITYPRICE",
    ENERGYCLASS: "ENERGYCLASS",
    ENTER_INSTALLATION_PARAMS: "ENTER_INSTALLATION_PARAMS",
    FOR_CLIENT: "FOR_CLIENT",
    GENERAL_HEATING_COST_CALCULATOR: "GENERAL_HEATING_COST_CALCULATOR",
    LOWENERGY: "LOWENERGY",
    GOTOCASHREGISTER: "GOTOCASHREGISTER",
    HEATINGCOSTCALCULATOR: "HEATINGCOSTCALCULATOR",
    HEATINGFOIL: "HEATINGFOIL",
    HEATINGMAT: "HEATINGMAT",
    HOUSE: "HOUSE",
    HOUSINGESTATE: "HOUSINGESTATE",
    INSTALLER: "INSTALLER",
    I_ORDER_WITH_SHIPPING_TO_CUSTOMERS: "I_ORDER_WITH_SHIPPING_TO_CUSTOMERS",
    I_AM_ORDERING_FOR_MY_OWN_WAREHOUSE: "I_AM_ORDERING_FOR_MY_OWN_WAREHOUSE",
    ITALIAN: "ITALIAN",
    HEATING_SYSTEM_POWER: "HEATING_SYSTEM_POWER",
    INSTALLATIONDESIGN: "INSTALLATIONDESIGN",
    INVESTMENT_MATERIALS_COSTS: "INVESTMENT_MATERIALS_COSTS",
    INVESTORS: "INVESTORS",
    INVESTMENTS: "INVESTMENTS",
    KNOWLEDGEBASE: "KNOWLEDGEBASE",
    LARGEQUANTITYINSTOCK: "LARGEQUANTITYINSTOCK",
    LEAVEPROJECT: "LEAVEPROJECT",
    LENGTH_IN_CM: "LENGTH_IN_CM",
    LOGIN: "LOGIN",
    LOG_IN: "LOG_IN",
    LOGOUT: "LOGOUT",
    MANAGER: "MANAGER",
    MARKETING: "MARKETING",
    MEDIUMENERGYSAVING: "MEDIUMENERGYSAVING",
    ENERGYSAVING: "ENERGYSAVING",
    MIN_PV_INSTALATION_REQUIRED: "MIN_PV_INSTALATION_REQUIRED",
    MORE_PRODUCTS_AND_GO_TO_HIGHER_DISCOUNT_LEVEL: "MORE_PRODUCTS_AND_GO_TO_HIGHER_DISCOUNT_LEVEL",
    NET: "NET",
    NEWROOM: "NEWROOM",
    NO: "NO",
    NODATA: "NODATA",
    NORWEGIAN: "NORWEGIAN",
    ONROLLS: "ONROLLS",
    POLISH: "POLISH",
    MONTHLY_OPERATING_COSTS: "MONTHLY_OPERATING_COSTS",
    OFFERGENERATING: "OFFERGENERATING",
    OFFERS: "OFFERS",
    ORDERS: "ORDERS",
    ONLINE_PAYMENT: "ONLINE_PAYMENT",
    PAYMENT_METHOD: `PAYMENT_METHOD`,
    OWN: "OWN",
    OWNER: "OWNER",
    PASSIVE_A_PLUS: "PASSIVE_A_PLUS",
    PHOTOVOLTAIC: "PHOTOVOLTAIC",
    PRESENTED_CALC_ARE_INFORMATIVE: "PRESENTED_CALC_ARE_INFORMATIVE",
    PRICELIST: "PRICELIST",
    PRODUCT: "PRODUCT",
    PRODUCTSINCART: "PRODUCTSINCART",
    PRODUCTAVAILABLE: "PRODUCTAVAILABLE",
    PROFIT: "PROFIT",
    PROJECTS: "PROJECTS",
    PUBLICFACILITY: "PUBLICFACILITY",
    RATED_ENERGY_CONSUMPTION: "RATED_ENERGY_CONSUMPTION",
    REJECT_CHANGES: "REJECT_CHANGES",
    REMIND_PASSWORD: "REMIND_PASSWORD",
    REMEMBER_ME: "REMEMBER_ME",
    RETURN_TO_THE_LOGIN_PANEL: "RETURN TO THE LOGIN PANEL",
    QUANTITY: "QUANTITY",
    ROMANIAN: "ROMANIAN",
    SALE: "SALE",
    SAVEANDCLOSE: "SAVEANDCLOSE",
    SAVECHANGES: "SAVECHANGES",
    SEARCHFORPRODUCTS: "SEARCHFORPRODUCTS",
    SEETHEPRODUCT: "SEETHEPRODUCT",
    SETTINGS: "SETTINGS",
    SHOWCROSS_SECTION: "SHOWCROSS_SECTION",
    SHOWLAYERS: "SHOWLAYERS",
    SLOVAK: "SLOVAK",
    SMALLQUANTITYINSTOCK: "SMALLQUANTITYINSTOCK",
    SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
    SPAIN: "SPAIN",
    RELOADAPPLICATION: "RELOADAPPLICATION",
    SWEDISH: "SWEDISH",
    THERMOSTAT: "THERMOSTAT",
    THERMOSTATFUNCTIONS: "THERMOSTATFUNCTIONS",
    THERMOSTATS: "THERMOSTATS",
    TOGETHER: "TOGETHER",
    TOGETHERTOPAY: "TOGETHERTOPAY",
    USABLEAREA: "USABLEAREA",
    ZEROENERGY: "ZEROENERGY",
    FLOOR: "FLOOR",
    CEILING: "CEILING",
    WALL: "WALL",
    WAREHOUSE: "WAREHOUSE",
    WHOLESALER: "WHOLESALER",
    WT2021: "WT2021",
    ADDROOM: "ADDROOM",
    CHANGE: "CHANGE",
    YES: "YES",
    GOBACKTOCREATOR: "GOBACKTOCREATOR",
    GOBACKTOCART: "GOBACKTOCART",
    SQUAREM: "SQUAREM",
    PIECE: "PIECE",
    RUNNINGM: "RUNNINGM",
    COLOR: "COLOR",
    UNDO: "UNDO",
    REDO: "REDO",
    ZOOMIN: "ZOOMIN",
    ZOOMOUT: "ZOOMOUT",
    MEASURE: "MEASURE",
    CLOSEPOLYGON: "CLOSEPOLYGON",
    CONTACTORREQUIRED: "CONTACTORREQUIRED",
    WRONGLOGINORPASSWORD: "WRONGLOGINORPASSWORD",
    WIDTH_IN_CM: "WIDTH_IN_CM",
    WIDTH: "WIDTH",
    MISSINGUSER: "MISSINGUSER",
    PASSWORD: "PASSWORD",
    PASSWORDREQUIRED: "PASSWORDREQUIRED",
    CONFIRMPASSWORDMUSTBESAME: "CONFIRMPASSWORDMUSTBESAME",
    PASSWORDSHOULDBEMIN8CHARLONG: "PASSWORDSHOULDBEMIN8CHARLONG",
    PASSWORDPATTERNQUOTE: "PASSWORDPATTERNQUOTE",
    PASSWORD_HAS_BEEN_RESET: "PASSWORD_HAS_BEEN_RESET",
    CALIBRATION: "CALIBRATION",
    READY: "READY",
    EDIT: "EDIT",
    START: "START",
    UNFOLDAUTOMATICALLY: "UNFOLDAUTOMATICALLY",
    CUTTINGPOINT: "CUTTINGPOINT",
    TYPE: "TYPE",
    FURNITURE: "FURNITURE",
    BATH_SHOWERTRAY: "BATH_SHOWERTRAY",
    SINK: "SINK",
    KITCHEN_FURNITURE: "KITCHEN_FURNITURE",
    FIREPLACE: "FIREPLACE",
    HOUSEHOLD_APPLIANCES: "HOUSEHOLD_APPLIANCES",
    YOUR_ADDRESSES: "YOUR_ADDRESSES",
    YOUR_GROSS_PRICE: "YOUR_GROSS_PRICE",
    OPTIMAL: "OPTIMAL",
    VERTICALY: "VERTICALY",
    HORIZONTALY: "HORIZONTALY",
    ALLWIDTHS: "ALLWIDTHS",
    DRAWOFFAREA: "DRAWOFFAREA",
    INNER: "INNER",
    OUTER: "OUTER",
    OTHER: "OTHER",
    BATHROOM: "BATHROOM",
    BASEMENT: "BASEMENT",
    GROUND_FLOOR: "GROUND_FLOOR",
    FIRST_FLOOR: "FIRST_FLOOR",
    ATTIC: "ATTIC",
    STOREY: "STOREY",
    LEVEL: "LEVEL",
    ROOM: "ROOM",
    ADDFILE: "ADDFILE",
    DRAWPLAN: "DRAWPLAN",
    LIVINGROOM: "LIVINGROOM",
    KITCHEN: "KITCHEN",
    VESTIBULE: "VESTIBULE",
    BEDROOM: "BEDROOM",
    DININGROOM: "DININGROOM",
    WARDROBE: "WARDROBE",
    TOILET: "TOILET",
    LAUNDRY: "LAUNDRY",
    HALL: "HALL",
    CABINET: "CABINET",
    ANTEROOM: "ANTEROOM",
    CORRIDOR: "CORRIDOR",
    PANTRY: "PANTRY",
    LIVING_DINING_ROOM: "LIVING_DINING_ROOM",
    LIVINGROOM_WITH_KITCHENETTE: "LIVINGROOM_WITH_KITCHENETTE",
    GARAGE: "GARAGE",
    TECHNICALROOM: "TECHNICALROOM",
    UTILITYROOM: "UTILITYROOM",
    RECREATIONROOM: "RECREATIONROOM",
    ENTRESOL: "ENTRESOL",
    SAUNA: "SAUNA",
    BOILERROOM: "BOILERROOM",
    CUBBY: "CUBBY",
    STAIRS: "STAIRS",
    FIREPLACE_ROOM: "FIREPLACE_ROOM",
    GO_BACK_EDIT: "GO_BACK_EDIT",
    GO_FURTHER: "GO_FURTHER",
    DRAW_NEXT: "DRAW_NEXT",
    ENTER_THE_EMAIL_PROVIDED_WHEN_CREATING_THE_ACCOUNT: "ENTER_THE_EMAIL_PROVIDED_WHEN_CREATING_THE_ACCOUNT",
    START_DESIGNING: "START_DESIGNING",
    IN_THE_WIZARD_HEAT_DECOR: "IN_THE_WIZARD_HEAT_DECOR",
    YOU_CAN_CREATE_AN_INFRARED_HEATING_PROJECT_IN_JUST_FEW_STEPS: "YOU_CAN_CREATE_AN_INFRARED_HEATING_PROJECT_IN_JUST_FEW_STEPS",
    WHAT_WILL_YOU_GAIN_BY_WORKING: "WHAT_WILL_YOU_GAIN_BY_WORKING",
    WITH_THE_HEAT_DECOR_CREATOR: "WITH_THE_HEAT_DECOR_CREATOR",
    PRECISE_DESIGNS_OF_THE_HEATING_SYSTEM_IN_THE: "PRECISE_DESIGNS_OF_THE_HEATING_SYSTEM_IN_THE",
    HEAT_DECOR_STANDARD_READY_FOR_USE_BY: "HEAT_DECOR_STANDARD_READY_FOR_USE_BY",
    THE_INSTALLATION_TEAM: "THE_INSTALLATION_TEAM",
    EASIER_AND_MORE_COMPLETE_PREPARATION_OF_AN_OFFER_FOR: "EASIER_AND_MORE_COMPLETE_PREPARATION_OF_AN_OFFER_FOR",
    YOUR_CLIENT: "YOUR_CLIENT",
    FACILITATING_THE_PROCESS_OF_ORDERING_PRODUCTS: "FACILITATING_THE_PROCESS_OF_ORDERING_PRODUCTS",
    HEAT_DECOR: "HEAT_DECOR",
    WE_ARE_CHANGING_THE_FUTURE_FOR: "WE_ARE_CHANGING_THE_FUTURE_FOR",
    GREEN: "GREEN",
    WATCH_HEAT_DECOR: "WATCH_HEAT_DECOR",
    READ_AND_SUBSCRIBE: "READ_AND_SUBSCRIBE",
    BLOG: "BLOG",
    PRIMARY_USER: "PRIMARY_USER",
    HAS_ACCESS_TO_ALL_WIZARDS_FUNCTIONALITIES: "HAS_ACCESS_TO_ALL_WIZARDS_FUNCTIONALITIES",
    DEFINING_THEROLE_IN_THE_COMPANYS_STRUCTURE_WILL_FACILITATE_FURTHER_COOPERATION_WITH_HEAT_DECOR:
        "DEFINING_THEROLE_IN_THE_COMPANYS_STRUCTURE_WILL_FACILITATE_FURTHER_COOPERATION_WITH_HEAT_DECOR",
    SPECIFYING_THE_TYPE_OF_COMPANY_WILL_ALLOW_YOU_TO_ADJUST_THE_WIZARD_TO_YOUR_NEEDS: "SPECIFYING_THE_TYPE_OF_COMPANY_WILL_ALLOW_YOU_TO_ADJUST_THE_WIZARD_TO_YOUR_NEEDS",
    AFTER_REGISTRATION_HEAT_DECOR_WILL_CONTACT_THE_INDICATED_MAIN_USER: "AFTER_REGISTRATION_HEAT_DECOR_WILL_CONTACT_THE_INDICATED_MAIN_USER",
    ADDITIONAL_USERS: "ADDITIONAL_USERS",
    THEY_CAN_USE_THE_WIZARD_TO_DEVELOP_DESIGNS_AND_QUOTES_FOR_THEIR_CLIENTS: "THEY_CAN_USE_THE_WIZARD_TO_DEVELOP_DESIGNS_AND_QUOTES_FOR_THEIR_CLIENTS",
    THEY_HAVE_LIMITED_ACCOUNT_EDITING_CAPABILITIES: "THEY_HAVE_LIMITED_ACCOUNT_EDITING_CAPABILITIES",
    IN_CASE_OF_REPEATED_DIFFICULTIES_WITH_USING_THE_WIZARD_CONTACT_THE_ADMINISTRATOR_OF_THE_HEAT_DECOR_WIZARD:
        "IN_CASE_OF_REPEATED_DIFFICULTIES_WITH_USING_THE_WIZARD_CONTACT_THE_ADMINISTRATOR_OF_THE_HEAT_DECOR_WIZARD",
    EMAIL: "EMAIL",
    TELEPHONE: "TELEPHONE",
    PRIVACY_POLICY: "PRIVACY_POLICY",
    WEBSITE_REGULATIONS: "WEBSITE_REGULATIONS",
    THANK_YOU_FOR_YOUR_REGISTRATION: "THANK_YOU_FOR_YOUR_REGISTRATION",
    LOGGING_IN_WILL_BE_POSSIBLE_AFTER_SUCCESSFULLY: "LOGGING_IN_WILL_BE_POSSIBLE_AFTER_SUCCESSFULLY",
    SETTING_THE_PASSWORD_AND_VERIFYING_THE_ACCOUNT_BY_THE: "SETTING_THE_PASSWORD_AND_VERIFYING_THE_ACCOUNT_BY_THE",
    ADMINISTRATOR: "ADMINISTRATOR",
    YOU_WILL_RECEIVE_A_LINK_TO_SET_A_NEW_PASSWORD: "YOU_WILL_RECEIVE_A_LINK_TO_SET_A_NEW_PASSWORD",
    EMAIL_WAS_SENT_FOLLOW_THE_INSTRUCTIONS: "EMAIL_WAS_SENT_FOLLOW_THE_INSTRUCTIONS",
    IN_THE_EMAIL: "IN_THE_EMAIL",
    CHECK_EMAIL: "CHECK_EMAIL",
    TO_COMPLETE_THE_REGISTRATION_PROCESS_CHECK_THE_MESSAGE_SENT_AUTOMATICALLY_TO_THE_EMAIL_ADDRESS_PROVIDED_AND_CLICK_THE_LINK_TO_CONFIRM_THE_REGISTRATION:
        "TO_COMPLETE_THE_REGISTRATION_PROCESS_CHECK_THE_MESSAGE_SENT_AUTOMATICALLY_TO_THE_EMAIL_ADDRESS_PROVIDED_AND_CLICK_THE_LINK_TO_CONFIRM_THE_REGISTRATION",
    REGISTRATION_ERROR: "REGISTRATION_ERROR",
    AN_ERROR_OCCURRED_WHILE_CREATING_A_NEW_ACCOUNT: "AN_ERROR_OCCURRED_WHILE_CREATING_A_NEW_ACCOUNT",
    TRY_AGAIN_IF_THE_ERROR_REPEATS_TRY_TO_REGISTER_TO_A_DIFFERENT_EMAIL_ADDRESS_OR_CONTACT_US:
        "TRY_AGAIN_IF_THE_ERROR_REPEATS_TRY_TO_REGISTER_TO_A_DIFFERENT_EMAIL_ADDRESS_OR_CONTACT_US",
    ENTER_THE_ACCOUNT_PRIMARY_USER_INFORMATION: "ENTER_THE_ACCOUNT_PRIMARY_USER_INFORMATION",
    REGISTRATION: "REGISTRATION",
    ADDITIONAL_USERS_OPTIONAL: "ADDITIONAL_USERS_OPTIONAL",
    INVESTMENT: "INVESTMENT",
    INVESTORS_NAME: "INVESTORS_NAME",
    ADDED_BY: "ADDED_BY",
    NOTES: "NOTES",
    STREET: "STREET",
    CITY: "CITY",
    ZIP_CODE: "ZIP_CODE",
    YOUR_NET_PRICE: "YOUR_NET_PRICE",
    NET_PRICE_FOR_THE_CLIENT: "NET_PRICE_FOR_THE_CLIENT",
    NET_PROFIT: "NET_PROFIT",
    DOWNLOAD_MULTIPLE_PDF_FILES: "DOWNLOAD_MULTIPLE_PDF_FILES",
    DELETE_PROJECTS: "DELETE_PROJECTS",
    ADD_TO_CART: "ADD_TO_CART",
    REMOVE_OFFERS: "REMOVE_OFFERS",
    GENEROWANIE_OFERTY: "GENEROWANIE_OFERTY",
    RESET_PASS: "RESET_PASS",
    VALUE_OF_THE_OFFER: "VALUE_OF_THE_OFFER",
    YOUR_COST: "YOUR_COST",
    YOUR_PRICE: "YOUR_PRICE",
    THE_INVESTOR_PAYS: "THE_INVESTOR_PAYS",
    YOU_EARN: "YOU_EARN",
    GO_ON: "GO_ON",
    ENTER_NEW_PASSWORD: "ENTER_NEW_PASSWORD",
    NEW_PASSWORD: "NEW_PASSWORD",
    PRICE: "PRICE",
    PRICE_FOR_THE_CLIENT: "PRICE_FOR_THE_CLIENT",
    A_PRICE_FOR_YOU: "A_PRICE_FOR_YOU",
    INCORRECT_LOGIN_OR_PASSWORD: "INCORRECT_LOGIN_OR_PASSWORD",
    OFFER: "OFFER",
    BY: "BY",
    LIST_OF_ORDERS: "LIST_OF_ORDERS",
    THANK_YOU_FOR_YOUR_ORDER: "THANK_YOU_FOR_YOUR_ORDER",
    GO_TO_ORDERS: "GO_TO_ORDERS",
    SMS_CODE: "SMS_CODE",
    INCORRECT_AUTHORIZATION_CODE: "INCORRECT_AUTHORIZATION_CODE",
    ENTER_AUTHORIZATION_CODE: "ENTER_AUTHORIZATION_COD",
    CONFIRMED: "CONFIRMED",
    RESEND_CODE: "RESEND_CODE",
    REENTER_THE_6_DIGIT_CODE: "REENTER_THE_6_DIGIT_CODE",
    TO_VERIFY_YOUR_ACCOUNT: "TO_VERIFY_YOUR_ACCOUNT",
    ENTER_6_DIGIT_SMS_CODE_TO_VERIFY_YOU_ACCOUNT: "ENTER_6_DIGIT_SMS_CODE_TO_VERIFY_YOU_ACCOUNT",
    NR: "NR",
    GO_TO_PAYMENT: "GO_TO_PAYMENT",
    ORDERED_PRODUCTS: "ORDERED_PRODUCTS",
    NOTES_TO_THE_ORDER: "NOTES_TO_THE_ORDER",
    NO_PRODUCTS: "NO_PRODUCTS",
    THERE_ARE_NO_PRODUCTS_IN_YOUR_CART: "THERE_ARE_NO_PRODUCTS_IN_YOUR_CART",
    NO_OFFERS: "NO_OFFERS",
    YOUR_CART: "YOUR_CART",
    YOUR_ORDER_WITH_DELIVERY_TO_THE_CUSTOMER: "YOUR_ORDER_WITH_DELIVERY_TO_THE_CUSTOMER",
    YOUR_ORDER_WITH_DELIVERY_TO_YOUR_WEREHOUSE: "YOUR_ORDER_WITH_DELIVERY_TO_YOUR_WEREHOUSE",
    PAY_FOR_YOUR_ORDER: "PAY_FOR_YOUR_ORDER",
    PHONE: "PHONE",
    SHIPPING_TO: "SHIPPING_TO",
    SAVE: "SAVE",
    EMAIL_IS_REQUIRED: "EMAIL_IS_REQUIRED",
    CODE_AND_CITY_ARE_REQUIRED: "CODE_AND_CITY_ARE_REQUIRED",
    POSTAL_CODE_AND_CITY: "POSTAL_CODE_AND_CITY",
    STREET_AND_BUILDING_NUMBER_ARE_REQUIRED: "STREET_AND_BUILDING_NUMBER_ARE_REQUIRED",
    STREET_AND_NUMBER: "STREET_AND_NUMBER",
    NAME_AND_SURNAME: "NAME_AND_SURNAME",
    DISCOUNT: "DISCOUNT",
    SUM: "SUM",
    YOU_WANT_TO_GIVE_A_DISCOUT_ON_ALL_MATERIAL: "YOU_WANT_TO_GIVE_A_DISCOUT_ON_ALL_MATERIAL",
    ALL_MATERIAL_DISCOUNT: "ALL_MATERIAL_DISCOUNT",
    SORRY_NOTHING_HERE: "SORRY_NOTHING_HERE",
    GO_HOME: "GO_HOME",
    I_UNDERSTAND_DO_NOT_SHOW_ON_THE_NEXT_LOGIN: "I_UNDERSTAND_DO_NOT_SHOW_ON_THE_NEXT_LOGIN",
    DISTRIBUTOR: "DISTRIBUTOR",
    GENERATE_REPORT: "GENERATE_REPORT",
    SKETCH: "SKETCH",
    GENERATE_AN_OFFER: "GENERATE_AN_OFFER",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECT: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECT",
    FIND_A_PROJECT: "FIND_A_PROJECT",
    THERE_IS_NO_SUCH_PROJECT: "THERE_IS_NO_SUCH_PROJECT",
    YOU_DONT_HAVE_ANY_PROJECT_YET: "YOU_DONT_HAVE_ANY_PROJECT_YET",
    YOU_MUST_HAVE_AN_INVESTOR_AND_AN_INVESTMENT_TO_CREATE_A_PROJECT_FOR_IT: "YOU_MUST_HAVE_AN_INVESTOR_AND_AN_INVESTMENT_TO_CREATE_A_PROJECT_FOR_IT",
    DO_YOU_WANT_TO_CREATE_AN_INVESTOR: "DO_YOU_WANT_TO_CREATE_AN_INVESTOR",
    GETTING_STARTED_WITH_ADDING_AN_INVESTOR: "GETTING_STARTED_WITH_ADDING_AN_INVESTOR",
    ADD_AN_INVESTOR: "ADD_AN_INVESTOR",
    SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN: "SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN",
    SELECT_THE_TYPE_OF_ROOM: "SELECT_THE_TYPE_OF_ROOM",
    GO_BACK_TO_EDITING: "GO_BACK_TO_EDITING",
    TITLE: "TITLE",
    GROUP: "GROUP",
    GENERAL: "GENERAL",
    HEATING_FOIL: "HEATING_FOIL",
    ELECTRIC_HEATERS: "ELECTRIC_HEATERS",
    HEATING_MAT: "HEATING_MAT",
    IMAGE: "IMAGE",
    DRAG_AND_DROP_THE_IMAGE_OR_CLICK_AND_SELECT: "DRAG_AND_DROP_THE_IMAGE_OR_CLICK_AND_SELECT",
    PREVIEW: "PREVIEW",
    TEXT_EDITOR: "TEXT_EDITOR",
    SAVE_CHANGES: "SAVE_CHANGES",
    EXIT: "EXIT",
    ADD_A_NEW_COMPANY: "ADD_A_NEW_COMPANY",
    GO_BACK_TO_THE_LIST: "GO_BACK_TO_THE_LIST",
    FIRST_NAME: "FIRST_NAME",
    LAST_NAME: " LAST_NAME",
    USER: "USER",
    IS_REQUIRED: "IS_REQUIRED",
    FIRST_NAME_IS_REQUIRED: "FIRST_NAME_IS_REQUIRED",
    LAST_NAME_IS_REQUIRED: "LAST_NAME_IS_REQUIRED",
    PHONE_IS_REQUIRED: "PHONE_IS_REQUIRED",
    EMAIL_IS_INVALID: "EMAIL_IS_INVALID",
    ADD_USER: "ADD_USER",
    REGISTER: "REGISTER",
    COUNTRY: "COUNTRY",
    PHONE_NUMBER: "PHONE_NUMBER",
    PREFIX_IS_REQUIRED: "PREFIX_IS_REQUIRED",
    NEXT_STEP: "NEXT_STEP",
    TAX_ID_IS_REQUIRED: "TAX_ID_IS_REQUIRED",
    COUNTRY_IS_REQUIRED: "COUNTRY_IS_REQUIRED",
    TAX_ID: "TAX_ID",
    TAX_ID_IS_INCORRECT: "TAX_ID_IS_INCORRECT",
    ZIP_CODE_IS_REQUIRED: "ZIP_CODE_IS_REQUIRED",
    CITY_IS_REQUIRED: "CITY_IS_REQUIRED",
    HOUSE_NUMBER_IS_REQUIRED: "HOUSE_NUMBER_IS_REQUIRED",
    NUMBER: "NUMBER",
    DOCUMENT_NUMBER: "DOCUMENT_NUMBER",
    DOWNLOAD: "DOWNLOAD",
    WAS_THIS_ARTICLE_HELPFUL: "WAS_THIS_ARTICLE_HELPFUL",
    MIN_READING: "MIN_READING",
    SEE_MORE: "SEE_MORE",
    ADDRESS: "ADDRESS",
    DEFAULT_ADDRESS: "DEFAULT_ADDRESS",
    NAME: "NAME",
    NAME_OF_THE_INVESTMENT: "NAME_OF_THE_INVESTMENT",
    SEARCH_CRITERIA: "SEARCH_CRITERIA",
    WHO_ADDED: "WHO_ADDED",
    IS_IT_A_COMPANY: "IS_IT_A_COMPANY",
    BUSINESS: "BUSINESS",
    NATURAL_PERSON: " NATURAL_PERSON",
    YOU_DONT_HAVE_ANY_INVESTOR_YET: "YOU_DONT_HAVE_ANY_INVESTOR_YET",
    DO_YOU_WANT_TO_ADD_IT_NOW: "DO_YOU_WANT_TO_ADD_IT_NOW",
    THERE_IS_NO_SUCH_INVESTOR: "THERE_IS_NO_SUCH_INVESTOR",
    YOU_DONT_HAVE_ANY_INVESTMENTS_YET: "YOU_DONT_HAVE_ANY_INVESTMENTS_YET",
    ADD_YOUR_FIRST_INVESTMENT: "ADD_YOUR_FIRST_INVESTMENT",
    THERE_IS_NO_SUCH_INVESTMENT: "THERE_IS_NO_SUCH_INVESTMENT",
    ADD_AN_INVESTMENT: "ADD_AN_INVESTMENT",
    INVESTOR: "INVESTOR",
    CONTACT_PERSON: "CONTACT_PERSON",
    STREET_IS_REQUIRED: "STREET_IS_REQUIRED",
    NO_IS_REQUIRED: "NO_IS_REQUIRED",
    CODE_IS_REQUIRED: "CODE_IS_REQUIRED",
    THE_ENERGY_CLASS_IS_REQUIRED: "THE_ENERGY_CLASS_IS_REQUIRED",
    CUSTOM_ENERGY_CLASS_IS_REQUIRED: "CUSTOM_ENERGY_CLASS_IS_REQUIRED",
    GENERATE_OFFERS: "GENERATE_OFFERS",
    THE_OFFER_IS_ALREADY_IN_THE_CART_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_IT: "THE_OFFER_IS_ALREADY_IN_THE_CART_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_IT",
    COMPANY_NAME_IS_REQUIRED: "COMPANY_NAME_IS_REQUIRED",
    THE_ADDRESS_IS_REQUIRED: "THE_ADDRESS_IS_REQUIRED",
    THE_ZIP_CODE_IS_INCORRECT: "THE_ZIP_CODE_IS_INCORRECT",
    THE_CITY_IS_NOT_CORRECT: "THE_CITY_IS_NOT_CORRECT",
    THE_HOUSE_NUMBER_IS_INCORRECT: "THE_HOUSE_NUMBER_IS_INCORRECT",
    COMPANY_NAME: "COMPANY_NAME",
    PREFIX_AND_PHONE_NUMBER_ARE_REQUIRED: "PREFIX_AND_PHONE_NUMBER_ARE_REQUIRED",
    POSTAL_CODE_AND_CITY_ARE_REQUIRED: "POSTAL_CODE_AND_CITY_ARE_REQUIRED",
    STREET_AND_HOUSE_NUMBER_ARE_REQUIRED: "STREET_AND_HOUSE_NUMBER_ARE_REQUIRED",
    FULL_NAME_IS_REQUIRED: "FULL_NAME_IS_REQUIRED",
    REQUIRED_FIELD: "REQUIRED_FIELD",
    REQUIRED: " REQUIRED",
    ADD_A_PROJECT: "ADD_A_PROJECT",
    PROJECT_NAME_IS_REQUIRED: "PROJECT_NAME_IS_REQUIRED",
    THE_BUILDING_NUMBER_IS_REQUIRED: "THE_BUILDING_NUMBER_IS_REQUIRED",
    No: "No",
    ADDITIONAL_CONTACT_PERSON: "ADDITIONAL_CONTACT_PERSON",
    CLASS_ENERGY_IS_REQUIRED: "CLASS_ENERGY_IS_REQUIRED",
    ENERGY_CLASS: "ENERGY_CLASS",
    THE_STREET_IS_NOT_CORRECT: "THE_STREET_IS_NOT_CORRECT",
    HOUSE_NUMBER: "HOUSE_NUMBER",
    TYPE_OF_INVESTMENT: " TYPE_OF_INVESTMENT",
    CODE: "CODE",
    THE_INVESTMENT_HAS_NOT_BEEN_ADDED_TRY_AGAIN: "THE_INVESTMENT_HAS_NOT_BEEN_ADDED_TRY_AGAIN",
    COMPANY_TYPE: "COMPANY_TYPE",
    PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_AN_SMS_CODE: "PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_AN_SMS_CODE",
    GRAPHICS: "GRAPHICS",
    GO_BACK_TO_LOGIN: "GO_BACK_TO_LOGIN",
    ADD_A_NEW_INVESTMENT: "ADD_A_NEW_INVESTMENT",
    GO_BACK_TO_THE_LIST_OF_INVESTMENTS: "GO_BACK_TO_THE_LIST_OF_INVESTMENTS",
    ADD_A_NEW_INVESTOR: "ADD_A_NEW_INVESTOR",
    COMPLETE_THE_INVESTOR_PROFILE: "COMPLETE_THE_INVESTOR_PROFILE",
    GO_BACK_TO_INVESTORS: "GO_BACK_TO_INVESTORS",
    PROJECT_NAME: "PROJECT_NAME",
    WHAT_DO_YOU_WANT_TO_DESIGN: "WHAT_DO_YOU_WANT_TO_DESIGN",
    UPLOAD_THE_FILE_WITH_THE_VIEW: "UPLOAD_THE_FILE_WITH_THE_VIEW",
    ADD_FILE: "ADD_FILE",
    DISCARD_CHANGES: "DISCARD_CHANGES",
    DRAW_ANY_ROOM_PLAN: "DRAW_ANY_ROOM_PLAN",
    GENERATE_AS_IMAGE: "GENERATE_AS_IMAGE",
    DRAW_PLAN: "DRAW_PLAN",
    CREATE_A_PROJECT: "CREATE_A_PROJECT",
    CREATE_A_THROW_IN_THE_WIZARD: "CREATE_A_THROW_IN_THE_WIZARD",
    COME_BACK: "COME_BACK",
    HEATING_MAT_UNDER_THE_TILES: "HEATING_MAT_UNDER_THE_TILES",
    HEATING_FOIL_UNDER_THE_SPOUT: "HEATING_FOIL_UNDER_THE_SPOUT",
    HEATING_FOIL_UNDER_THE_SPC_VINYL_PANELS: "HEATING_FOIL_UNDER_THE_SPC_VINYL_PANELS",
    HEATING_FOIL_FOR_LAMINATED_PANELS: "HEATING_FOIL_FOR_LAMINATED_PANELS",
    SELECT_A_HEATING_SYSTEM: "SELECT_A_HEATING_SYSTEM",
    GIVE_THE_REAL_DIMENSION: "GIVE_THE_REAL_DIMENSION",
    ENTER_THE_REAL_LENGTH: "ENTER_THE_REAL_LENGTH",
    DESIGNATED_EPISODE: "DESIGNATED_EPISODE",
    SEE_PREVIEW: "SEE_PREVIEW",
    ADJUST_THE_VIEW_BY_TRIMMING_AND_ROTATING_IT: "ADJUST_THE_VIEW_BY_TRIMMING_AND_ROTATING_IT",
    THERE_ARE_NO_PRODUCTS_FOR_THE_GIVEN_CRITERIA: "THERE_ARE_NO_PRODUCTS_FOR_THE_GIVEN_CRITERIA",
    CHOOSE_A_MODEL: "CHOOSE_A_MODEL",
    PLN_GROSS: "PLN_GROSS",
    FOR_CALIBRATION_MARK_THE_LONGEST_SIDE_OF_THE_ROOM: "FOR_CALIBRATION_MARK_THE_LONGEST_SIDE_OF_THE_ROOM",
    STEP1_DRAW_THE_OUTLINE_OF_THE_ROOM: "STEP1_DRAW_THE_OUTLINE_OF_THE_ROOM",
    SELECT_THE_TYPE_OF_THE_SELECTED_FIXED_BUILDING_ELEMENT: "SELECT_THE_TYPE_OF_THE_SELECTED_FIXED_BUILDING_ELEMENT",
    STEP2_APPLY_THE_SURFACES_EXCLUDED_FOR_PERMANENT_CONSTRUCTION: "STEP2_APPLY_THE_SURFACES_EXCLUDED_FOR_PERMANENT_CONSTRUCTION",
    ADD_A_LAYER_OF_VAPOR_BARRIER_FOIL: "ADD_A_LAYER_OF_VAPOR_BARRIER_FOIL",
    PLACE_THE_THERMOREGULATOR: "PLACE_THE_THERMOREGULATOR",
    SELECT_THE_ENERGY_CLASS_OF_THE_BUILDING: "SELECT_THE_ENERGY_CLASS_OF_THE_BUILDING",
    THE_PROJECT_HAS_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_EXIT_THE_WIZARD: "THE_PROJECT_HAS_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_EXIT_THE_WIZARD",
    DO_YOU_WANT_TO_SAVE_THE_CHANGES: "DO_YOU_WANT_TO_SAVE_THE_CHANGES",
    CONFIRM_NEW_PASSWORD: "CONFIRM_NEW_PASSWORD",
    SET_A_PASSWORD: "SET_A_PASSWORD",
    THE_PHONE_IS_NOT_VALID: "THE_PHONE_IS_NOT_VALID",
    MANAGE_ADDRESSES: "MANAGE_ADDRESSES",
    PLEASE_SELECT_A_SHIPPING_ADDRESS: "PLEASE_SELECT_A_SHIPPING_ADDRESS",
    ADD_A_COMPANY: "ADD_A_COMPANY",
    SEARCH_FOR_A_COMPANY: "SEARCH_FOR_A_COMPANY",
    MESSAGE: "MESSAGE",
    NO_EMAIL_ADDRESS: "NO_EMAIL_ADDRESS",
    EDIT_CUSTOMER_DETAILS: "EDIT_CUSTOMER_DETAILS",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMPANY: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMPANY",
    RULER_CALIBRATION: "RULER_CALIBRATION",
    CALIBRATE_TOOL_TO_TEACH_A_DISTANCE_CALCULATION_PROGRAM_FOR_YOUR_THROW: "CALIBRATE_TOOL_TO_TEACH_A_DISTANCE_CALCULATION_PROGRAM_FOR_YOUR_THROW",
    SEE_AN_EXAMPLE: "SEE_AN_EXAMPLE",
    CALCULATE_YOUR_HEATING_COSTS: "CALCULATE_YOUR_HEATING_COSTS",
    THE_AREA_OF_THE_ROOM: "THE_AREA_OF_THE_ROOM",
    PERMANENT_BUILDINGS: "PERMANENT_BUILDINGS",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ROOM: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ROOM",
    THERMOREGULATORS: "THERMOREGULATORS",
    FLOOR_SENSOR: "FLOOR_SENSOR",
    INDOOR_AIR_SENSOR: "INDOOR_AIR_SENSOR",
    OUTSIDE_AIR_SENSOR: "OUTSIDE_AIR_SENSOR",
    OPTIONAL: "OPTIONAL",
    PROGRAMMING: "PROGRAMMING",
    LEVEL_OF_SECURITY: "LEVEL_OF_SECURITY",
    CONTROL_PANEL: "CONTROL_PANEL",
    TOUCH: "TOUCH",
    KEYBOARD: "KEYBOARD",
    KNOB: "KNOB",
    FIND_OUT_MORE: "FIND_OUT_MORE",
    CHOOSE: "CHOOSE",
    ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT_FROM_YOUR_CART: "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT_FROM_YOUR_CART",
    FILTER: "FILTER",
    CUTTING: "CUTTING",
    POWER: "POWER",
    MAX_TEMP: "MAX_TEMP",
    AREA: "AREA",
    HEATING_MAT_POWER: "HEATING_MAT_POWER",
    HEIGHT: "HEIGHT",
    THICKNESS: "THICKNESS",
    PRODUCT_PARAMETERS: "PRODUCT_PARAMETERS",
    LENGTH: "LENGTH",
    HEATING_POWER: "HEATING_POWER",
    FOIL_SURFACE: "FOIL_SURFACE",
    MAX_HEATING_TEMPERATURE: "MAX_HEATING_TEMPERATURE",
    THE_WIDTH_OF_THE_FILM: "THE_WIDTH_OF_THE_FILM",
    POSSIBILITY_TO_CUT_THE_FILM_CO: "POSSIBILITY_TO_CUT_THE_FILM_CO",
    MAT_AREA: "MAT_AREA",
    THERMOSTAT_MODEL: "THERMOSTAT_MODEL",
    RESISTANCE: "RESISTANCE",
    FOIL_MODEL: "FOIL_MODEL",
    BRAND: "BRAND",
    WEIGHT: "WEIGHT",
    FOIL_GUARANTEE: "FOIL_GUARANTEE",
    CONNECTION_KITS_PCS: "CONNECTION_KITS_PCS",
    WIRES: "WIRES",
    TAPE_PCS: "TAPE_PCS",
    THERMOSTAT_WARRANTY: "THERMOSTAT_WARRANTY",
    CONNECTOR: "CONNECTOR",
    MAT_GUARANTEE: "MAT_GUARANTEE",
    PRO_TAPE_PCS: "PRO_TAPE_PCS",
    PRODUCT_DESCRIPTION: "PRODUCT_DESCRIPTION",
    RETURN_TO_THE_KNOWLEDGE_BASE: "RETURN_TO_THE_KNOWLEDGE_BASE",
    SIMILAR_ARTICLES: "SIMILAR_ARTICLES",
    NUMBER_OF_INVESTMENTS: "NUMBER_OF_INVESTMENTS",
    VIEW_PROFILE: "VIEW_PROFILE",
    LATEST_INVESTMENTS: "LATEST_INVESTMENTS",
    THERE_ARE_NO_PRODUCTS_IN_THE_PROJECT: "THERE_ARE_NO_PRODUCTS_IN_THE_PROJECT",
    BILL_OF_MATERIALS_FOR_PROJECTS: "BILL_OF_MATERIALS_FOR_PROJECTS",
    YOUR_CALCULATION_WITHOUT_ASSEMBLY: "YOUR_CALCULATION_WITHOUT_ASSEMBLY",
    YOU_WANT_TO_ADD_A_MONTAGE: "YOU_WANT_TO_ADD_A_MONTAGE",
    NUMBER_OF_KILOMETERS: "NUMBER_OF_KILOMETERS",
    PRICE_FOR_ASSEMBLY: "PRICE_FOR_ASSEMBLY",
    YOUR_CALCULATION_WITH_ASSEMBLY: "YOUR_CALCULATION_WITH_ASSEMBLY",
    CHECK_WHAT_RATE_YOU_ARE_AT: "CHECK_WHAT_RATE_YOU_ARE_AT",
    SHOW_HEATING_COSTS: "SHOW_HEATING_COSTS",
    USABLE_AREA: "USABLE_AREA",
    SELECTED_ENERGY_CLASS: "SELECTED_ENERGY_CLASS",
    THE_PRICE_OF_ELECTRICITY: "THE_PRICE_OF_ELECTRICITY",
    HEATING_PRICE_IN_KWH_YEAR: "HEATING_PRICE_IN_KWH_YEAR",
    SAVE_AND_GENERATE_AN_OFFER: "SAVE_AND_GENERATE_AN_OFFER",
    NUMBER_OF_PROJECTS: "NUMBER_OF_PROJECTS",
    GO_TO_INVESTMENT: "GO_TO_INVESTMENT",
    LATEST_PROJECTS: "LATEST_PROJECTS",
    ASSEMBLY: "ASSEMBLY",
    START_TYPING: "START_TYPING",
    ORDERS_PLACED: "ORDERS_PLACED",
    NO_ORDERS_PLACED: "NO_ORDERS_PLACED",
    SUBMIT: "SUBMIT",
    SAYS: "SAYS",
    ROTATE: "ROTATE",
    SCALE: "SCALE",
    ADD_A_NEW_PROJECT: "ADD_A_NEW_PROJECT",
    GO_BACK_TO_PROJECTS: "GO_BACK_TO_PROJECTS",
    SELECT_THE_SIDE_ON_WHICH_THE_VIEW_IS_LOCATED: "SELECT_THE_SIDE_ON_WHICH_THE_VIEW_IS_LOCATED",
    STEP: "STEP",
    CREATED_BY: "CREATED_BY",
    INVESTOR_NAME: "INVESTOR_NAME",
    YOU_DONT_HAVE_ANY_OFFER_YET: "YOU_DONT_HAVE_ANY_OFFER_YET",
    CURRENT_VIEW_CREATOR_SAVED: "CURRENT_VIEW_CREATOR_SAVED",
    ENTER_DWG_FILE_SIZE: "ENTER_DWG_FILE_SIZE",
    CREATED_BY2: "CREATED_BY2",
    DO_YOU_WANT_TO_SAVE_CHANGES: "DO_YOU_WANT_TO_SAVE_CHANGES",
    NUMBER_OF_ROOMS: "NUMBER_OF_ROOMS",
    FOIL: "FOIL",
    MAT: "MAT",
    NETTO_PRICE: "NETTO_PRICE",
    QUANTITY_PCS_M: "QUANTITY_PCS_M",
    NET_VALUE: "NET_VALUE",
    MATERIAL_COST: "MATERIAL_COST",
    INVESTOR_COST: "INVESTOR_COST",
    ADD_NOTE: "ADD_NOTE",
    REMOVE_PROJECT_FROM_OFFER: "REMOVE_PROJECT_FROM_OFFER",
    COVERING_AREA_OF_ROOM: "COVERING_AREA_OF_ROOM",
    SEE_ASSORTMENT: "SEE_ASSORTMENT",
    LP: "LP",
    SUMMARY: "SUMMARY",
    THE_ORDER_HAS_BEEN_FORWARDED_FOR_EXECUTION: "THE_ORDER_HAS_BEEN_FORWARDED_FOR_EXECUTION",
    THE_STATUS_OF_THE_ORDER_CAN_BE_TRACKED_IN_THE_ORDERS_TAB: "THE_STATUS_OF_THE_ORDER_CAN_BE_TRACKED_IN_THE_ORDERS_TAB",
    THANK_YOU_FOR_YOUR_TRUST: "THANK_YOU_FOR_YOUR_TRUST",

    TO_PAY: "TO_PAY",
    SHIPPING_TO_YOUR_OWN_WAREHOUSE: "SHIPPING_TO_YOUR_OWN_WAREHOUSE",
    SHIPPING_TO_THE_CUSTOMER: "SHIPPING_TO_THE_CUSTOMER",
    YOU_HAVE_RECEIVED_AN_ADDITIONAL_DISCOUNT_RESULTING_FROM_COMBINING_TWO_INVESTMENTS_INTO_ONE_ORDER:
        "YOU_HAVE_RECEIVED_AN_ADDITIONAL_DISCOUNT_RESULTING_FROM_COMBINING_TWO_INVESTMENTS_INTO_ONE_ORDER",
    SHIPPING_ADDRESS: "SHIPPING_ADDRESS",
    ADDRESSEE: "ADDRESSEE",
    WAREHOUSE_ADDRESS: "WAREHOUSE_ADDRESS",
    CONTACT: "CONTACT",
    CHOOSE_PAYMENT_METHOD: "CHOOSE_PAYMENT_METHOD",
    TRADITIONAL_BANK_TRANSFER: "TRADITIONAL_BANK_TRANSFER",
    PAYMENT_DEADLINE: "PAYMENT_DEADLINE",
    CASH_ON_DELIVERY: "CASH_ON_DELIVERY",
    INVESTMENT_DATA: "INVESTMENT_DATA",
    DELIVERY_ADDRESS: "DELIVERY_ADDRESS",
    LIST_OF_PRODUCTS: "LIST_OF_PRODUCTS",
    PRODUCT_NAME: "PRODUCT_NAME",
    AMOUNT_OF_PRODUCTS: "AMOUNT_OF_PRODUCTS",
    NET_PRICE: "NET_PRICE",
    GROSS_PRICE: "GROSS_PRICE",
    INFO_IF_YOU_WANT_TO_ADD_RESERVE_MATERIAL_TO_AN_INVESTMENT_CLICK_EDIT_IN_THE_TOP_RIGHT_CORNER_OF_THE_PRODUCT_LIST:
        "INFO_IF_YOU_WANT_TO_ADD_RESERVE_MATERIAL_TO_AN_INVESTMENT_CLICK_EDIT_IN_THE_TOP_RIGHT_CORNER_OF_THE_PRODUCT_LIST",
    INVESTMENT_ADDRESS: "INVESTMENT_ADDRESS",
    BANK_TRANSFER_DETAILS: "BANK_TRANSFER_DETAILS",
    RECIPIENT: "RECIPIENT",
    ACCOUNT_NUMBER: "ACCOUNT_NUMBER",
    DOWNLOAD_PDF: "DOWNLOAD_PDF",
    GO_TO_ORDER: "GO_TO_ORDER",
    CHOOSE_A_BANK: "CHOOSE_A_BANK",
    THE_VALUE_OF_THE_MATERIALS: "THE_VALUE_OF_THE_MATERIALS",
    SHOPPING_CART_ITEMS: "SHOPPING_CART_ITEMS",
    INFO_TO_EDIT_THE_SHIPPING_ADDRESS_CLICK_ON_THE_PENCIL_IN_THE_UPPER_RIGHT_CORNER: "INFO_TO_EDIT_THE_SHIPPING_ADDRESS_CLICK_ON_THE_PENCIL_IN_THE_UPPER_RIGHT_CORNER",
    NO_ORDERS: "NO_ORDERS",
    STATUS_OF_THE_ORDER: "STATUS_OF_THE_ORDER",
    DATE: "DATE",
    SHIPPING_NO: "SHIPPING_NO",
    DOWNLOAD_THE_INVOICE: "DOWNLOAD_THE_INVOICE",
    MORE_INFORMATION: "MORE_INFORMATION",
    SHIPPING_INFORMATION: "SHIPPING_INFORMATION",
    ORDER_STATUS: "ORDER_STATUS",
    CONTACT_PERSON2: "CONTACT_PERSON2",
    ORDER_NOTES: "ORDER_NOTES",
    QUOTA: "QUOTA",
    GETTING_STARTED_ON_A_PROJECT_STARTS_WITH_ADDING_AN_INVESTMENT: "GETTING_STARTED_ON_A_PROJECT_STARTS_WITH_ADDING_AN_INVESTMENT",
    DUPLICATE: "DUPLICATE",
    ROOMS: "ROOMS",
    COMPLETED_OFFERS: "COMPLETED_OFFERS",
    NO_PROJECTS: "NO_PROJECTS",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECTS: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECTS",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_OFFERS: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_OFFERS",
    YOUR_ADVANTAGE_IN_ASSEMBLY: "YOUR_ADVANTAGE_IN_ASSEMBLY",
    YOUR_BENEFIT_ON_THE_MATERIAL: "YOUR_BENEFIT_ON_THE_MATERIAL",
    ADDED_TO_CART: "ADDED_TO_CART",
    CREATED: "CREATED",
    PENDING: "PENDING",
    COMPLETE: "COMPLETE",
    REJECTED: "REJECTED",
    COMPANY: "COMPANY",
    GO_TO_OFFERS: "GO_TO_OFFERS",
    DONE_WITH_INSTALLATION: "DONE_WITH_INSTALLATION",
    DONE_WITHOUT_INSTALLATION: "DONE_WITHOUT_INSTALLATION",
    THE_NAME_OF_THE_INVESTMENT_IS_REQUIRED: "THE_NAME_OF_THE_INVESTMENT_IS_REQUIRED",
    INVESTOR_DATA: "INVESTOR_DATA",
    USERS: "USERS",
    RULES: "RULES",
    CONTACT_DETAILS: "CONTACT_DETAILS",
    THE_LOGO_WILL_BE_USED_ON_THE_OFFER_AND_INVOICES_FOR_THE_CUSTOMER: "THE_LOGO_WILL_BE_USED_ON_THE_OFFER_AND_INVOICES_FOR_THE_CUSTOMER",
    UPLOAD_THE_FILE: "UPLOAD_THE_FILE",
    UPLOAD_THE_LOGO_FILE: "UPLOAD_THE_LOGO_FILE",
    MAX_SIZE: "MAX_SIZE",
    MAX_DIMENSIONS: "MAX_DIMENSIONS",
    CURRENT_FILE: "CURRENT_FILE",
    NO_PHOTO: "NO_PHOTO",
    DELETE_FILE: "DELETE_FILE",
    WIZARD_LANGUAGE: "WIZARD_LANGUAGE",
    COMPANY_LOGO: "COMPANY_LOGO",
    MAIN_USER: "MAIN_USER",
    OTHER_USERS: "OTHER_USERS",
    ADD_NEW_PROFILE: "ADD_NEW_PROFILE",
    CHANGES_HAVE_BEEN_MADE_DO_YOU_WANT_TO_SAVE_THEM: "CHANGES_HAVE_BEEN_MADE_DO_YOU_WANT_TO_SAVE_THEM",
    DISCARD: "DISCARD",
    POSITION_OPTIONAL: "POSITION_OPTIONAL",
    LOCK_PROFILE: "LOCK_PROFILE",
    DELETE_PROFILE: "DELETE_PROFILE",
    DO_SAVE_CHANGES: "DO_SAVE_CHANGES",
    POSITION: "POSITION",
    THE_NAME_OF_THE_ENERGY_CLASS_IS_REQUIRED: "THE_NAME_OF_THE_ENERGY_CLASS_IS_REQUIRED",
    THE_NAME_OF_THE_ENERGY_CLASS: "THE_NAME_OF_THE_ENERGY_CLASS",
    EU_DEMAND: "EU_DEMAND",
    EU_REQUIREMENT_IS_REQUIRED: "EU_REQUIREMENT_IS_REQUIRED",
    HEATING_SYSTEM_UNDER_THE_SPOUT: "HEATING_SYSTEM_UNDER_THE_SPOUT",
    HEATING_SYSTEM_UNDER_THE_SPOUT_IS_REQUIRED: "HEATING_SYSTEM_UNDER_THE_SPOUT_IS_REQUIRED",
    HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA: "HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA",
    HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_SAUNA_IS_REQUIRED: "HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_SAUNA_IS_REQUIRED",
    HEATING_SYSTEM_UNDER_THE_PANEL: "HEATING_SYSTEM_UNDER_THE_PANEL",
    HEATING_SYSTEM_UNDER_THE_PANEL_IS_REQUIRED: "HEATING_SYSTEM_UNDER_THE_PANEL_IS_REQUIRED",
    REMOVE_ENERGY_CLASS: "REMOVE_ENERGY_CLASS",
    TABLE_OF_ENERGY_CLASSES: "TABLE_OF_ENERGY_CLASSES",
    EU_DEMAND_EVERY_KWH: "EU_DEMAND_EVERY_KWH",
    SYSTEM_UNDER_THE_SPOUT: "SYSTEM_UNDER_THE_SPOUT",
    SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA: "SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA",
    SYSTEM_UNDER_THE_PANEL: "SYSTEM_UNDER_THE_PANEL",
    INFO_YOU_CAN_ADD_YOUR_OWN_ENERGY_CLASS_SETTINGS: "INFO_YOU_CAN_ADD_YOUR_OWN_ENERGY_CLASS_SETTINGS",
    ADD_YOUR_OWN_ENERGY_CLASS: "ADD_YOUR_OWN_ENERGY_CLASS",
    ASSEMBLY_RULE: "ASSEMBLY_RULE",
    ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_FROM_THE_USABLE_AREA_OF_THE_ROOM: "ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_FROM_THE_USABLE_AREA_OF_THE_ROOM",
    HEAT_DECOR_RECOMMENDATION: "HEAT_DECOR_RECOMMENDATION",
    ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_ON_THE_SURFACE_OF_THE_HEATING_MAT: "ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_ON_THE_SURFACE_OF_THE_HEATING_MAT",
    VAT_RULE: "VAT_RULE",
    ACTIVE_VAT_RULES: "ACTIVE_VAT_RULES",
    ADD_A_NEW_USER: "ADD_A_NEW_USER",
    UNLOCK_PROFILE: "UNLOCK_PROFILE",
    NET_PRICE_PER_M2: "NET_PRICE_PER_M2",
    SYSTEM_FOR_TILES: "SYSTEM_FOR_TILES",
    INSULATION_MAT: "INSULATION_MAT",
    XPS_EXTRUDED_POLYSTYRENE: "XPS_EXTRUDED_POLYSTYRENE",
    SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY: "SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY",
    SYSTEM_FOR_PANELS_5_MM_BASE: "SYSTEM_FOR_PANELS_5_MM_BASE",
    THE_MINIMUM_VALUE_MUST_NOT_BE_GREATER_THAN_THE_MAXIMUM: "THE_MINIMUM_VALUE_MUST_NOT_BE_GREATER_THAN_THE_MAXIMUM",
    UNDER_LAMINATED_PANELS: "UNDER_LAMINATED_PANELS",
    UNDER_THE_SPOUT: "UNDER_THE_SPOUT",
    UNDER_SPC_VINYL_PANELS: "UNDER_SPC_VINYL_PANELS",
    PRODUCT_TAILORED_TO_YOUR_SYSTEM: "PRODUCT_TAILORED_TO_YOUR_SYSTEM",
    YEARS_WARRANTY: "YEARS_WARRANTY",
    THERMOREGULATOR: "THERMOREGULATOR",
    INTERNAL_AIR_SENSOR: "INTERNAL_AIR_SENSOR",
    EXTERNAL_AIR_SENSOR: "EXTERNAL_AIR_SENSOR",
    CONNECTION: "CONNECTION",
    DOES_NOT_HAVE: "DOES_NOT_HAVE",
    WHITE: "WHITE",
    BLACK: "BLACK",
    MANUAL: "MANUAL",
    INFO_ZIGBEE: "INFO_ZIGBEE",
    SKIP: "SKIP",
    ATTENTION: "ATTENTION",
    ERROR: "ERROR",
    INFO: "INFO",
    RECTANGLE: "RECTANGLE",
    LINE: "LINE",
    DO_YOU_WANT_TO_ADD_ANOTHER_ELEMERNTS: "DO_YOU_WANT_TO_ADD_ANOTHER_ELEMERNTS",
    ADDITIONAL_DISCOUNT_HAS_BEEN_CHARGED: "ADDITIONAL_DISCOUNT_HAS_BEEN_CHARGED",
    IRREGULAR_SHAPE: "IRREGULAR_SHAPE",
    VAPOR_BARRIER_FILM: "VAPOR_BARRIER_FILM",
    INSULATING_UNDERLAY: "INSULATING_UNDERLAY",
    WIRES_BROWN: "WIRES_BROWN",
    WIRES_BLUE: "WIRES_BLUE",
    CONNECTORS_FOR_HEATING_FOIL: "CONNECTORS_FOR_HEATING_FOIL",
    SELF_FUSING_TAPE: "SELF_FUSING_TAPE",
    HEAT_DECOR_ADHESIVE_TAPE: "HEAT_DECOR_ADHESIVE_TAPE",
    ARRANGEMENT: "ARRANGEMENT",
    DRAG: "DRAG",
    DELETE_ROOM: "DELETE_ROOM",
    INCORRECT_FORMAT: "INCORRECT_FORMAT",
    USE_HAND: "USE_HAND",
    FILE_TYPE_MUST_BE: "FILE_TYPE_MUST_BE",
    THERE_IS_NO_SUCH_OFFER: "THERE_IS_NO_SUCH_OFFER",
    COPY_DATA: "COPY_DATA",
    DELETE_THERMOSTAT: "DELETE_THERMOSTAT",
    SHOWER_BATH__WASHING_MACHINE: "SHOWER_BATH__WASHING_MACHINE",
    FREESTANDING_WASHBASIN__FREESTANDING_BOWL: "FREESTANDING_WASHBASIN__FREESTANDING_BOWL",
    WALL_HUNG_WASHBASIN__HUNG_BOWL: "WALL_HUNG_WASHBASIN__HUNG_BOWL",
    SEWAGE_GRATE: "SEWAGE_GRATE",
    SELECT_POINTS_TO_CREATE_A_FIGURE: "SELECT_POINTS_TO_CREATE_A_FIGURE",
    STAINLESS_STEEL_MESH: "STAINLESS_STEEL_MESH",
    GROUNDING_WIRE: "GROUNDING_WIRE",
    CONNECTORS_FOR_STAINLESS_STEEL_MESH: "CONNECTORS_FOR_STAINLESS_STEEL_MESH",
    SELF_VULCANIZING_TAPE_FOR_HEATING_FOIL_CONNECTORS: "SELF_VULCANIZING_TAPE_FOR_HEATING_FOIL_CONNECTORS",
    REINFORCED_TAPE: "REINFORCED_TAPE",
    INSTALLATION_BOX: "INSTALLATION_BOX",
    PROTECTIVE_CONDUIT: "PROTECTIVE_CONDUIT",
    CHAT: "CHAT",
    INPUTENERGY: "INPUTENERGY",
    LISTOFINVEST: "LISTOFINVEST",
    PROJECTSSTATUS: "PROJECTSSTATUS",
    FLOORAREA: "FLOORAREA",
    COMPLETENOTES: "COMPLETENOTES",
    TEXTADDINVEST: "TEXTADDINVEST",
    IMPORTANT: "IMPORTANT",
    TEXTADDINVEST2: "TEXTADDINVEST2",
    INVESTORPROFILTEXT: "INVESTORPROFILTEXT",
    INVESTORPROFILTEXT2: "INVESTORPROFILTEXT2",
    INVESTORPROFILTEXT3: "INVESTORPROFILTEXT3",
    HEATING_SYSTEM: "HEATING_SYSTEM",
    SYSTEMCOMPONENTS: "SYSTEMCOMPONENTS",
    MODIFY_THE_PERMANENT_STRUCTURE: "MODIFY_THE_PERMANENT_STRUCTURE",
    ACCESSORIES: "ACCESSORIES",
    DATE_CREATED: "DATE_CREATED",
    COPY_OFFER: "COPY_OFFER",
    UNDO: "UNDO",
    FILL_IN_THE_DETAILS: "FILL_IN_THE_DETAILS",
    E_G: "E_G",
    ADD_INVEST_TEXT: "ADD_INVEST_TEXT",
    COMPLATE_THE_INVEST: "COMPLATE_THE_INVEST",
    DISCOUNT_VALUE: "DISCOUNT_VALUE",
    ADD_MONTAGE: "ADD_MONTAGE",
    MOUNTING_SYSTEM: "MOUNTING_SYSTEM",
    COST_OF_INSTALL: "COST_OF_INSTALL",
    NOTE_CHECK: "NOTE_CHECK",
    VAT_RATE: "VAT_RATE",
    NOTE_CHECK_VAT: "NOTE_CHECK_VAT",
    HEATING_COSTS: "HEATING_COSTS",
    ADD_HEATING_COSTS: "ADD_HEATING_COSTS",
    RESET: "RESET",
    RETURN_TO_OFFERS: "RETURN_TO_OFFERS",
    LACK_OF_ROOMS: "LACK_OF_ROOMS",
    DRAW_A_ROOM: "DRAW_A_ROOM",
    HEATING_FOIL_UNDER_THE_LAYERED: "HEATING_FOIL_UNDER_THE_LAYERED",
    CONFIGURATION_OF_PRICE: "CONFIGURATION_OF_PRICE",
    SALEOFFER: "SALEOFFER",
    EXPIRATIONDATE: "EXPIRATIONDATE",
    COSTOFINSTALLSERVICE: "COSTOFINSTALLSERVICE",
    COSTOFALLMATERIALS: "COSTOFALLMATERIALS",
    LISTOFMATERIALS: "LISTOFMATERIALS",
    MATERIAL: "MATERIAL",
    NO: "NO",
    MANUFACTUREROFHEATING: "MANUFACTUREROFHEATING",
    VISUALIZATION: "VISUALIZATION",
    VISUALIZATIONOFTHEHEATING: "VISUALIZATIONOFTHEHEATING",
    LOGODISTRIBUTOR: "LOGODISTRIBUTOR",
    VISUALIZATIONMADE: "VISUALIZATIONMADE",
    AREAOFTHEHEATING: "AREAOFTHEHEATING",
    POWEROFTHEHEATING: "POWEROFTHEHEATING",
    ELECTRICALCONNECTION: "ELECTRICALCONNECTION",
    ESTIMATEDANNUAL: "ESTIMATEDANNUAL",
    ENERGYCLASSSOLO: "ENERGYCLASSSOLO",
    CHANGETHEPOWER: "CHANGETHEPOWER",
    GERMAN: "GERMAN",
    ROMANIAN: "ROMANIAN",
    HUNGARIAN: "HUNGARIAN",
    RODO: "RODO",
    RODOTEXT: "RODOTEXT",
    RODOHD: "RODOHD",
    SCOPEOFRESPONSIBILITY: "SCOPEOFRESPONSIBILITY",
    ATTACHMENTS: "ATTACHMENTS",
    ATTACHMENTSTEXT: "ATTACHMENTSTEXT",
    ANNOTATIONS: "ANNOTATIONS",
    THANKYOU: "THANKYOU",
    THANKYOUTEXT: "THANKYOUTEXT",
    THANKYOUQUESTION: "THANKYOUQUESTION",
    OFFERINGPERSON: "OFFERINGPERSON",
    OFFERINGCOMPANY: "OFFERINGCOMPANY",
    RECOMMENDMODERN: "RECOMMENDMODERN",
    MAINTENANCE: "MAINTENANCE",
    COMPLETLY: "COMPLETLY",
    SILENT: "SILENT",
    FRIENDLY: "FRIENDLY",
    ENVIRONMENT: "ENVIRONMENT",
    WITHINTELLIGENT: "WITHINTELLIGENT",
    CONTROL: "CONTROL",
    VISUALIZATIONOFTHEDISTRIBUTION: "VISUALIZATIONOFTHEDISTRIBUTION",
    HEATINGSYSTEM: "HEATINGSYSTEM",
    TOTALPOWER: "TOTALPOWER",
    HEATINGFLOORS: "HEATINGFLOORS",
    LEGEND: "LEGEND",
    PERMANENT_BUILDINGS2: "PERMANENT_BUILDINGS2",
    CAPACITYOFHEATING: "CAPACITYOFHEATING",
    NAMEOFROOM: "NAMEOFROOM",
    SYSTEMTYPE: "SYSTEMTYPE",
    AREACOVERAGE: "AREACOVERAGE",
    POWERPER: "POWERPER",
    SYSTEMPOWER: "SYSTEMPOWER",
    HEATINGCIRCUIT: "HEATINGCIRCUIT",
    CONTACTOR: "CONTACTOR",
    OFFERNO: "OFFERNO",
    DEVELOPMENT: "DEVELOPMENT",
    BASEMENTPLAN: "BASEMENTPLAN",
    FLOORPLAN: "FLOORPLAN",
    ATTICPLAN: "ATTICPLAN",
    LEVELPLAN: "LEVELPLAN",
    STOREYPLAN: "STOREYPLAN",
    ROOMPLAN: "ROOMPLAN",
    GROUNDFLOORPLAN: "GROUNDFLOORPLAN",
    NO_THERMOSTAT: "NO_THERMOSTAT",
    EXTERNAL_SENSOR: "EXTERNAL_SENSOR",
    APPLY_DISCOUNT_TO_ALL_PRODUCTS: "APPLY_DISCOUNT_TO_ALL_PRODUCTS",
    INVESTOR_PRICE_BELOW_COST: "INVESTOR_PRICE_BELOW_COST",
    CURRENCY: "CURRENCY",
    POLISH_ZLOTY: "POLISH_ZLOTY",
    EURO: "EURO",
    CZECH_KORUNA: "CZECH_KORUNA",
    BRITISH_POUND: "BRITISH_POUND",
    CURRENCY_IS_REQUIRED: "CURRENCY_IS_REQUIRED",
    SELECT_DISTRIBUTOR: "SELECT_DISTRIBUTOR",
    SELECT_ACTIVE_PRODUCTS: "SELECT_ACTIVE_PRODUCTS",
    MANAGE_PRODUCTS: "MANAGE_PRODUCTS",
    ENTER_MESSAGE: "ENTER_MESSAGE",
    LOGFF: "LOGFF",
    SELECTUSER: "SELECTUSER",
    USERACCOUNT: "USERACCOUNT",
    US_DOLLAR: "US_DOLLAR",
    PRODUCT_SUMMARY: "PRODUCT_SUMMARY",
    ERROR_OCCURRED: "ERROR_OCCURRED",
    PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_SMS_CODE: "PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_SMS_CODE",
    DOWNLOAD_EXCEL: "DOWNLOAD_EXCEL",
    RESET_TO_DEFAULTS: "RESET_TO_DEFAULTS",
    ORDER_VALUE: "ORDER_VALUE",
    SHOW_HINTS: "SHOW_HINTS ",
};
