const k = require("./keys");

export const hungarian = {
    [k._]: `:`,
    [k._1]: ``,
    [k._10]: `4`,
    [k._11]: `5`,
    [k._12]: `6`,
    [k._13]: `06:00`,
    [k._14]: `10`,
    [k._15]: `1:`,
    [k._16]: `]`,
    [k._17]: `100%`,
    [k._2]: `-`,
    [k._3]: `/`,
    [k._4]: `.`,
    [k._5]: `(`,
    [k._6]: `12:00`,
    [k._7]: `1`,
    [k._8]: `2`,
    [k._9]: `3`,
    [k.ADD]: `Hozzáadás`,
    [k.ADD_ADDRESS]: `Cím hozzáadása`,
    [k.ADD_ANOTHER_ROOM]: `Új szoba hozzáadása`,
    [k.ADD_NEW_ADDRESS]: `Új cím hozzáadása`,
    [k.ADDTOCART]: `Kosárba helyezés`,
    [k.ANNUAL_ELECTRICITY_CONSUMPTION]: `Éves villamosenergia-fogyasztás`,
    [k.ANNUAL_OPERATING_COSTS]: `Éves üzemeltetési költségek`,
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ADDRESS]: `Biztosan törölni szeretné ezt a címet?`,
    [k.ARCHITECT]: `Építész`,
    [k.APARTMENT]: `Lakás`,
    [k.BACK]: `Vissza`,
    [k.TRANSFER]: `Átutalás`,
    [k.BANK_TRANSFER]: `Banki átutalás`,
    [k.BLOCKOFFLATS]: `Lakótömb`,
    [k.BUILDINGENERGYCLASS]: `Épület energetikai besorolása`,
    [k.BUYBYMETER]: `Vásárlás méterre`,
    [k.BUYONROLLS]: `Vásárlás tekercsben`,
    [k.BYMETERS]: `(méterre)`,
    [k.CONFIRM]: `Megerősítés`,
    [k.CZECH]: `Cseh`,
    [k.CALCULATECOSTS]: `Költségek kiszámítása`,
    [k.CALCULATE_HEATING_COSTS]: `Fűtési költségek kiszámítása`,
    [k.CREATE_ACCOUNT]: `Fiók létrehozása`,
    [k.CANCEL]: `Mégse`,
    [k.CHOOSELANG]: `Nyelv kiválasztása`,
    [k.CHOOSE_NEXT_STEP]: `Válassza a következő lépést`,
    [k.COSTCALCULATION]: `Költségszámítás`,
    [k.CONSTRUCTION]: `Építés`,
    [k.DELETE]: `Törlés`,
    [k.DEVELOPER]: `Fejlesztő`,
    [k.DISCOUNTGRANTED]: `Kedvezmény megadva`,
    [k.DISCOUNTLEVEL]: `Aktuális kedvezményszintje`,
    [k.DISTRIBUTORS]: `Forgalmazók`,
    [k.ELECTRICHEATERS]: `Elektromos fűtőtestek`,
    [k.EMPLOYEE]: `Munkavállaló`,
    [k.ENGLISH]: `Angol`,
    [k.ENTER_ROOMS_DIMENSIONS]: `Adja meg a szoba méreteit`,
    [k.ENTER_ROOMS_DIMENSIONS_BASED_ON_FIGURE]: `Adja meg a szoba méreteit az alak alapján`,
    [k.ENTER_A_NEW_PASSWORD]: `Az új jelszónak legalább 8 karakterből, kis- és nagybetűből, valamint speciális karakterből kell állnia`,
    [k.FINISHED]: `Kész`,
    [k.FOILPOWER]: `Fólia teljesítménye`,
    [k.FOILWIDTH]: `Fólia szélessége`,
    [k.FRENCH]: `Francia`,
    [k.GERMAN]: `Német`,
    [k.GROSS]: `bruttó`,
    [k.ELECRICITYPRICE]: `Villamosenergia ára (HUF / kWh)`,
    [k.ENERGYCLASS]: `Az épület energetikai osztálya (kWh / m2 évente)`,
    [k.ENTER_INSTALLATION_PARAMS]: `Adja meg a telepítési paramétereket a fogyasztás kiszámításához`,
    [k.FOR_CLIENT]: `Az ügyfélnek`,
    [k.GENERAL_HEATING_COST_CALCULATOR]: `Infravörös fűtési költségszámoló az épület energetikai osztályával:`,
    [k.LOWENERGY]: `A Alacsony energiafogyasztású`,
    [k.GOTOCASHREGISTER]: `Tovább a pénztárhoz`,
    [k.HEATINGCOSTCALCULATOR]: `Fűtési költségszámoló`,
    [k.HEATINGFOIL]: `Fűtőfólia`,
    [k.HEATINGMAT]: `Fűtőszőnyeg`,
    [k.HOUSE]: `Ház`,
    [k.HOUSINGESTATE]: `Lakótelep`,
    [k.INSTALLER]: `Telepítő`,
    [k.I_ORDER_WITH_SHIPPING_TO_CUSTOMERS]: `Megrendelem ügyfélhez történő szállítással`,
    [k.I_AM_ORDERING_FOR_MY_OWN_WAREHOUSE]: `Megrendelem saját raktárba`,
    [k.ITALIAN]: `Olasz`,
    [k.HEATING_SYSTEM_POWER]: `Fűtési rendszer névleges teljesítménye`,
    [k.INSTALLATIONDESIGN]: `Telepítési terv`,
    [k.INVESTMENT_MATERIALS_COSTS]: `Beruházási anyagköltségek`,
    [k.INVESTORS]: `Befektetők`,
    [k.INVESTMENTS]: `Beruházások`,
    [k.KNOWLEDGEBASE]: `Tudásbázis`,
    [k.LARGEQUANTITYINSTOCK]: `Nagy mennyiség raktáron`,
    [k.LEAVEPROJECT]: `Projekt elhagyása`,
    [k.LENGTH_IN_CM]: `Hosszúság cm-ben`,
    [k.LOGIN]: `Bejelentkezés`,
    [k.LOG_IN]: `Bejelentkezés`,
    [k.LOGOUT]: `Kijelentkezés`,
    [k.MANAGER]: `Menedszer`,
    [k.MARKETING]: `Marketing`,
    [k.MEDIUMENERGYSAVING]: `C Közepes energiahatékonyságú`,
    [k.ENERGYSAVING]: `B Energiahatékony`,
    [k.MIN_PV_INSTALATION_REQUIRED]: `Minimális fotovoltaikus telepítés szükséges a fűtési költségek kiegyenlítéséhez`,
    [k.MORE_PRODUCTS_AND_GO_TO_HIGHER_DISCOUNT_LEVEL]: `termékek és lépjen magasabb kedvezményszintre`,
    [k.NO]: `Nem`,
    [k.NODATA]: `Nincs adat`,
    [k.NORWEGIAN]: `Norvég`,
    [k.ONROLLS]: `(tekercsben)`,
    [k.POLISH]: `Lengyel`,
    [k.MONTHLY_OPERATING_COSTS]: `Havi üzemeltetési költségek`,
    [k.NET]: `nettó`,
    [k.NEWROOM]: `Új szoba`,
    [k.OFFERGENERATING]: `Ajánlat készítése`,
    [k.OFFERS]: `Ajánlatok`,
    [k.ORDERS]: `Rendelések`,
    [k.ONLINE_PAYMENT]: `Online fizetés`,
    [k.PAYMENT_METHOD]: `Fizetési mód`,
    [k.OWN]: `Saját`,
    [k.OWNER]: `Tulajdonos`,
    [k.PASSIVE_A_PLUS]: `A+ Passzív`,
    [k.PHOTOVOLTAIC]: `Fotovoltaika`,
    [k.PRESENTED_CALC_ARE_INFORMATIVE]: `*A bemutatott számítások tájékoztató jellegűek és nem minősülnek
      ajánlattételnek a Polgári Törvénykönyv 66. § 1. bekezdése értelmében`,
    [k.PRICELIST]: `Árlista`,
    [k.PRODUCT]: `Termék`,
    [k.PRODUCTSINCART]: `Termékek a kosárban`,
    [k.PRODUCTAVAILABLE]: `Termék elérhető`,
    [k.PROFIT]: `Nyereség`,
    [k.PROJECTS]: `Projektek`,
    [k.PUBLICFACILITY]: `Közintézmény`,
    [k.RATED_ENERGY_CONSUMPTION]: `Névleges energiafogyasztás`,
    [k.REJECT_CHANGES]: `Változtatások elvetése`,
    [k.REMIND_PASSWORD]: `Jelszó emlékeztető`,
    [k.REMEMBER_ME]: `Emlékezz rám`,
    [k.RETURN_TO_THE_LOGIN_PANEL]: `Vissza a bejelentkező panelre`,
    [k.QUANTITY]: `Mennyiség`,
    [k.ROMANIAN]: `Román`,
    [k.SALE]: `Akciók`,
    [k.SAVEANDCLOSE]: `Mentés és bezárás`,
    [k.SAVECHANGES]: `Változtatások mentése`,
    [k.SEARCHFORPRODUCTS]: `Termékek keresése`,
    [k.SEETHEPRODUCT]: `Termék megtekintése`,
    [k.SETTINGS]: `Beállítások`,
    [k.SHOWCROSS_SECTION]: `Metszet megjelenítése`,
    [k.SHOWLAYERS]: `Rétegek megjelenítése`,
    [k.SLOVAK]: `Szlovák`,
    [k.SMALLQUANTITYINSTOCK]: `Kis mennyiség raktáron`,
    [k.SOMETHING_WENT_WRONG]: `Valami hiba történt:`,
    [k.SPAIN]: `Spanyol`,
    [k.RELOADAPPLICATION]: `Alkalmazás újratöltése`,
    [k.SWEDISH]: `Svéd`,
    [k.THERMOSTAT]: `Termosztát`,
    [k.THERMOSTATFUNCTIONS]: `Termosztát funkciók`,
    [k.THERMOSTATS]: `Termosztátok`,
    [k.TOGETHER]: `Összesen`,
    [k.TOGETHERTOPAY]: `Fizetendő összeg`,
    [k.USABLEAREA]: `Hasznos terület (m2)`,
    [k.ZEROENERGY]: `A++ Zéró energia`,
    [k.FLOOR]: `Padló`,
    [k.CEILING]: `Mennyezet`,
    [k.WALL]: `Fal`,
    [k.WAREHOUSE]: `Raktár`,
    [k.WHOLESALER]: `Nagykereskedő`,
    [k.WT2021]: `WT2021`,
    [k.ADDROOM]: `Szoba hozzáadása`,
    [k.CHANGE]: `Változtatás`,
    [k.YES]: `Igen`,
    [k.GOBACKTOCREATOR]: `Vissza a tervezőhöz`,
    [k.GOBACKTOCART]: `Vissza az árlistához`,
    [k.SQUAREM]: `m²`,
    [k.PIECE]: `db.`,
    [k.RUNNINGM]: `fm.`,
    [k.COLOR]: `Szín`,
    [k.UNDO]: `Visszavonás`,
    [k.REDO]: `Ismétlés`,
    [k.ZOOMIN]: `Nagyítás`,
    [k.ZOOMOUT]: `Kicsinyítés`,
    [k.MEASURE]: `Mérés`,
    [k.CLOSEPOLYGON]: `Sokszög bezárása`,
    [k.CONTACTORREQUIRED]: `kontaktor szükséges`,
    [k.WRONGLOGINORPASSWORD]: "Helytelen felhasználónév vagy jelszó.",
    [k.WIDTH_IN_CM]: "Szélesség cm-ben",
    [k.WIDTH]: "Szélesség",
    [k.MISSINGUSER]: "Felhasználó nem található.",
    [k.PASSWORD]: "Jelszó",
    [k.PASSWORDREQUIRED]: "A jelszó megadása kötelező.",
    [k.CONFIRMPASSWORDMUSTBESAME]: "A megerősítő jelszónak egyeznie kell.",
    [k.PASSWORDSHOULDBEMIN8CHARLONG]: "A jelszónak legalább 8 karakter hosszúnak kell lennie.",
    [k.PASSWORDPATTERNQUOTE]: "A jelszónak tartalmaznia kell legalább egy számot, nagybetűt, kisbetűt és speciális karaktert (pl.: @, #, &, !, $).",
    [k.PASSWORD_HAS_BEEN_RESET]: "A jelszó beállítva",
    [k.CALIBRATION]: "Kalibráció",
    [k.READY]: "Kész",
    [k.EDIT]: "Szerkesztés",
    [k.START]: "Indítás",
    [k.UNFOLDAUTOMATICALLY]: "Automatikus kinyitás",
    [k.CUTTINGPOINT]: "Vágási pont",
    [k.TYPE]: "Típus",
    [k.FURNITURE]: "Bútor",
    [k.BATH_SHOWERTRAY]: "Kád/zuhanytálca",
    [k.SINK]: "Mosdó/WC függesztett",
    [k.KITCHEN_FURNITURE]: "Konyhabútor",
    [k.FIREPLACE]: "Kandalló",
    [k.HOUSEHOLD_APPLIANCES]: "Háztartási gépek",
    [k.YOUR_ADDRESSES]: "Címeid",
    [k.YOUR_NET_PRICE]: "Nettó árad",
    [k.YOUR_GROSS_PRICE]: "Bruttó árad",
    [k.OPTIMAL]: "Optimális",
    [k.VERTICALY]: "Függőlegesen",
    [k.HORIZONTALY]: "Vízszintesen",
    [k.ALLWIDTHS]: "Minden szélesség",
    [k.DRAWOFFAREA]: "Szeretne állandó berendezési tárgyakat hozzáadni?",
    [k.INNER]: "Belső",
    [k.OUTER]: "Külső",
    [k.OTHER]: "Egyéb",
    [k.ROOM]: "Szoba",
    [k.BATHROOM]: "Fürdőszoba",
    [k.BASEMENT]: "Pince",
    [k.GROUND_FLOOR]: "Földszint",
    [k.FIRST_FLOOR]: "Első emelet",
    [k.ATTIC]: "Tetőtér",
    [k.STOREY]: "Szint",
    [k.LEVEL]: "Szint",
    [k.ROOM]: "Helyiség",
    [k.ADDFILE]: "Fájl hozzáadása",
    [k.DRAWPLAN]: "Alaprajz rajzolása",
    [k.LIVINGROOM]: "Nappali",
    [k.KITCHEN]: "Konyha",
    [k.VESTIBULE]: "Előtér",
    [k.BEDROOM]: "Hálószoba",
    [k.DININGROOM]: "Étkező",
    [k.WARDROBE]: "Gardrób",
    [k.TOILET]: "WC",
    [k.LAUNDRY]: "Mosókonyha",
    [k.HALL]: "Előszoba",
    [k.CABINET]: "Dolgozószoba",
    [k.ANTEROOM]: "Előszoba",
    [k.CORRIDOR]: "Folyosó",
    [k.PANTRY]: "Kamra",
    [k.LIVING_DINING_ROOM]: "Nappali + Étkező",
    [k.LIVINGROOM_WITH_KITCHENETTE]: "Nappali konyhasarokkal",
    [k.GARAGE]: "Garázs",
    [k.TECHNICALROOM]: "Műszaki helyiség",
    [k.UTILITYROOM]: "Háztartási helyiség",
    [k.RECREATIONROOM]: "Rekreációs helyiség",
    [k.ENTRESOL]: "Galéria",
    [k.SAUNA]: "Szauna",
    [k.BOILERROOM]: "Kazánház",
    [k.CUBBY]: "Tárhely",
    [k.STAIRS]: "Lépcső",
    [k.FIREPLACE_ROOM]: "Kandallószoba",
    [k.GO_BACK_EDIT]: "Vissza a szerkesztéshez",
    [k.GO_FURTHER]: "Tovább",
    [k.DRAW_NEXT]: "Rajzolj újabbat",
    [k.START_DESIGNING]: "Tervezés indítása",
    [k.IN_THE_WIZARD_HEAT_DECOR]: "a Heat Decor tervezőben",
    [k.YOU_CAN_CREATE_AN_INFRARED_HEATING_PROJECT_IN_JUST_FEW_STEPS]: "Néhány egyszerű lépésben létrehozhatja az infravörös fűtési projektet.",
    [k.ENTER_THE_EMAIL_PROVIDED_WHEN_CREATING_THE_ACCOUNT]: "Adja meg a fiók létrehozásakor megadott e-mail címet.",
    [k.WHAT_WILL_YOU_GAIN_BY_WORKING]: "Mit nyer azzal, ha a",
    [k.WITH_THE_HEAT_DECOR_CREATOR]: "Heat Decor tervezővel dolgozik?",
    [k.PRECISE_DESIGNS_OF_THE_HEATING_SYSTEM_IN_THE]: "• Pontos fűtési rendszertervek a",
    [k.HEAT_DECOR_STANDARD_READY_FOR_USE_BY]: "Heat Decor szabvány szerint, kész felhasználásra",
    [k.THE_INSTALLATION_TEAM]: "a telepítő csapat által",
    [k.EASIER_AND_MORE_COMPLETE_PREPARATION_OF_AN_OFFER_FOR]: "• Könnyebb és teljesebb ajánlatkészítés a",
    [k.YOUR_CLIENT]: "ügyfél számára",
    [k.FACILITATING_THE_PROCESS_OF_ORDERING_PRODUCTS]: "• A termékrendelési folyamat megkönnyítése",
    [k.HEAT_DECOR]: "Heat Decor",
    [k.WE_ARE_CHANGING_THE_FUTURE_FOR]: "A jövőt változtatjuk ",
    [k.GREEN]: "zölddé",
    [k.WATCH_HEAT_DECOR]: "Kövesse a Heat Decort",
    [k.READ_AND_SUBSCRIBE]: "olvassa és iratkozzon fel",
    [k.BLOG]: "blog",
    [k.PRIMARY_USER]: "Elsődleges felhasználó:",
    [k.HAS_ACCESS_TO_ALL_WIZARDS_FUNCTIONALITIES]: "• Hozzáfér a tervező összes funkciójához.",
    [k.DEFINING_THEROLE_IN_THE_COMPANYS_STRUCTURE_WILL_FACILITATE_FURTHER_COOPERATION_WITH_HEAT_DECOR]:
        "• A szerep meghatározása a vállalat struktúrájában megkönnyíti a további együttműködést a Heat Decorral.",
    [k.SPECIFYING_THE_TYPE_OF_COMPANY_WILL_ALLOW_YOU_TO_ADJUST_THE_WIZARD_TO_YOUR_NEEDS]: "• A cég típusának meghatározása lehetővé teszi a tervező igényeihez való igazítását.",
    [k.AFTER_REGISTRATION_HEAT_DECOR_WILL_CONTACT_THE_INDICATED_MAIN_USER]: "• A regisztráció után a Heat Decor kapcsolatba lép a megadott fő felhasználóval.",
    [k.ADDITIONAL_USERS]: "További felhasználók:",
    [k.THEY_CAN_USE_THE_WIZARD_TO_DEVELOP_DESIGNS_AND_QUOTES_FOR_THEIR_CLIENTS]: "• Használhatják a tervezőt a tervek és ajánlatok készítéséhez ügyfeleik számára.",
    [k.THEY_HAVE_LIMITED_ACCOUNT_EDITING_CAPABILITIES]: "• Korlátozott fiókszerkesztési lehetőségeik vannak.",
    [k.IN_CASE_OF_REPEATED_DIFFICULTIES_WITH_USING_THE_WIZARD_CONTACT_THE_ADMINISTRATOR_OF_THE_HEAT_DECOR_WIZARD]:
        "Ismétlődő nehézségek esetén lépjen kapcsolatba a Heat Decor tervező adminisztrátorával:",
    [k.EMAIL]: "Email",
    [k.TELEPHONE]: "Telefon:",
    [k.PRIVACY_POLICY]: "Adatvédelmi nyilatkozat",
    [k.WEBSITE_REGULATIONS]: "Weboldal szabályzat",
    [k.THANK_YOU_FOR_YOUR_REGISTRATION]: "Köszönjük regisztrációját",
    [k.LOGGING_IN_WILL_BE_POSSIBLE_AFTER_SUCCESSFULLY]: "Bejelentkezés a sikeres",
    [k.SETTING_THE_PASSWORD_AND_VERIFYING_THE_ACCOUNT_BY_THE]: "jelszó beállítása és a fiók adminisztrátor általi ellenőrzése után lehetséges",
    [k.ADMINISTRATOR]: "Adminisztrátor.",
    [k.YOU_WILL_RECEIVE_A_LINK_TO_SET_A_NEW_PASSWORD]: "Egy linket fog kapni új jelszó beállításához.",
    [k.EMAIL_WAS_SENT_FOLLOW_THE_INSTRUCTIONS]: "Az email elküldve. Kövesse az utasításokat az",
    [k.IN_THE_EMAIL]: " e-mailben.",
    [k.CHECK_EMAIL]: "Ellenőrizze az e-mailt",
    [k.TO_COMPLETE_THE_REGISTRATION_PROCESS_CHECK_THE_MESSAGE_SENT_AUTOMATICALLY_TO_THE_EMAIL_ADDRESS_PROVIDED_AND_CLICK_THE_LINK_TO_CONFIRM_THE_REGISTRATION]:
        "A regisztrációs folyamat befejezéséhez ellenőrizze az automatikusan küldött üzenetet a megadott e-mail címre, és kattintson a megerősítő linkre.",
    [k.REGISTRATION_ERROR]: "Regisztrációs hiba történt",
    [k.AN_ERROR_OCCURRED_WHILE_CREATING_A_NEW_ACCOUNT]: "Hiba történt az új fiók létrehozása közben.",
    [k.TRY_AGAIN_IF_THE_ERROR_REPEATS_TRY_TO_REGISTER_TO_A_DIFFERENT_EMAIL_ADDRESS_OR_CONTACT_US]:
        "Ha a hiba ismétlődik, próbálkozzon egy másik e-mail cím regisztrálásával, vagy lépjen kapcsolatba velünk.",
    [k.ENTER_THE_ACCOUNT_PRIMARY_USER_INFORMATION]: "Adja meg a fiók fő felhasználójának adatait.",
    [k.REGISTRATION]: "Regisztráció",
    [k.ADDITIONAL_USERS_OPTIONAL]: "További felhasználók (opcionális)",
    [k.INVESTMENT]: "Befektetés",
    [k.INVESTORS_NAME]: "Befektető neve:",
    [k.ADDED_BY]: "Hozzáadta:",
    [k.NOTES]: "Jegyzetek",
    [k.STREET]: "Utca",
    [k.CITY]: "Város",
    [k.ZIP_CODE]: "Irányítószám",
    [k.GROSS]: "bruttó",
    [k.NET_PRICE_FOR_THE_CLIENT]: "Nettó ár az ügyfélnek:",
    [k.NET_PROFIT]: "Nettó nyereség:",
    [k.DOWNLOAD_MULTIPLE_PDF_FILES]: "Több PDF letöltése",
    [k.DELETE_PROJECTS]: "Projektek törlése",
    [k.ADD_TO_CART]: "Kosárba helyezés",
    [k.REMOVE_OFFERS]: "Ajánlatok eltávolítása",
    [k.GENEROWANIE_OFERTY]: "Ajánlat készítése",
    [k.RESET_PASS]: "Jelszó visszaállítása",
    [k.VALUE_OF_THE_OFFER]: "Ajánlat értéke",
    [k.YOUR_COST]: "Költséged",
    [k.YOUR_PRICE]: "Árad",
    [k.THE_INVESTOR_PAYS]: "A befektető fizet",
    [k.YOU_EARN]: "Te keresel",
    [k.GO_ON]: "Folytatás",
    [k.ENTER_NEW_PASSWORD]: "Adjon meg új jelszót",
    [k.NEW_PASSWORD]: "Új jelszó",
    [k.PRICE]: "Ár",
    [k.PRICE_FOR_THE_CLIENT]: "Ár az ügyfélnek:",
    [k.A_PRICE_FOR_YOU]: "Ár neked:",
    [k.INCORRECT_LOGIN_OR_PASSWORD]: "Helytelen bejelentkezési név vagy jelszó",
    [k.OFFER]: `Ajánlat`,
    [k.BY]: `által`,
    [k.LIST_OF_ORDERS]: "Rendelések listája",
    [k.THANK_YOU_FOR_YOUR_ORDER]: "Köszönjük a rendelését",
    [k.GO_TO_ORDERS]: "Ugrás a rendelésekhez",
    [k.SMS_CODE]: "SMS kód",
    [k.INCORRECT_AUTHORIZATION_CODE]: "Helytelen hitelesítési kód",
    [k.ENTER_AUTHORIZATION_CODE]: "Adja meg a hitelesítési kódot",
    [k.CONFIRMED]: "Megerősítve",
    [k.RESEND_CODE]: "Kód újraküldése",
    [k.REENTER_THE_6_DIGIT_CODE]: "Adja meg újra a 6 számjegyű SMS-kódot",
    [k.TO_VERIFY_YOUR_ACCOUNT]: "fiók hitelesítéséhez",
    [k.ENTER_6_DIGIT_SMS_CODE_TO_VERIFY_YOU_ACCOUNT]: "Adja meg a 6 számjegyű SMS-kódot fiókja hitelesítéséhez",
    [k.NR]: "szám",
    [k.GO_TO_PAYMENT]: "Ugrás a fizetéshez",
    [k.ORDERED_PRODUCTS]: "Megrendelt termékek",
    [k.NOTES_TO_THE_ORDER]: "Megjegyzések a rendeléshez",
    [k.NO_PRODUCTS]: "Nincsenek termékek",
    [k.THERE_ARE_NO_PRODUCTS_IN_YOUR_CART]: "Nincs termék a kosárban",
    [k.NO_OFFERS]: "Nincsenek ajánlatok",
    [k.YOUR_CART]: "A kosarad",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_THE_CUSTOMER]: "Rendelésed ügyfélhez szállítva",
    [k.YOUR_ORDER_WITH_DELIVERY_TO_YOUR_WEREHOUSE]: "Rendelésed raktárhoz szállítva",
    [k.PAY_FOR_YOUR_ORDER]: "Fizesd ki a rendelésed",
    [k.PHONE]: "Telefon",
    [k.SHIPPING_TO]: "Szállítás ide:",
    [k.SAVE]: "Mentés",
    [k.EMAIL_IS_REQUIRED]: "Az e-mail megadása kötelező",
    [k.CODE_AND_CITY_ARE_REQUIRED]: "Irányítószám és város megadása kötelező",
    [k.POSTAL_CODE_AND_CITY]: "Irányítószám és város",
    [k.STREET_AND_BUILDING_NUMBER_ARE_REQUIRED]: "Utca és házszám megadása kötelező",
    [k.STREET_AND_NUMBER]: "Utca és házszám",
    [k.NAME_AND_SURNAME]: "Név és vezetéknév",
    [k.DISCOUNT]: "Kedvezmény",
    [k.SUM]: "Összeg",
    [k.YOU_WANT_TO_GIVE_A_DISCOUNT_ON_ALL_MATERIAL]: "Kedvezményt szeretnél adni az összes anyagra",
    [k.ALL_MATERIAL_DISCOUNT]: "Kedvezmény az összes anyagra",
    [k.SORRY_NOTHING_HERE]: "Sajnáljuk... itt nincs semmi.",
    [k.GO_HOME]: "Vissza a főoldalra",
    [k.I_UNDERSTAND_DO_NOT_SHOW_ON_THE_NEXT_LOGIN]: "Értem, ne jelenjen meg a következő bejelentkezéskor",
    [k.DISTRIBUTOR]: "Forgalmazó",
    [k.GENERATE_REPORT]: "Jelentés generálása",
    [k.SKETCH]: "Vázlat",
    [k.GENERATE_AN_OFFER]: "Ajánlat generálása",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECT]: "Biztosan törölni szeretné a kiválasztott projektet?",
    [k.FIND_A_PROJECT]: "Projekt keresése",
    [k.THERE_IS_NO_SUCH_PROJECT]: "Nincs ilyen projekt",
    [k.YOU_DONT_HAVE_ANY_PROJECT_YET]: "Még nincs projekted",
    [k.YOU_MUST_HAVE_AN_INVESTOR_AND_AN_INVESTMENT_TO_CREATE_A_PROJECT_FOR_IT]: "Befektetőre és befektetésre van szükséged a projekt létrehozásához",
    [k.DO_YOU_WANT_TO_CREATE_AN_INVESTOR]: "Szeretnél befektetőt létrehozni?",
    [k.GETTING_STARTED_WITH_ADDING_AN_INVESTOR]: "A munka kezdése a befektető hozzáadásával kezdődik",
    [k.ADD_AN_INVESTOR]: "Befektető hozzáadása",
    [k.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN]: "Valami hiba történt, próbálja meg újra",
    [k.SELECT_THE_TYPE_OF_ROOM]: "Válassza ki a szoba típusát",
    [k.GO_BACK_TO_EDITING]: "Vissza a szerkesztéshez",
    [k.TITLE]: "Cím",
    [k.GROUP]: "Csoport",
    [k.GENERAL]: "Általános",
    [k.HEATING_FOIL]: "Fűtőfólia",
    [k.ELECTRIC_HEATERS]: "Elektromos fűtőtestek",
    [k.HEATING_MAT]: "Fűtőszőnyeg",
    [k.IMAGE]: "Kép",
    [k.DRAG_AND_DROP_THE_IMAGE_OR_CLICK_AND_SELECT]: "Húzza és dobja be a képet, vagy kattintson és válasszon",
    [k.PREVIEW]: "Előnézet",
    [k.TEXT_EDITOR]: "Szövegszerkesztő",
    [k.SAVE_CHANGES]: "Változások mentése",
    [k.EXIT]: "Kilépés",
    [k.ADD_A_NEW_COMPANY]: "Új cég hozzáadása",
    [k.GO_BACK_TO_THE_LIST]: "Vissza a listához",
    [k.FIRST_NAME]: "Keresztnév",
    [k.LAST_NAME]: "Vezetéknév",
    [k.USER]: "Felhasználó",
    [k.IS_REQUIRED]: "kötelező",
    [k.FIRST_NAME_IS_REQUIRED]: "A keresztnév megadása kötelező",
    [k.LAST_NAME_IS_REQUIRED]: "A vezetéknév megadása kötelező",
    [k.PHONE_IS_REQUIRED]: "A telefon megadása kötelező",
    [k.EMAIL_IS_INVALID]: "Az e-mail cím érvénytelen",
    [k.ADD_USER]: "Felhasználó hozzáadása",
    [k.REGISTER]: "Regisztráció",
    [k.COUNTRY]: "Ország",
    [k.PHONE_NUMBER]: "Telefonszám",
    [k.PREFIX_IS_REQUIRED]: "A prefix megadása kötelező",
    [k.NEXT_STEP]: "Következő lépés",
    [k.TAX_ID_IS_REQUIRED]: "Adószám megadása kötelező",
    [k.COUNTRY_IS_REQUIRED]: "Ország megadása kötelező",
    [k.TAX_ID]: "Adószám",
    [k.TAX_ID_IS_INCORRECT]: "Helytelen adószám",
    [k.ZIP_CODE_IS_REQUIRED]: "Az irányítószám megadása kötelező",
    [k.CITY_IS_REQUIRED]: "A város megadása kötelező",
    [k.HOUSE_NUMBER_IS_REQUIRED]: "A házszám megadása kötelező",
    [k.NUMBER]: "Szám",
    [k.DOCUMENT_NUMBER]: "Dokumentum száma",
    [k.DOWNLOAD]: "Letöltés",
    [k.WAS_THIS_ARTICLE_HELPFUL]: "Hasznos volt ez a cikk?",
    [k.MIN_READING]: "perc olvasás",
    [k.SEE_MORE]: "TÖBB MEGTEKINTÉSE",
    [k.ADDRESS]: "Cím",
    [k.DEFAULT_ADDRESS]: "Alapértelmezett cím",
    [k.NAME]: "Név",
    [k.NAME_OF_THE_INVESTMENT]: "A befektetés neve",
    [k.SEARCH_CRITERIA]: "Keresési feltételek",
    [k.WHO_ADDED]: "Ki adta hozzá",
    [k.IS_IT_A_COMPANY]: "Ez egy cég?",
    [k.BUSINESS]: "Cég",
    [k.NATURAL_PERSON]: "Magánszemély",
    [k.YOU_DONT_HAVE_ANY_INVESTOR_YET]: "Még nincs befektetőd",
    [k.DO_YOU_WANT_TO_ADD_IT_NOW]: "Szeretnéd most hozzáadni?",
    [k.THERE_IS_NO_SUCH_INVESTOR]: "Nincs ilyen befektető",
    [k.YOU_DONT_HAVE_ANY_INVESTMENTS_YET]: "Még nincs befektetésed",
    [k.ADD_YOUR_FIRST_INVESTMENT]: "Add hozzá az első befektetést",
    [k.THERE_IS_NO_SUCH_INVESTMENT]: "Nincs ilyen befektetés",
    [k.ADD_AN_INVESTMENT]: "Befektetés hozzáadása",
    [k.INVESTOR]: "Befektető",
    [k.CONTACT_PERSON]: "Kapcsolattartó",
    [k.STREET_IS_REQUIRED]: "Az utca megadása kötelező",
    [k.NO_IS_REQUIRED]: "A szám megadása kötelező",
    [k.CODE_IS_REQUIRED]: "A kód megadása kötelező",
    [k.THE_ENERGY_CLASS_IS_REQUIRED]: "Az energiaosztály megadása kötelező",
    [k.CUSTOM_ENERGY_CLASS_IS_REQUIRED]: "Saját energiaosztály megadása kötelező",
    [k.GENERATE_OFFERS]: "Ajánlatok generálása",
    [k.THE_OFFER_IS_ALREADY_IN_THE_CART_ARE_YOU_SURE_YOU_WANT_TO_REMOVE_IT]: "Az ajánlat már szerepel a kosárban, biztosan el akarja távolítani?",
    [k.COMPANY_NAME_IS_REQUIRED]: "A cég neve kötelező",
    [k.THE_ADDRESS_IS_REQUIRED]: "A cím kötelező",
    [k.THE_ZIP_CODE_IS_INCORRECT]: "A megadott irányítószám hibás",
    [k.THE_CITY_IS_NOT_CORRECT]: "A város helytelen",
    [k.THE_HOUSE_NUMBER_IS_INCORRECT]: "A házszám helytelen",
    [k.COMPANY_NAME]: "Cég neve",
    [k.PREFIX_AND_PHONE_NUMBER_ARE_REQUIRED]: "Az előhívó és a telefonszám kötelező",
    [k.POSTAL_CODE_AND_CITY_ARE_REQUIRED]: "Irányítószám és város kötelező",
    [k.STREET_AND_HOUSE_NUMBER_ARE_REQUIRED]: "Utca és házszám kötelező",
    [k.FULL_NAME_IS_REQUIRED]: "Teljes név kötelező",
    [k.REQUIRED_FIELD]: "Kötelező mező",
    [k.REQUIRED]: "Kötelező",
    [k.ADD_A_PROJECT]: "Projekt hozzáadása",
    [k.PROJECT_NAME_IS_REQUIRED]: "A projekt neve kötelező",
    [k.THE_BUILDING_NUMBER_IS_REQUIRED]: "Az épület száma kötelező",
    [k.No]: "Nr",
    [k.ADDITIONAL_CONTACT_PERSON]: "További kapcsolattartó személy",
    [k.CLASS_ENERGY_IS_REQUIRED]: "Az energiaosztály kötelező",
    [k.ENERGY_CLASS]: "Energiaosztály",
    [k.THE_STREET_IS_NOT_CORRECT]: "Az utca helytelen",
    [k.HOUSE_NUMBER]: "Házszám",
    [k.TYPE_OF_INVESTMENT]: "Befektetés típusa",
    [k.CODE]: "Kód",
    [k.THE_INVESTMENT_HAS_NOT_BEEN_ADDED_TRY_AGAIN]: "A befektetés hozzáadása sikertelen. Próbálja újra",
    [k.COMPANY_TYPE]: "Cég típusa",
    [k.PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_AN_SMS_CODE]: "Telefonszám a fiók SMS kóddal történő ellenőrzéséhez",
    [k.GRAPHICS]: "Grafika",
    [k.GO_BACK_TO_LOGIN]: "Vissza a bejelentkezéshez",
    [k.ADD_A_NEW_INVESTMENT]: "Új befektetés hozzáadása",
    [k.GO_BACK_TO_THE_LIST_OF_INVESTMENTS]: "Vissza a befektetések listájához",
    [k.ADD_A_NEW_INVESTOR]: "Új befektető hozzáadása",
    [k.COMPLETE_THE_INVESTOR_PROFILE]: "Töltse ki a befektető profilját",
    [k.GO_BACK_TO_INVESTORS]: "Vissza a befektetőkhöz",
    [k.PROJECT_NAME]: "Projekt neve",
    [k.WHAT_DO_YOU_WANT_TO_DESIGN]: "Mit szeretne tervezni",
    [k.UPLOAD_THE_FILE_WITH_THE_VIEW]: "Töltse fel a fájlt a nézettel",
    [k.ADD_FILE]: "FÁJL HOZZÁADÁSA",
    [k.DISCARD_CHANGES]: "Módosítások elvetése",
    [k.DRAW_ANY_ROOM_PLAN]: "Rajzoljon bármilyen helyiség tervét",
    [k.GENERATE_AS_IMAGE]: "Generálás képként",
    [k.DRAW_PLAN]: "TERV RAJZOLÁSA",
    [k.CREATE_A_PROJECT]: "Projekt létrehozása",
    [k.CREATE_A_THROW_IN_THE_WIZARD]: "Készítsen tervet a varázslóban",
    [k.COME_BACK]: "Vissza",
    [k.HEATING_MAT_UNDER_THE_TILES]: "Fűtőszőnyeg csempék alá",
    [k.HEATING_FOIL_UNDER_THE_SPOUT]: "Fűtőfólia a beton alá",
    [k.HEATING_FOIL_UNDER_THE_SPC_VINYL_PANELS]: "Fűtőfólia SPC vinilpanelek alá",
    [k.HEATING_FOIL_FOR_LAMINATED_PANELS]: "Fűtőfólia laminált panelekhez",
    [k.SELECT_A_HEATING_SYSTEM]: "Válasszon fűtési rendszert",
    [k.GIVE_THE_REAL_DIMENSION]: "ADJA MEG A VALÓDI MÉRETET",
    [k.ENTER_THE_REAL_LENGTH]: "Adja meg a valódi hosszúságot",
    [k.DESIGNATED_EPISODE]: "kijelölt szakasz",
    [k.SEE_PREVIEW]: "Előnézet megtekintése",
    [k.ADJUST_THE_VIEW_BY_TRIMMING_AND_ROTATING_IT]: "Igazítsa a nézetet vágással és forgatással",
    [k.THERE_ARE_NO_PRODUCTS_FOR_THE_GIVEN_CRITERIA]: "Nincs termék a megadott kritériumok szerint",
    [k.CHOOSE_A_MODEL]: "VÁLASSZON MODELLT",
    [k.PLN_GROSS]: "Ft bruttó",
    [k.FOR_CALIBRATION_MARK_THE_LONGEST_SIDE_OF_THE_ROOM]:
        "A kalibráláshoz válassza ki a szoba bármelyik oldalát. Kattintson a tervrajzon egy pontra, majd húzza azt a kiválasztott vonal végéig a kalibrálás befejezéséhez.",
    [k.STEP1_DRAW_THE_OUTLINE_OF_THE_ROOM]: "1. lépés: Rajzolja meg a helyiség körvonalát",
    [k.SELECT_THE_TYPE_OF_THE_SELECTED_FIXED_BUILDING_ELEMENT]: "Válassza ki a kiválasztott fix építési elem típusát",
    [k.STEP2_APPLY_THE_SURFACES_EXCLUDED_FOR_PERMANENT_CONSTRUCTION]: "2. lépés: Jelölje meg a fix építkezésből kizárt felületeket",
    [k.ADD_A_LAYER_OF_VAPOR_BARRIER_FOIL]: "Hozzáad egy réteg párazáró fóliát",
    [k.PLACE_THE_THERMOREGULATOR]: "Helyezze el a termosztátot a helyiség szélén",
    [k.SELECT_THE_ENERGY_CLASS_OF_THE_BUILDING]: "Válassza ki az épület energiaosztályát",
    [k.THE_PROJECT_HAS_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_EXIT_THE_WIZARD]: "A projektben vannak el nem mentett módosítások. Biztosan kilép a varázslóból?",
    [k.DO_YOU_WANT_TO_SAVE_THE_CHANGES]: "Szeretné menteni a módosításokat?",
    [k.CONFIRM_NEW_PASSWORD]: "Erősítse meg az új jelszót",
    [k.SET_A_PASSWORD]: "Jelszó beállítása",
    [k.THE_PHONE_IS_NOT_VALID]: "A telefonszám érvénytelen",
    [k.MANAGE_ADDRESSES]: "Címek kezelése",
    [k.PLEASE_SELECT_A_SHIPPING_ADDRESS]: "Válassza ki a szállítási címet",
    [k.ADD_A_COMPANY]: "Cég hozzáadása",
    [k.SEARCH_FOR_A_COMPANY]: "Cég keresése",
    [k.MESSAGE]: "Üzenet",
    [k.NO_EMAIL_ADDRESS]: "Nincs e-mail cím",
    [k.EDIT_CUSTOMER_DETAILS]: "Ügyféladatok szerkesztése",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMPANY]: "Biztosan törölni szeretné ezt a céget?",
    [k.RULER_CALIBRATION]: "Vonalzó kalibráció",
    [k.CALIBRATE_TOOL_TO_TEACH_A_DISTANCE_CALCULATION_PROGRAM_FOR_YOUR_THROW]: "Kalibrálja az eszközt, hogy megtanítsa a távolság számítási programot a tervhez",
    [k.SEE_AN_EXAMPLE]: "Nézze meg a példát",
    [k.CALCULATE_YOUR_HEATING_COSTS]: "Számítsa ki a fűtési költségeit",
    [k.THE_AREA_OF_THE_ROOM]: "A helyiség területe",
    [k.PERMANENT_BUILDINGS]: "Fix épületek",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_ROOM]: "Biztosan törölni szeretné a helyiséget?",
    [k.THERMOREGULATORS]: "Termosztátok",
    [k.FLOOR_SENSOR]: "Padlóérzékelő",
    [k.INDOOR_AIR_SENSOR]: "Beltéri levegőérzékelő",
    [k.OUTSIDE_AIR_SENSOR]: "Kültéri levegőérzékelő",
    [k.OPTIONAL]: "Opcionális",
    [k.PROGRAMMING]: "Programozás",
    [k.LEVEL_OF_SECURITY]: "Biztonsági szint",
    [k.CONTROL_PANEL]: "Vezérlőpanel",
    [k.TOUCH]: "Érintős",
    [k.KEYBOARD]: "Billentyűzet",
    [k.KNOB]: "Gomb",
    [k.FIND_OUT_MORE]: "Tudjon meg többet",
    [k.CHOOSE]: "Válassza",
    [k.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT_FROM_YOUR_CART]: "Biztosan eltávolítja ezt a terméket a kosárból?",
    [k.FILTER]: "SZŰRÉS",
    [k.CUTTING]: "Vágás",
    [k.POWER]: "Teljesítmény",
    [k.MAX_TEMP]: "Max hőmérséklet",
    [k.AREA]: "Terület",
    [k.HEATING_MAT_POWER]: "Fűtőszőnyeg teljesítménye",
    [k.HEIGHT]: "Magasság",
    [k.THICKNESS]: "Vastagság",
    [k.PRODUCT_PARAMETERS]: "TERMÉK PARAMÉTEREI",
    [k.LENGTH]: "Hossz",
    [k.HEATING_POWER]: "Fűtőteljesítmény",
    [k.FOIL_SURFACE]: "Fóliafelület",
    [k.MAX_HEATING_TEMPERATURE]: "Max fűtési hőmérséklet",
    [k.THE_WIDTH_OF_THE_FILM]: "A fólia szélessége",
    [k.POSSIBILITY_TO_CUT_THE_FILM_CO]: "A fólia vágási lehetősége",
    [k.MAT_AREA]: "Szőnyeg területe",
    [k.THERMOSTAT_MODEL]: "Termosztát modell",
    [k.RESISTANCE]: "Ellenállás",
    [k.FOIL_MODEL]: "Fólia modell",
    [k.BRAND]: "Márka",
    [k.WEIGHT]: "Súly",
    [k.FOIL_GUARANTEE]: "Fólia garancia",
    [k.CONNECTION_KITS_PCS]: "Csatlakozókészletek [db]",
    [k.WIRES]: "Vezetékek",
    [k.TAPE_PCS]: "Szalag [db]",
    [k.THERMOSTAT_WARRANTY]: "Termosztát garancia",
    [k.CONNECTOR]: "Csatlakozó",
    [k.MAT_GUARANTEE]: "Szőnyeg garancia",
    [k.PRO_TAPE_PCS]: "PRO szalag [db]",
    [k.PRODUCT_DESCRIPTION]: "Termékleírás",
    [k.RETURN_TO_THE_KNOWLEDGE_BASE]: "Vissza a tudásbázisba",
    [k.SIMILAR_ARTICLES]: "Hasonló cikkek",
    [k.NUMBER_OF_INVESTMENTS]: "Befektetések száma",
    [k.VIEW_PROFILE]: "Profil megtekintése",
    [k.LATEST_INVESTMENTS]: "Legújabb befektetések",
    [k.THERE_ARE_NO_PRODUCTS_IN_THE_PROJECT]: "Nincsenek termékek a projektben",
    [k.BILL_OF_MATERIALS_FOR_PROJECTS]: "Anyagjegyzék projektekhez",
    [k.YOUR_CALCULATION_WITHOUT_ASSEMBLY]: "AZ ÖN KALKULÁCIÓJA ÖSSZESZERELÉS NÉLKÜL",
    [k.YOU_WANT_TO_ADD_A_MONTAGE]: "Szeretne hozzáadni egy összeszerelést",
    [k.NUMBER_OF_KILOMETERS]: "Kilométerek száma",
    [k.PRICE_FOR_ASSEMBLY]: "Összeszerelés ára",
    [k.YOUR_CALCULATION_WITH_ASSEMBLY]: "AZ ÖN KALKULÁCIÓJA ÖSSZESZERELÉSSEL",
    [k.CHECK_WHAT_RATE_YOU_ARE_AT]: "Ellenőrizze, milyen áron van",
    [k.SHOW_HEATING_COSTS]: "Mutasd a fűtési költségeket",
    [k.USABLE_AREA]: "Használható terület",
    [k.SELECTED_ENERGY_CLASS]: "Kiválasztott energiaosztály",
    [k.THE_PRICE_OF_ELECTRICITY]: "Az áram ára",
    [k.HEATING_PRICE_IN_KWH_YEAR]: "Fűtési ár kWh/év",
    [k.SAVE_AND_GENERATE_AN_OFFER]: "Mentés és ajánlat generálása",
    [k.NUMBER_OF_PROJECTS]: "Projektek száma",
    [k.GO_TO_INVESTMENT]: "Lépjen a befektetéshez",
    [k.LATEST_PROJECTS]: "Legújabb projektek",
    [k.ASSEMBLY]: "Összeszerelés",
    [k.START_TYPING]: "Kezdj el írni",
    [k.ORDERS_PLACED]: "Leadott rendelések",
    [k.NO_ORDERS_PLACED]: "Nincs leadott rendelés",
    [k.SUBMIT]: "Beküld",
    [k.SAYS]: "mondja",
    [k.ROTATE]: "Forgatás",
    [k.SCALE]: "Méretezés",
    [k.ADD_A_NEW_PROJECT]: "Új projekt hozzáadása",
    [k.GO_BACK_TO_PROJECTS]: "Vissza a projektekhez",
    [k.SELECT_THE_SIDE_ON_WHICH_THE_VIEW_IS_LOCATED]: "Válassza ki az oldalt, amelyen a nézet található",
    [k.STEP]: "Lépés",
    [k.CREATED_BY]: "készítette",
    [k.INVESTOR_NAME]: "Befektető neve",
    [k.YOU_DONT_HAVE_ANY_OFFER_YET]: "Még nincs ajánlata",
    [k.CURRENT_VIEW_CREATOR_SAVED]: `A "MENTÉS ÉS BEFEJEZÉS" gomb megnyomásával a jelenlegi nézet mentésre kerül a PDF ajánlatba. Egyéb esetekben a nézet nem kerül mentésre.`,
    [k.ENTER_DWG_FILE_SIZE]: `Adja meg a DWG fájl méretét.`,
    [k.CREATED_BY2]: `Létrehozta`,
    [k.DO_YOU_WANT_TO_SAVE_CHANGES]: `Szeretné menteni a változtatásokat?`,
    [k.NUMBER_OF_ROOMS]: `Helyiségek száma`,
    [k.FOIL]: "Fólia",
    [k.MAT]: "Szőnyeg",
    [k.NETTO_PRICE]: "Nettó ár",
    [k.QUANTITY_PCS_M]: "Mennyiség db/m",
    [k.NET_VALUE]: "Nettó érték",
    [k.MATERIAL_COST]: "Anyagköltség",
    [k.INVESTOR_COST]: "Befektető költsége",
    [k.ADD_NOTE]: "Megjegyzés hozzáadása",
    [k.REMOVE_PROJECT_FROM_OFFER]: "Projekt eltávolítása az ajánlatból",
    [k.COVERING_AREA_OF_ROOM]: "Helyiség lefedett területe",
    [k.SEE_ASSORTMENT]: "Készlet megtekintése",
    [k.LP]: "Sorszám",

    [k.SUMMARY]: "Összegzés",
    [k.THE_ORDER_HAS_BEEN_FORWARDED_FOR_EXECUTION]: "A rendelés továbbítva lett a teljesítésre",
    [k.THE_STATUS_OF_THE_ORDER_CAN_BE_TRACKED_IN_THE_ORDERS_TAB]: "A rendelés állapota nyomon követhető a Rendelések fülön",
    [k.THANK_YOU_FOR_YOUR_TRUST]: "Köszönjük a bizalmat",

    [k.TO_PAY]: "Fizetendő",
    [k.SHIPPING_TO_YOUR_OWN_WAREHOUSE]: "Szállítás a saját raktárba",
    [k.SHIPPING_TO_THE_CUSTOMER]: "Szállítás az ügyfélhez",
    [k.YOU_HAVE_RECEIVED_AN_ADDITIONAL_DISCOUNT_RESULTING_FROM_COMBINING_TWO_INVESTMENTS_INTO_ONE_ORDER]:
        "Olyan további kedvezményt kapott, amely két befektetés egy rendelésbe való egyesítéséből ered",
    [k.SHIPPING_ADDRESS]: "Szállítási cím",
    [k.ADDRESSEE]: "Címzett",
    [k.WAREHOUSE_ADDRESS]: "Raktár címe",
    [k.CONTACT]: "Kapcsolat",
    [k.CHOOSE_PAYMENT_METHOD]: "Válassza ki a fizetési módot",
    [k.TRADITIONAL_BANK_TRANSFER]: "Hagyományos banki átutalás",
    [k.PAYMENT_DEADLINE]: "Fizetési határidő",
    [k.CASH_ON_DELIVERY]: "Utánvét",
    [k.INVESTMENT_DATA]: "Befektetési adatok",
    [k.DELIVERY_ADDRESS]: "Szállítási cím",
    [k.LIST_OF_PRODUCTS]: "Termékek listája",
    [k.PRODUCT_NAME]: "Termék neve",
    [k.AMOUNT_OF_PRODUCTS]: "Termékek mennyisége",
    [k.NET_PRICE]: "Nettó ár",
    [k.GROSS_PRICE]: "Bruttó ár",
    [k.INFO_IF_YOU_WANT_TO_ADD_RESERVE_MATERIAL_TO_AN_INVESTMENT_CLICK_EDIT_IN_THE_TOP_RIGHT_CORNER_OF_THE_PRODUCT_LIST]:
        "Infó: Ha tartalék anyagot szeretne hozzáadni a befektetéshez, kattintson a terméklista jobb felső sarkában lévő szerkesztés gombra.",
    [k.INVESTMENT_ADDRESS]: "Befektetés címe",
    [k.BANK_TRANSFER_DETAILS]: "Átutalási adatok",
    [k.RECIPIENT]: "Kedvezményezett",
    [k.ACCOUNT_NUMBER]: "Számlaszám",
    [k.DOWNLOAD_PDF]: "PDF letöltése",
    [k.GO_TO_ORDER]: "Lépjen a rendeléshez",
    [k.CHOOSE_A_BANK]: "Válasszon bankot",
    [k.THE_VALUE_OF_THE_MATERIALS]: "Az anyagok értéke",
    [k.SHOPPING_CART_ITEMS]: "Kosár tételei",
    [k.INFO_TO_EDIT_THE_SHIPPING_ADDRESS_CLICK_ON_THE_PENCIL_IN_THE_UPPER_RIGHT_CORNER]:
        "Infó: A szállítási cím szerkesztéséhez kattintson a jobb felső sarokban található ceruzára",
    [k.NO_ORDERS]: "Nincs rendelés",
    [k.STATUS_OF_THE_ORDER]: "A rendelés állapota",
    [k.DATE]: "Dátum",
    [k.SHIPPING_NO]: "Szállítási szám",
    [k.DOWNLOAD_THE_INVOICE]: "Számla letöltése",
    [k.MORE_INFORMATION]: "További információk",
    [k.SHIPPING_INFORMATION]: "Szállítási adatok",
    [k.ORDER_STATUS]: "Rendelés állapota",
    [k.CONTACT_PERSON2]: "Kapcsolattartó személy",
    [k.ORDER_NOTES]: "Rendeléshez fűzött megjegyzések (opcionális)",
    [k.QUOTA]: "Kvóta",
    [k.GETTING_STARTED_ON_A_PROJECT_STARTS_WITH_ADDING_AN_INVESTMENT]: "A projekt elkezdése egy befektetés hozzáadásával kezdődik",
    [k.DUPLICATE]: "Másolat",
    [k.ROOMS]: "Helyiségek",
    [k.COMPLETED_OFFERS]: "Teljesített ajánlatok",
    [k.NO_PROJECTS]: "Nincs projekt",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_PROJECTS]: "Biztosan törölni szeretné a kiválasztott projekteket?",
    [k.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_SELECTED_OFFERS]: "Biztosan törölni szeretné a kiválasztott ajánlatokat?",
    [k.COMPANY]: "Cég",
    [k.YOUR_ADVANTAGE_IN_ASSEMBLY]: "Az Ön előnye az összeszerelésben",
    [k.YOUR_BENEFIT_ON_THE_MATERIAL]: "Az Ön előnye az anyagon",
    [k.ADDED_TO_CART]: "Hozzáadva a kosárhoz",
    [k.CREATED]: "Létrehozva",
    [k.PENDING]: "Függőben",
    [k.COMPLETE]: "Teljes",
    [k.REJECTED]: "Elutasítva",
    [k.GO_TO_OFFERS]: "Lépjen az ajánlatokhoz",
    [k.DONE_WITH_INSTALLATION]: "Telepítéssel kész",
    [k.DONE_WITHOUT_INSTALLATION]: "Telepítés nélkül kész",
    [k.THE_NAME_OF_THE_INVESTMENT_IS_REQUIRED]: "A befektetés neve kötelező",
    [k.INVESTOR_DATA]: "Befektető adatai",
    [k.USERS]: "Felhasználók",
    [k.RULES]: "Szabályok",
    [k.CONTACT_DETAILS]: "Kapcsolattartási adatok",
    [k.THE_LOGO_WILL_BE_USED_ON_THE_OFFER_AND_INVOICES_FOR_THE_CUSTOMER]: "A logó az ajánlaton és a számlákon lesz használva az ügyfél számára",
    [k.UPLOAD_THE_FILE]: "Fájl feltöltése",
    [k.UPLOAD_THE_LOGO_FILE]: "Logó fájl feltöltése",
    [k.MAX_SIZE]: "Max. méret",
    [k.MAX_DIMENSIONS]: "Max. méretek",
    [k.CURRENT_FILE]: "Jelenlegi fájl",
    [k.NO_PHOTO]: "Nincs fotó",
    [k.DELETE_FILE]: "Fájl törlése",
    [k.WIZARD_LANGUAGE]: "Varázsló nyelve",
    [k.COMPANY_LOGO]: "Cég logója",
    [k.MAIN_USER]: "Fő felhasználó",
    [k.OTHER_USERS]: "További felhasználók",
    [k.ADD_NEW_PROFILE]: "Új profil hozzáadása",
    [k.CHANGES_HAVE_BEEN_MADE_DO_YOU_WANT_TO_SAVE_THEM]: "Módosítások történtek, szeretné menteni őket?",
    [k.DISCARD]: "Elvetés",
    [k.POSITION_OPTIONAL]: "Pozíció (opcionális)",
    [k.LOCK_PROFILE]: "Profil zárolása",
    [k.DELETE_PROFILE]: "Profil törlése",
    [k.DO_SAVE_CHANGES]: "Mentse a változtatásokat?",
    [k.POSITION]: "Pozíció",
    [k.THE_NAME_OF_THE_ENERGY_CLASS_IS_REQUIRED]: "Az energiaosztály neve kötelező",
    [k.THE_NAME_OF_THE_ENERGY_CLASS]: "Az energiaosztály neve",
    [k.EU_DEMAND]: "EU igény",
    [k.EU_REQUIREMENT_IS_REQUIRED]: "Az EU igény kötelező",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT]: "Fűtési rendszer a kifolyó alatt",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_IS_REQUIRED]: "Fűtési rendszer a kifolyó alatt kötelező",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]:
        "Fűtési rendszer a kifolyó alatt a következő helyiségekhez: fürdőszoba, WC, mosókonyha, szauna.",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_SAUNA_IS_REQUIRED]:
        "Fűtési rendszer a kifolyó alatt a következő helyiségekhez: fürdőszoba, WC, mosókonyha, szauna kötelező",
    [k.HEATING_SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_SAUNA_IS_REQUIRED]: "Padlófűtési rendszer szükséges a helyiségekben: fürdőszoba, WC, mosókonyha, szauna",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL]: "Panel alatti fűtési rendszer",
    [k.HEATING_SYSTEM_UNDER_THE_PANEL_IS_REQUIRED]: "Panel alatti fűtési rendszer szükséges",
    [k.REMOVE_ENERGY_CLASS]: "Energiaosztály eltávolítása",
    [k.TABLE_OF_ENERGY_CLASSES]: "Energiaosztályok táblázata",
    [k.EU_DEMAND_EVERY_KWH]: "EU igény KWh/m2*év",
    [k.SYSTEM_UNDER_THE_SPOUT]: "Rendszer a padló alá",
    [k.SYSTEM_UNDER_THE_SPOUT_FOR_ROOMS_BATHROOM_TOILET_LAUNDRY_ROOM_SAUNA]: "Rendszer a padló alá a helyiségekben (fürdőszoba, WC, mosókonyha, szauna)",
    [k.SYSTEM_UNDER_THE_PANEL]: "Rendszer a panel alá",
    [k.INFO_YOU_CAN_ADD_YOUR_OWN_ENERGY_CLASS_SETTINGS]: "Info: Lehetőség van saját energiaosztály beállításainak hozzáadására.",
    [k.ADD_YOUR_OWN_ENERGY_CLASS]: "Saját energiaosztály hozzáadása",
    [k.ASSEMBLY_RULE]: "Szerelési szabály",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_FROM_THE_USABLE_AREA_OF_THE_ROOM]: "Figyelem! A nettó összeg a szoba hasznos területéből számítódik (alaprajz).",
    [k.HEAT_DECOR_RECOMMENDATION]: "Heat Decor ajánlás",
    [k.ATTENTION_THE_NET_AMOUNT_IS_CALCULATED_ON_THE_SURFACE_OF_THE_HEATING_MAT]: "Figyelem! A nettó összeg a fűtőszőnyeg felületéből számítódik.",
    [k.VAT_RULE]: "ÁFA szabály",
    [k.ACTIVE_VAT_RULES]: "Aktív ÁFA szabályok",
    [k.ADD_A_NEW_USER]: "Új felhasználó hozzáadása",
    [k.UNLOCK_PROFILE]: "Profil feloldása",
    [k.NET_PRICE_PER_M2]: "Nettó ár m2-enként",
    [k.SYSTEM_FOR_TILES]: "Rendszer csempék alá",
    [k.INSULATION_MAT]: "5 mm-es szigetelő szőnyeg",
    [k.XPS_EXTRUDED_POLYSTYRENE]: "6 mm-es extrudált XPS hab",
    [k.SYSTEM_FOR_PANELS_6_MM_XPS_UNDERLAY]: "Rendszer panelekhez (6 mm XPS alátét)",
    [k.SYSTEM_FOR_PANELS_5_MM_BASE]: "Rendszer panelekhez (5 mm alátét)",
    [k.THE_MINIMUM_VALUE_MUST_NOT_BE_GREATER_THAN_THE_MAXIMUM]: "A minimális érték nem lehet nagyobb a maximálisnál",
    [k.UNDER_LAMINATED_PANELS]: "laminált panelek alatt",
    [k.UNDER_THE_SPOUT]: "padló alatt",
    [k.UNDER_SPC_VINYL_PANELS]: "SPC vinil panelek alatt",
    [k.PRODUCT_TAILORED_TO_YOUR_SYSTEM]: "A rendszerhez szabott termék",
    [k.YEARS_WARRANTY]: "év garancia",
    [k.THERMOREGULATOR]: "Termoregulator",
    [k.INTERNAL_AIR_SENSOR]: "Belső légérzékelő",
    [k.EXTERNAL_AIR_SENSOR]: "Külső légérzékelő",
    [k.CONNECTION]: "Kapcsolat",
    [k.HAS]: "Van",
    [k.DOES_NOT_HAVE]: "Nincs",
    [k.WHITE]: "Fehér",
    [k.BLACK]: "Fekete",
    [k.MANUAL]: "Kézi",
    [k.INFO_ZIGBEE]: "Info: *A projekthez hozzá lesz adva egy ZigBee 3.0 központ, amely szükséges a rendszer működéséhez. Legfeljebb 50 eszközt támogat.",
    [k.SKIP]: "Kihagyás",
    [k.ATTENTION]: "Figyelem!",
    [k.ERROR]: "Hiba",
    [k.INFO]: "Info",
    [k.DO_YOU_WANT_TO_ADD_ANOTHER_ELEMERNTS]: "Szeretne további állandó szerkezeti elemeket hozzáadni?",
    [k.RECTANGLE]: "Téglalap",
    [k.LINE]: "Vonal",
    [k.ADDITIONAL_DISCOUNT_HAS_BEEN_CHARGED]: "További kedvezmény került felszámolásra!",
    [k.IRREGULAR_SHAPE]: "Szabálytalan alak",
    [k.VAPOR_BARRIER_FILM]: "Párazáró fólia",
    [k.INSULATING_UNDERLAY]: "Szigetelő alátét",
    [k.WIRES_BROWN]: "Vezetékek - barna",
    [k.WIRES_BLUE]: "Vezetékek - kék",
    [k.CONNECTORS_FOR_HEATING_FOIL]: "Csatlakozók fűtőfóliához (A vagy B típus)",
    [k.SELF_FUSING_TAPE]: "Önszilárduló szalag",
    [k.HEAT_DECOR_ADHESIVE_TAPE]: "Heat Decor ragasztószalag",
    [k.ARRANGEMENT]: "Elrendezés",
    [k.DRAG]: "Húzás",
    [k.DELETE_ROOM]: "Helyiség törlése",
    [k.INCORRECT_FORMAT]: "Helytelen formátum",
    [k.USE_HAND]: "Nézet mozgatása",
    [k.FILE_TYPE_MUST_BE]:
        "Helytelen fájltípus. A fájlnak a következő típusú kell lennie: image/jpeg, image/png, .pdf, image/gif, .dwg image/jpeg, image/png, .pdf, image/gif vagy .dwg",
    [k.THERE_IS_NO_SUCH_OFFER]: "Nincs ilyen ajánlat",
    [k.COPY_DATA]: "Adatok másolása",
    [k.DELETE_THERMOSTAT]: "Termosztát törlése",
    [k.SHOWER_BATH__WASHING_MACHINE]: "Zuhany/kád/mosógép",
    [k.FREESTANDING_WASHBASIN__FREESTANDING_BOWL]: "Szabadon álló mosdókagyló/szabadon álló csésze",
    [k.WALL_HUNG_WASHBASIN__HUNG_BOWL]: "Falra szerelt mosdókagyló/falra szerelt csésze",
    [k.SEWAGE_GRATE]: "Szennyvízcsatorna rács",
    [k.SELECT_POINTS_TO_CREATE_A_FIGURE]: "Válasszon pontokat egy ábra létrehozásához",
    [k.STAINLESS_STEEL_MESH]: "Rozsdamentes acél háló",
    [k.GROUNDING_WIRE]: "Földelő vezeték",
    [k.CONNECTORS_FOR_STAINLESS_STEEL_MESH]: "Csatlakozók rozsdamentes acél hálóhoz (A vagy B típus)",
    [k.SELF_VULCANIZING_TAPE_FOR_HEATING_FOIL_CONNECTORS]: "Önszilárduló szalag fűtőfólia csatlakozókhoz",
    [k.REINFORCED_TAPE]: "Erősített szalag",
    [k.INSTALLATION_BOX]: "Szerelődoboz",
    [k.PROTECTIVE_CONDUIT]: "Védőcső",
    [k.CITY]: "Város",
    [k.INPUTENERGY]: "Energiaosztály",
    [k.LISTOFINVEST]: "Beruházások listája",
    [k.PROJECTSSTATUS]: "Projekt állapota",
    [k.FLOORAREA]: "Terület",
    [k.COMPLETENOTES]: "Jegyzetek kitöltése",
    [k.TEXTADDINVEST]:
        "A Heat Decor Tervezőben a befektető olyan természetes vagy jogi személy, aki meg kívánja vásárolni a modern fűtési rendszer telepítéséhez szükséges anyagokat, és opcionálisan a telepítést is megrendeli. A befektetőt azonosító adatok közé tartozik az adószám, jogi személy esetén a név, természetes személy esetén pedig a név és az elérhetőségi adatok.",
    [k.IMPORTANT]: "Fontos!",
    [k.TEXTADDINVEST2]: "A befektető lakhelyének vagy üzleti tevékenységének helye Lengyelország kell, hogy legyen.",
    [k.INVESTORPROFILTEXT]: "Ennek a befektetőnek nincs beruházása",
    [k.INVESTORPROFILTEXT2]: "Szeretné most hozzáadni?",
    [k.INVESTORPROFILTEXT3]: "A projekt megkezdése a beruházás hozzáadásával kezdődik",
    [k.HEATING_SYSTEM]: "Fűtési rendszer",
    [k.SYSTEMCOMPONENTS]: "Rendszerelemek",
    [k.MODIFY_THE_PERMANENT_STRUCTURE]: "Állandó szerkezet módosítása",
    [k.ACCESSORIES]: "Kiegészítők",
    [k.DATE_CREATED]: "Létrehozás dátuma",
    [k.COPY_OFFER]: "Ajánlat másolása",
    [k.UNDO]: "VISSZAVONÁS",
    [k.FILL_IN_THE_DETAILS]: "Töltse ki a kapcsolatfelvételi személy adatait",
    [k.E_G]: "pl. Építész",
    [k.ADD_INVEST_TEXT]:
        "A Heat Decor Tervezőben a beruházás a modern fűtési rendszer tervezett telepítésére vonatkozó helyiségeket tartalmazza. A beruházást azonosító adatok közé tartozik a feltöltött alaprajzok, az egyes helyiségek alapterülete, a biztonsági zónák kijelölése, ahol elektromos padlófűtés nem telepíthető, a rögzített szerkezetek megjelölése, amelyek nem rendelkeznek legalább 3 cm magasságú lábakkal, a hőszigetelési képességből adódó fűtési energiaszükséglet (EUco) és a címadatok.",
    [k.COMPLATE_THE_INVEST]: "Töltse ki a beruházás adatait",
    [k.DISCOUNT_VALUE]: "Kedvezmény értéke",
    [k.ADD_MONTAGE]: "Szerelés hozzáadása",
    [k.MOUNTING_SYSTEM]: "Szerelési rendszer",
    [k.COST_OF_INSTALL]: "Telepítési költség",
    [k.NOTE_CHECK]: "Figyelem! Ellenőrizze, hogyan van beállítva a szerelési szabály",
    [k.VAT_RATE]: "ÁFA mérték",
    [k.NOTE_CHECK_VAT]: "Figyelem! Ellenőrizze, milyen ÁFA mértéken van!",
    [k.HEATING_COSTS]: "Fűtési költségek",
    [k.ADD_HEATING_COSTS]: "Fűtési költségek hozzáadása",
    [k.RESET]: "Alaphelyzet",
    [k.RETURN_TO_OFFERS]: "VISSZA AZ AJÁNLATOKHOZ",
    [k.LACK_OF_ROOMS]: "Nincs helyiség",
    [k.DRAW_A_ROOM]: "Rajzoljon helyiséget",
    [k.HEATING_FOIL_UNDER_THE_LAYERED]: "Fűtőfólia rétegelt deszka alá",
    [k.CONFIGURATION_OF_PRICE]: "Ár konfigurálása 1 km-re",
    [k.SALEOFFER]: "Eladási ajánlat",
    [k.COSTOFINSTALLSERVICE]: "Telepítési szolgáltatás költsége",
    [k.COSTOFALLMATERIALS]: "Minden anyag költsége",
    [k.LISTOFMATERIALS]: "Minden anyag költsége",
    [k.MATERIAL]: "Anyag",
    [k.MANUFACTUREROFHEATING]: "A fűtőfólia és fűtőszőnyeg gyártója: Heat Decor",
    [k.VISUALIZATION]: "Fűtési rendszer vizualizációja",
    [k.VISUALIZATIONOFTHEHEATING]: "A fűtési rendszer vizualizációja a jelen ajánlat mellékletét képezi.",
    [k.LOGODISTRIBUTOR]: "Forgalmazó logója",
    [k.VISUALIZATIONMADE]: "Vizualizáció készítette",
    [k.AREAOFTHEHEATING]: "A fűtőfólia területe",
    [k.POWEROFTHEHEATING]: "A fűtőfólia teljesítménye",
    [k.ELECTRICALCONNECTION]: "Elektromos csatlakozás",
    [k.ESTIMATEDANNUAL]: "Becsült éves működési költség",
    [k.ENERGYCLASSSOLO]: "Energiaosztály",
    [k.CHANGETHEPOWER]: "Módosítsa a fűtőfólia teljesítményét",
    [k.GERMAN]: "Német",
    [k.ROMANIAN]: "Román",
    [k.HUNGARIAN]: "Magyar",
    [k.RODO]: "Rodo a forgalmazóktól",
    [k.RODOTEXT]:
        "A Heat Decor sp. z o.o. nem vállal felelősséget a felhasználó által készített projektekért/anyagösszeállításokért, beleértve a fűtési rendszer helyiséghez való kiválasztását, annak teljesítményét és anyagait. A felhasználó felelős a választásért, az esetleges hibás működésért és az általa konfigurált fűtési rendszer hatékonyságáért.",
    [k.RODOHD]: "Rodo Heat Decor",
    [k.SCOPEOFRESPONSIBILITY]: "Felelősségi kör",
    [k.ATTACHMENTS]: "Mellékletek",
    [k.ATTACHMENTSTEXT]: "Az ajánlat tartalmazza a fűtési rendszer vizualizációját az egyes helyiségekre vonatkozóan.",
    [k.ANNOTATIONS]: "Megjegyzések az ajánlathoz",
    [k.THANKYOU]: "Köszönjük",
    [k.THANKYOUTEXT]: "az érdeklődést a modern fűtési rendszer iránt.",
    [k.THANKYOUQUESTION]: "Kérdések esetén forduljon az ajánlattevő személyhez.",
    [k.OFFERINGPERSON]: "Ajánlattevő személy",
    [k.OFFERINGCOMPANY]: "Ajánlattevő cég",
    [k.RECOMMENDMODERN]: "Ajánljuk a Heat Decor modern fűtési rendszereit",
    [k.MAINTENANCE]: "karbantartásmentes",
    [k.COMPLETLY]: "teljesen",
    [k.SILENT]: "csendes",
    [k.FRIENDLY]: "környezetbarát",
    [k.ENVIRONMENT]: "a környezet számára",
    [k.WITHINTELLIGENT]: "intelligens",
    [k.CONTROL]: "vezérléssel",
    [k.VISUALIZATIONOFTHEDISTRIBUTION]: "Elrendezés vizualizációja",
    [k.HEATINGSYSTEM]: "Fűtési rendszer",
    [k.TOTALPOWER]: "Rendszerek összteljesítménye",
    [k.HEATINGFLOORS]: "Fűtési szintek",
    [k.LEGEND]: "Jelmagyarázat",
    [k.PERMANENT_BUILDINGS2]: "állandó épületek",
    [k.CAPACITYOFHEATING]: "FŰTÉSI RENDSZEREK TELJESÍTMÉNYEI AZ EGYES HELYISÉGEKBEN",
    [k.NAMEOFROOM]: "Helyiség neve",
    [k.SYSTEMTYPE]: "Rendszer típusa",
    [k.AREACOVERAGE]: "Fedett terület [ % ]",
    [k.POWERPER]: "Teljesítmény (m2-re) [ W/(m2) ]",
    [k.SYSTEMPOWER]: "Rendszer teljesítménye a helyiségben [ W ]",
    [k.HEATINGCIRCUIT]: "Fűtőkör ellenállása [ \u03A9 ]",
    [k.CONTACTOR]: "* - az adott helyiséghez kontaktor szükséges",
    [k.OFFERNO]: "AJÁNLAT SZÁMA",
    [k.DEVELOPMENT]: "Kidolgozás",
    [k.BASEMENTPLAN]: "Pince alaprajz",
    [k.FLOORPLAN]: "Emeleti alaprajz",
    [k.ATTICPLAN]: "Padlás alaprajz",
    [k.STOREYPLAN]: "Szint alaprajz",
    [k.LEVELPLAN]: "Szint tervrajz",
    [k.ROOMPLAN]: "Helyiség alaprajz",
    [k.GROUNDFLOORPLAN]: "Földszinti alaprajz",
    [k.NO_THERMOSTAT]: "Nincs termosztát",
    [k.EXTERNAL_SENSOR]: "Külső érzékelő",
    [k.APPLY_DISCOUNT_TO_ALL_PRODUCTS]: "Kedvezmény alkalmazása az összes termékre",
    [k.INVESTOR_PRICE_BELOW_COST]: "Figyelem! Az egyik termék befektetői ára alacsonyabb, mint az Ön beszerzési ára!",
    [k.CURRENCY]: "Pénznem",
    [k.POLISH_ZLOTY]: "Lengyel zloty (PLN)",
    [k.EURO]: "Euró (EUR)",
    [k.CZECH_KORUNA]: "Cseh korona (CZK)",
    [k.BRITISH_POUND]: "Brit font (GBP)",
    [k.CURRENCY_IS_REQUIRED]: "A pénznem megadása kötelező",
    [k.SELECT_DISTRIBUTOR]: "Válasszon forgalmazót",
    [k.SELECT_ACTIVE_PRODUCTS]: "Válassza ki az aktív termékeket",
    [k.MANAGE_PRODUCTS]: "Termékek kezelése",
    [k.ENTER_MESSAGE]: "Üzenet beírása",
    [k.LOGFF]: "Jelentkezzen ki a felhasználó",
    [k.SELECTUSER]: "Válasszon felhasználót",
    [k.USERACCOUNT]: "Felhasználói fiók",
    [k.US_DOLLAR]: "Amerikai dollár (USD)",
    [k.PRODUCT_SUMMARY]: "Termékek összegzése",
    [k.ERROR_OCCURRED]: "Hiba történt",
    [k.PHONE_NUMBER_TO_VERIFY_THE_ACCOUNT_WITH_SMS_CODE]: "Telefonszám a fiók hitelesítéséhez SMS-kóddal",
    [k.DOWNLOAD_EXCEL]: "Excel letöltése",
    [k.RESET_TO_DEFAULTS]: "Visszaállítás alapértelmezett értékekre",
    [k.ORDER_VALUE]: "Rendelés értéke",
    [k.SHOW_HINTS]: "Tippek megjelenítése?",
};
